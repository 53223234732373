<mat-toolbar color="primary">
  <span>Imperial Truck Services Inc. {{ environmentTitle }}
    <p style="font-size: 10px; display: inline">v{{ version }}</p>
  </span>

  <span style="position: absolute; right: 10px;">
    <theme-switcher></theme-switcher>
  </span>
</mat-toolbar>

<div class="mt-3" fxLayout fxLayoutAlign="center center">
  <mat-card fxFlex.xs="86%" fxFlex.sm="86%" fxFlex.md="70" fxFlex="30%">
    <mat-card-title>
      <h2 class="Anton">Imperial Truck Services, Inc.</h2>
    </mat-card-title>

    <mat-card-content>
      <form [formGroup]="loginForm">
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input matInput required formControlName="username" name="username" type="text" autocomplete="username"
            autofocus />
          <mat-error *ngIf="formGroupHasError(loginForm, 'username', 'required')">
            Username is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <br />

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput required formControlName="password" name="password" type="password" autocomplete="password" />
          <mat-error *ngIf="formGroupHasError(loginForm, 'password', 'required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>

      <loading-button [runOnEnter]="true" [text]="'Login'" [action]="loginClick"></loading-button>
    </mat-card-content>

    <mat-divider class="mt-3 mb-3"></mat-divider>
    <br />

    <div class="mt-3">
      <span style="font-style: italic; font-size: 8px"><strong>Login with google coming soon...</strong></span>
      <!-- <google-login-button></google-login-button> -->
    </div>

    <mat-error class="mt-3" *ngIf="requestError">
      {{ requestError }}
    </mat-error>
  </mat-card>
</div>