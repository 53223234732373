import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ViewChildren, QueryList, OnDestroy, ComponentRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormComponent } from 'src/app/services/FormComponentHelper';


export enum EntitySelectorTableColumnType {
	data = 'data',
	menu = 'menu',
	button = 'button',
	text = 'text',
	number = 'number',
	asyncButton = 'asyncButton',
	asyncData = 'asyncData',
	checkbox = 'checkbox',
	custom = 'custom'
}

@Component({
	selector: 'entity-selector-custom-component',
	template: `<ng-container #customComponentHousing></ng-container>`
})
export class EntitySelectorCustomComponent implements AfterViewInit, OnDestroy {
	@ViewChild('customComponentHousing', { read: ViewContainerRef }) componentHousing: ViewContainerRef;

	@Input() element: any;
	@Input() column: EntitySelectorTableColumn;

	private componentRef: ComponentRef<any> = null;

	constructor(private componentFactoryResoler: ComponentFactoryResolver) { }

	public ngAfterViewInit() {
		this.loadComponent();
	}

	public ngOnDestroy(): void {
		if (this.componentRef && this.componentRef.changeDetectorRef) {
			this.componentRef.changeDetectorRef.detach();
		}
	}

	public loadComponent() {
		if (this.componentHousing && this.column.typeOptions && this.column.typeOptions.componentType) {
			let componentFactory = this.componentFactoryResoler.resolveComponentFactory(this.column.typeOptions.componentType);
			this.componentRef = this.componentHousing.createComponent(componentFactory);

			(this.componentRef.instance as any).data = this.element;
			(this.componentRef.instance as any).column = this.column;

			this.componentRef.changeDetectorRef.detectChanges();
		}
	}
}

export class EntitySelectorTableColumn {
	public columnHeader: string;
	public columnProperty: string;
	public columnWidth: string = '150px';
	public type: EntitySelectorTableColumnType;
	public typeOptions: any;
	public inputLabel: string = "";
	public errors: any[] = [];
	public getData = (element: any) => {
		let getDotNotationData = (element: any, columnProperty: string) => {
			let properties = columnProperty.split('.');

			if (properties.length > 1) {
				let property = properties[0];
				properties.shift();

				return getDotNotationData(element.hasOwnProperty(property) ? element[property] : {}, properties.join('.'));
			}

			return element.hasOwnProperty(columnProperty) ? element[columnProperty] : '';
		};

		return getDotNotationData(element, this.columnProperty);
	};

	public disabled = (element: any) => {
		return false;
	};
}

@Component({
	selector: 'entity-selector-table',
	templateUrl: './entitySelectorTable.html',
	styleUrls: ['./entitySelectorTable.scss']
})
export class EntitySelectorTable extends FormComponent implements AfterViewInit, OnInit {
	@ViewChild(MatPaginator) paginator;

	private originalData: any[] = [];
	private _dataSource: MatTableDataSource<any> = new MatTableDataSource();
	@Input() set dataSource(data: MatTableDataSource<any>) {
		this._dataSource = data;
	}
	get dataSource(): MatTableDataSource<any> {
		return this._dataSource;
	}

	private _selectorTableColumns: EntitySelectorTableColumn[] = [];
	get selectorTableColumns(): EntitySelectorTableColumn[] {
		return this._selectorTableColumns;
	}
	@Input() set selectorTableColumns(selectorColumns: EntitySelectorTableColumn[]) {
		if (selectorColumns) {
			this.displayedColumns = selectorColumns.map(column => column.columnProperty);
		}

		this._selectorTableColumns = selectorColumns;
	}

	public displayedColumns: string[] = [];
	public textFilterControl: UntypedFormControl = new UntypedFormControl();
	@Input() tableMaxHeight: string = '75vh';
	@Input() pageSize: number = 10;


	@Input() filters: [] = [];//		Array of functions called that will filter the data in the order provided

	constructor() {
		super();
	}

	public ngOnInit() {
		this.textFilterControl.valueChanges.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map((text: string) => {
				if (text == null || text == undefined || text == '') text = " ";

				this.dataSource.filter = text.toLowerCase();
			})
		).subscribe();
	}

	public ngAfterViewInit() {
		if (this.paginator && this.dataSource) {
			this.dataSource.paginator = this.paginator;
		}
	}

	public updateDisplayedColumns() {
		if (this.selectorTableColumns) {
			this.displayedColumns = this.selectorTableColumns.map(column => column.columnProperty);
		}
	}

	public applyFilters() {
		this.originalData = this.dataSource.data;

		this.filters.forEach((filter: any) => {
			filter(this.dataSource);
		});

	}

	public unapplyFilters() {
		this._dataSource.data = this.originalData;
	}
}