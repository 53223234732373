import { Quote } from './quote';

export class RepairOrder extends Quote {
	public repairOrderProcessingStepId: RepairOrderProcessingStep;
	public total_cost: number;
	public purchaseordernumber: string;
	public close_date: Date;
	public complete_date: Date;
	public isBilled: boolean;

	constructor(data?: RepairOrder | Quote) {
		super(data);

		if (data) {
			this.repairOrderProcessingStepId = (data as RepairOrder).repairOrderProcessingStepId;
			this.total_cost = (data as RepairOrder).total_cost;
			this.purchaseordernumber = (data as RepairOrder).purchaseordernumber;
			this.close_date = (data as RepairOrder).close_date;
			this.complete_date = (data as RepairOrder).complete_date;
			this.isBilled = (data as RepairOrder).isBilled;
		}
	}
}

export enum RepairOrderProcessingStep {
	New = 1,
	NeedsBilled = 2,
	Closed = 3, //	Only admins can close, users can no longer make changes after closed, has been invoiced
	Complete = 4 //	Used for users to mark if their repair order has been complted
}