import { Component, Input, OnInit, HostListener } from "@angular/core";

@Component({
	selector: "loading-button",
	templateUrl: "./loadingButton.html",
	styleUrls: ["./loadingButton.scss"],
})
export class LoadingButton {
	@Input("text") text: string;
	@Input("buttonClass") buttonClass: string;
	@Input("iconClass") iconClass: string;
	@Input("action") action: any;
	@Input("disabled") disabled: any = false;
	@Input("actionArgs") actionArgs: any;
	@Input() runOnEnter: boolean = false;
	@Input() materialType: string = "mat-raised-button";
	@Input() materialColor: string = "accent";

	public loading: boolean = false;

	@HostListener("document:keypress", ["$event"])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (this.runOnEnter && event.keyCode == 13) {
			this.clicked();
		}
	}

	constructor() { }

	public clicked() {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (Array.isArray(this.actionArgs)) {
			this.action(...this.actionArgs).finally(() => {
				this.loading = false;
			});
		} else {
			this.action(this.actionArgs).finally(() => {
				this.loading = false;
			});
		}
	}

	public getDisabled() {
		if (typeof this.disabled == "function") {
			return this.disabled();
		}

		return this.disabled;
	}
}
