<div class="mt-3" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start" fxLayout="row">
	<div fxFlex.xs="95" fxFlex.sm="95" fxFlex.md="70" fxFlex="50">
		<mat-card >
			<mat-card-title>
				<h2 class="Anton">Reporting</h2>
			</mat-card-title>
		
			<mat-card-content  class="pageOverflow">
				<div>
					<mat-form-field appearance="fill">
						<mat-label>Enter a date range</mat-label>
						<mat-date-range-input [formGroup]="range" [rangePicker]="picker">
							<input matStartDate formControlName="start" placeholder="Start date">
							<input matEndDate formControlName="end" placeholder="End date">
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
				
						<mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
						<mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
					</mat-form-field>
				</div>
				<br>
		
				<mat-divider></mat-divider>
		
				<br>
	
				<div>
					<loading-button
						[text]="'Download Receipts'"
						[action]="downloadPurchaseOrderReceipts"
					></loading-button>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>