import { Component } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Store } from '../../classes/store';
import { UserService } from '../../services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/classes/customer';
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../entity_selector_table/entitySelectorTable';

@Component({
	selector: 'stores-component',
	templateUrl: './stores.html',
	styleUrls: ['./stores.scss']
})
export class StoresComponent {
	public selectedStore: Store = null;
	public tableDataSource = new MatTableDataSource();

	public loading: boolean = false;
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	constructor(public userService: UserService, private api: ApiService) {
		this.loadStores();
		this.setupEntitySelectorTableDataSource();
	}

	public setupEntitySelectorTableDataSource() {
		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = "";
		editButton.columnProperty = "edit";
		editButton.columnWidth = "35px";
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: 'edit',
			click: (store: Store) => {
				this.selectedStore = store;
			}
		};
		this.selectorTableColumns.push(editButton);

		let nameColumn = new EntitySelectorTableColumn();
		nameColumn.columnHeader = "Name";
		nameColumn.columnProperty = "name";
		nameColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(nameColumn);
		this.tableDataSource.filterPredicate = (customer: Customer, filterText: string) => {
			let name = customer.name;
			if (name == null || name == undefined) name = '';

			return name.toLowerCase().indexOf(filterText.trim()) > -1;
		};
	}

	public backClicked() {
		this.selectedStore = null;
		//		Force filtering to reoccur
		this.tableDataSource.filter = this.tableDataSource.filter + " ";
	}

	public loadStores() {
		this.loading = true;
		return this.api.getStores().toPromise().then(results => {
			let stores = results;

			if (stores && stores.length) {
				stores = stores.sort((a, b) => {
					let aCustomer = (a.name && a.name.toLowerCase()) || '';
					let bCustomer = (b.name && b.name.toLowerCase()) || '';

					if (aCustomer > bCustomer) return 1;
					if (aCustomer < bCustomer) return -1;
					return 0;
				});
			}

			this.tableDataSource.data = stores;

			if (this.selectedStore && this.selectedStore.id) {
				this.selectedStore = stores.find(store => store.id == this.selectedStore.id);
			} else {
				this.selectedStore = null;
			}
		}).catch(error => {
			console.error(error);
		}).finally(() => {
			this.loading = false;
		});
	}

	public newStoreClicked() {
		this.selectedStore = new Store();
	}
}