<ng-template #buttonInnerHTML>
	<div *ngIf="!loading"><i [ngClass]="iconClass"></i> {{ text }}</div>

	<mat-spinner [diameter]="25" [color]="materialColor" *ngIf="loading"></mat-spinner>
</ng-template>

<ng-container [ngSwitch]="materialType">
	<div *ngSwitchCase="'mat-raised-button'">
		<button [disabled]="loading || getDisabled()" (click)="clicked()" [ngClass]="buttonClass" mat-raised-button [color]="materialColor">
			<ng-container *ngTemplateOutlet="buttonInnerHTML"></ng-container>
		</button>
	</div>
	<div *ngSwitchCase="'mat-mini-fab'">
		<button *ngSwitchCase="'mat-mini-fab'" [disabled]="loading || getDisabled()" (click)="clicked()" [ngClass]="buttonClass" mat-mini-fab [color]="materialColor">
			<ng-container *ngTemplateOutlet="buttonInnerHTML"></ng-container>
		</button>
	</div>
</ng-container>
