import { EventEmitter, Injectable } from '@angular/core';

declare let gapi:any;

export class GoogleUser {
	public id:string;
	public name:string;
	public email:string;
	public photoUrl:string;
	public firstName:string;
	public lastName:string;
	public authToken:string;
	public idToken:string;
	public response:any;
	public authorizationCode:string;
}

@Injectable()
export class AuthorizationService {
	public _googleAuth:any = null;
	
	get googleAuth() : any {
		if(!this._googleAuth) {
			console.warn('Google authorization has not yet been initialized. Please call initializeGApi().')
		}

		return this._googleAuth;
	}

	set googleAuth(auth:any) {
		this._googleAuth = auth;
	}

	public loggedIn:EventEmitter<GoogleUser> = new EventEmitter<GoogleUser>();
	public loggedOut:EventEmitter<any> = new EventEmitter<any>();

	public isInitialized:Promise<any> = this.initializeGApi();

	constructor() { }

	public initializeGApi() : Promise<void> {
		return new Promise((resolve, reject) => {
			if(!this.googleAuth) {
				//		Load the google api script
				let script = document.createElement('script');
	
				script.async = true;
	
				//		TODO:MIKE - Load this url from some sort of configuration file
				script.src = 'https://apis.google.com/js/platform.js';
				script.onload = () => {
					gapi.load('client:auth2', () => {
						let authorizationScope = { scope: 'email profile https://mail.google.com/' };
						//		TODO:MIKE - load this client id from some sort of configuration file
						let clientId = '712184027054-eantoe0on3gaeoif096kq8d87ouu5fjh.apps.googleusercontent.com';
						let apiKey = 'AIzaSyA6t9TilhmxZKOQB-FlL7Y_fSlbiLywhAs';

						this.googleAuth = gapi.auth2.init({
							...authorizationScope,
							clientId: clientId,
							apiKey: apiKey
						}).then(authObject => {
							this.googleAuth = authObject;

							gapi.client.load('gmail').then(() => {
								if(this.isLoggedIn()) {
									this.loggedIn.emit(this.getGoogleUserInfo());
								}

								resolve();
							});							
						});
	
						console.info('Google authorization client initialized.');
					});
				};
	
				document.head.appendChild(script);
			} else {
				return reject();
			}
		});
	}

	public isLoggedIn() : boolean {
		return this.googleAuth && this.googleAuth.isSignedIn.get();
	}

	public getGoogleUserInfo() : GoogleUser {
		let user:GoogleUser = new GoogleUser();

		const profile = this.googleAuth.currentUser.get().getBasicProfile();
		const authResponse = this.googleAuth.currentUser.get().getAuthResponse(true);

		user.id = profile.getId();
		user.name = profile.getName();
		user.email = profile.getEmail();
		user.photoUrl = profile.getImageUrl();
		user.firstName = profile.getGivenName();
		user.lastName = profile.getFamilyName();
		user.authToken = authResponse.access_token;
		user.idToken = authResponse.id_token;
		user.response = profile;

		return user;
	}

	public refreshToken() {
		this.googleAuth.currentUser.get().reloadAuthResponse(true);
	}

	public login() :Promise<GoogleUser> {
		return this.googleAuth.signIn().then(response => {
			let user:GoogleUser = new GoogleUser();

			if (response && response.code) {
				user.authorizationCode = response.code;
			} else {
				let profile = this.googleAuth.currentUser.get().getBasicProfile();
				let token = this.googleAuth.currentUser.get().getAuthResponse(true).access_token;
				let backendToken = this.googleAuth.currentUser.get().getAuthResponse(true).id_token;

				user.id = profile.getId();
				user.name = profile.getName();
				user.email = profile.getEmail();
				user.photoUrl = profile.getImageUrl();
				user.firstName = profile.getGivenName();
				user.lastName = profile.getFamilyName();
				user.authToken = token;
				user.idToken = backendToken;
  
				user.response = profile;
			}

			this.loggedIn.emit(user);
			return user;
		});
	}

	public logout(revoke?:boolean) : Promise<any> {
		let signOutPromise: Promise<any>;

		if (revoke) {
			signOutPromise = this.googleAuth.disconnect();
		} else {
			signOutPromise = this.googleAuth.signOut();
		}

		this.loggedOut.emit();
		return signOutPromise;
	}

	public getEmailLables() {
		/*gapi.client.gmail.users.messages.list({ 'userId': 'me'}).then(response => {
			gapi.client.gmail.users.messages.get({ 'userId': 'me', id: response.result.messages[0].id}).then(message => {
				let textMessage = atob(message.result.payload.parts[0].body.data.replace(/-/g, '+').replace(/_/g, '/'));
				let newDiv = document.createElement('div');
				newDiv.innerHTML = textMessage;

				//document.body.appendChild(newDiv);
				
			});
		}).catch(error => {
			debugger;
		}); */
	}
}