import { Customer } from "./customer";
import { User } from "./user";
import { Vehicle } from "./vehicle";

export class Quote {
    public id: number;
    public createdAt: Date;
    public user: User = new User();
    public customer: Customer = new Customer();
    public vehicle: Vehicle = new Vehicle();
    public lineItems: Array<LineItem> = [];

    constructor(data?: Quote) {
        if (data) {
            this.id = data.id;
            this.createdAt = data.createdAt;
            this.user = new User(data.user);
            this.customer = new Customer(data.customer);
            this.vehicle = new Vehicle(data.vehicle);
            this.lineItems = data.lineItems ? data.lineItems.map(lineItem => new LineItem(lineItem)) : [];
        }
    }
}

export class LineItem {
    public id: number;
    public complaint: string;
    public cause: string;
    public correction: string;
    public hours: number;
    public rate: number;
    public products: Array<Product> = [];

    constructor(data?: LineItem) {
        if (data) {
            this.id = data.id;
            this.complaint = data.complaint;
            this.cause = data.cause;
            this.correction = data.correction;
            this.hours = data.hours;
            this.rate = data.rate;
            this.products = data.products ? data.products.map(product => new Product(product)) : [];
        }
    }
}

export class Product {
    public id: number;
    public cost: number;
    public quantity: number;
    public description: string;
    public part_number: string;

    constructor(data?: Product) {
        if (data) {
            this.id = data.id;
            this.cost = data.cost;
            this.quantity = data.quantity;
            this.description = data.description;
            this.part_number = data.part_number;
        }
    }
}