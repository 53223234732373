<div class="mt-3 overflow maxHeight" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<div *ngIf="configuration" fxLayout="column" fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="50%" fxLayoutGap="15px">

			<button (click)="saveConfiguration()" mat-raised-button color="primary">Save</button>

			<button (click)="deleteConfiguration()" mat-raised-button color="warn">Delete</button>

			<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
				<button (click)="addField()" mat-raised-button color="primary">Add New Field</button>
			</div>

			<div cdkDropList (cdkDropListDropped)="drop($event)">
				<mat-form-field>
						<mat-label>Configuration Title</mat-label>
						<input [(ngModel)]="configuration.title" matInput placeholder="The title of the configuration">
				</mat-form-field>

				<mat-card class="mb-3">Drag and drop items to re-order them.</mat-card>

				<div *ngFor="let field of configuration.fields; index as i;" [matBadge]="i + 1" matBadgePosition="before" matBadgeColor="primary" cdkDrag>
					<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
						<button (click)="removeField(field)" mat-mini-fab color="warn"> <mat-icon>delete</mat-icon></button>
	
						<mat-form-field>
							<mat-label>Field Description</mat-label>
							<input [(ngModel)]="field.title" matInput placeholder="Description for the field">
						</mat-form-field>
	
					</div>
				</div>
			</div>

	</div>
</div>