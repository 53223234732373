<div fxLayout="row">
	<div>
		<mat-toolbar style="height: 100vh" fxFlex="50px;" color="primary">
			<button style="position: absolute; top: 5px; left: 5px" mat-icon-button [matMenuTriggerFor]="menu">
				<mat-icon>menu</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button style="outline: none" mat-menu-item [routerLink]="['../home']">Home</button>
				<button mat-menu-item [routerLink]="['new_purchase_order']">New Purchase Order</button>
				<button mat-menu-item [routerLink]="['receiving']">Receiving</button>
				<button mat-menu-item [routerLink]="['quotes']">Quotes</button>
				<button mat-menu-item [routerLink]="['repair_orders']">Repair Orders</button>
				<button mat-menu-item [routerLink]="['preventative_maintenance/selector']">Preventative
					Maintenance</button>
				<button mat-menu-item [routerLink]="['customers']">Customers</button>
				<button mat-menu-item [routerLink]="['accounts']">Accounts</button>
				<button mat-menu-item [routerLink]="['vehicles']">Vehicles</button>
				<button mat-menu-item [routerLink]="['stores']">Stores</button>
				<button mat-menu-item [routerLink]="['payroll']">Payroll</button>
				<button mat-menu-item [routerLink]="['reporting']">Reporting</button>
				<button mat-menu-item [routerLink]="['settings']">Settings</button>
				<mat-divider></mat-divider>
				<user-clock-in></user-clock-in>
				<button mat-menu-item (click)="logoutClicked()">Logout</button>
			</mat-menu>

			<span class="envTitle" *ngIf="environmentTitle && environmentTitle != ''">{{ environmentTitle }}</span>

			<!-- <google-profile-button *ngIf="google && google.isLoggedIn()" style="bottom: 45px; left: 8px; position: absolute;"></google-profile-button> -->
			<span style="position: absolute; bottom: 45px; left: 8px">
				<theme-switcher></theme-switcher>
			</span>

			<span matTooltip="Online / Offline Indicator" style="left: 13px; bottom: 10px; position: absolute">
				<mat-icon *ngIf="isOnline" style="color: rgb(29, 202, 29)">wifi</mat-icon>
				<mat-icon *ngIf="!isOnline" style="color: red">wifi_off</mat-icon>
			</span>
			<span>
				<p matTooltip="Version"
					style="font-size: 10px; display: inline; position: absolute; bottom: -20px; left: 10px">v{{ version
					}}</p>
			</span>
		</mat-toolbar>
	</div>

	<div fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center center" fxLayout="column" class="pl-3 pr-3"
		*ngIf="router.url === '/home' || router.url === '/home/'">
		<div>
			<h2 class="Anton"><strong>Welcome, {{ user.username || 'user' }}</strong></h2>
		</div>

		<div>
			<strong>
				Welcome to the Imperial Truck Services website.
				<br />
				<br />
				Here you may log your purchase orders, repair orders and time on the job.
				<br />
				Coming soon your account will be able to be linked to a google account and your emails will be
				accessible from within this website.
				<br />
				<br />
				If you have any questions or concerns, please contact Dustin Dubiaga at 330-523-0710 or email him at
				imperialtruckservices@gmail.com
				<br />
				<br />
				Thank you!
			</strong>
		</div>
	</div>

	<!-- Router outlet area for children routes -->
	<router-outlet></router-outlet>
</div>