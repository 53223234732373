import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Vehicle } from "../classes/vehicle";
import { of, Observable, throwError } from "rxjs";
import { User } from "../classes/user";
import { RepairOrder } from "../classes/repairOrder";
import { Customer } from "../classes/customer";
import { Product } from "../classes/product";
import { PurchaseOrder } from "../classes/purchaseOrder";
import { Store } from "../classes/store";
import { map } from "rxjs/operators";
import { PreventativeMaintenanceConfiguration, PreventativeMaintenanceRecord } from "../classes/preventative_maintenance";
import { environment } from "../../environments/environment";
import { LineItem, Quote } from "../classes/quote";

export class ApiConfiguration {
	public static apiBaseUrl: string = environment.apiUrl;

	public static apiEndPoints = {
		login: ApiConfiguration.apiBaseUrl + "login",
		allUsers: ApiConfiguration.apiBaseUrl + "allUsers",
		allVehicles: ApiConfiguration.apiBaseUrl + "allVehicles",
		allCustomers: ApiConfiguration.apiBaseUrl + "allCustomers",
		allPurchaseOrders: ApiConfiguration.apiBaseUrl + "allPurchaseorders",
		allRepairOrders: ApiConfiguration.apiBaseUrl + "repairorder/active",
		allProducts: ApiConfiguration.apiBaseUrl + "allProducts",
		createRepairOrder: ApiConfiguration.apiBaseUrl + "repairorder/create",
		createUser: ApiConfiguration.apiBaseUrl + "user/create",
		createVehicle: ApiConfiguration.apiBaseUrl + "vehicle/create",
		createPurchaseOrder: ApiConfiguration.apiBaseUrl + "purchaseorder/create",
		createProduct: ApiConfiguration.apiBaseUrl + "product/create",
		createCustomer: ApiConfiguration.apiBaseUrl + "customer/create",
		createPurchaseOrderFiles: ApiConfiguration.apiBaseUrl + "purchaseorder/file/create",
		createVehicleProduct: ApiConfiguration.apiBaseUrl + "vehicleproduct/create",
		updateRepairOrder: ApiConfiguration.apiBaseUrl + "repairorder/update",
		updateUser: ApiConfiguration.apiBaseUrl + "user/update",
		updateVehicle: ApiConfiguration.apiBaseUrl + "vehicle/update",
		updatePurchaseOrder: ApiConfiguration.apiBaseUrl + "purchaseorder/update",
		updateCustomer: ApiConfiguration.apiBaseUrl + "customer/update",
		addPurchaseOrderProducts: ApiConfiguration.apiBaseUrl + "purchaseorderproducts/add",
		deletePurchaseOrderProducts: ApiConfiguration.apiBaseUrl + "purchaseorderproducts/delete",
		deletePurchaseOrder: ApiConfiguration.apiBaseUrl + "purchaseorder/:id",
		getVehicleProducts: ApiConfiguration.apiBaseUrl + "vehicleproduct",
		updateVehicleProduct: ApiConfiguration.apiBaseUrl + "vehicleproduct/update",
		removeVehicleProuduct: ApiConfiguration.apiBaseUrl + "vehicleproudct/remove",
		getPurchaseOrderFile: ApiConfiguration.apiBaseUrl + "files/purchaseorder",
		deleteRepairOrder: ApiConfiguration.apiBaseUrl + "repairorder/delete",
		setNextInvoiceNumber: ApiConfiguration.apiBaseUrl + "repairorder/update/repairordernumber",
		getRepairOrderVehicle: ApiConfiguration.apiBaseUrl + "repairorder/:id/vehicles",
		getRepairOrderCustomer: ApiConfiguration.apiBaseUrl + "repairorder/:id/customer",
		getRepairOrderLineItems: ApiConfiguration.apiBaseUrl + "repairorder/:id/lineitems",
		getLineItemProducts: ApiConfiguration.apiBaseUrl + "lineitem/:id/products",
		upsertRepairOrder: ApiConfiguration.apiBaseUrl + "repairorder/upsert",
		updateRepairOrderCustomer: ApiConfiguration.apiBaseUrl + "repairorder/:orderId/customer/:customerId/update",
		updateRepairOrderVehicle: ApiConfiguration.apiBaseUrl + "repairorder/:orderId/vehicle/:vehicleId/update",
		upsertRepairOrderLineItem: ApiConfiguration.apiBaseUrl + "repairorder/:id/lineitem/upsert",
		upsertLineItemProduct: ApiConfiguration.apiBaseUrl + "lineitem/:id/product/upsert",
		upsertRepairOrderVehicle: ApiConfiguration.apiBaseUrl + "repairorder/:id/vehicle/upsert",
		deactivateRepairOrder: ApiConfiguration.apiBaseUrl + "repairorder/:id",
		removeLineItemProduct: ApiConfiguration.apiBaseUrl + "lineitem/:id/product/:productId",
		removeRepairOrderLineItem: ApiConfiguration.apiBaseUrl + "repairorder/:repairOrderId/lineitem/:lineItemId",
		getUserPayrollInformation: ApiConfiguration.apiBaseUrl + "user/:id/payrollinformation",
		getUserClockInStatus: ApiConfiguration.apiBaseUrl + "user/:id/clockInStatus",
		userClockIn: ApiConfiguration.apiBaseUrl + "user/:id/clockin",
		userClockOut: ApiConfiguration.apiBaseUrl + "user/:id/clockout",
		searchRepairOrders: ApiConfiguration.apiBaseUrl + "repairorder/search",
		searchStores: ApiConfiguration.apiBaseUrl + "store/search",
		deactivateUser: ApiConfiguration.apiBaseUrl + "user/:id/deactivate",
		deleteVehicleProduct: ApiConfiguration.apiBaseUrl + "vehicle/:id/product/:productId",
		deleteVehicleProducts: ApiConfiguration.apiBaseUrl + "vehicleproduct/delete",
		getNextPurchaseOrderName: ApiConfiguration.apiBaseUrl + "purchaseorder/getNextName",
		createStore: ApiConfiguration.apiBaseUrl + "store/create",
		updateStore: ApiConfiguration.apiBaseUrl + "store/update",
		getStores: ApiConfiguration.apiBaseUrl + "stores",
		getPurchaseOrderFiles: ApiConfiguration.apiBaseUrl + "files/zip",
		createUserTime: ApiConfiguration.apiBaseUrl + "user/:id/usertime",
		updateUserTime: ApiConfiguration.apiBaseUrl + "user/:id/usertime/:userTimeId",
		updateVehicleProducts: ApiConfiguration.apiBaseUrl + "vehicle/:id/products",
		snapshotVehicleInventory: ApiConfiguration.apiBaseUrl + "vehicle/:id/snapshot",
		vehicleInventorySnapshot: ApiConfiguration.apiBaseUrl + "vehicle/:vehicleId/snapshot/:id",
		linkGoogleAccount: ApiConfiguration.apiBaseUrl + "user/link_google_account",
		repairOrderMergeInto: ApiConfiguration.apiBaseUrl + "repairorder/:id/merge",
		getConfigurationPreventativeMaintenanceRecords: ApiConfiguration.apiBaseUrl + "preventative_maintenance/records/configuration/:id",
		getCustomerPreventativeMaintenanceConfigurations: ApiConfiguration.apiBaseUrl + "preventative_maintenance_configuration/customer/:id",
		getPreventativeMaintenanceRecord: ApiConfiguration.apiBaseUrl + "preventative_maintenance/record/:id",
		getPreventativeMaintenanceConfiguration: ApiConfiguration.apiBaseUrl + "preventative_maintenance/configuration/:id",
		savePreventativeMaintenanceRecord: ApiConfiguration.apiBaseUrl + "preventative_maintenance/record",
		deletePreventativeMaintenanceRecord: ApiConfiguration.apiBaseUrl + "preventative_maintenance/record/:id",
		savePreventativeMaintenanceConfiguration: ApiConfiguration.apiBaseUrl + "preventative_maintenance/configuration",
		deletePreventativeMaintenanceConfiguration: ApiConfiguration.apiBaseUrl + "preventative_maintenance/configuration/:id",
		convertPreventativeMaintenanceRecordToRepairOrder: ApiConfiguration.apiBaseUrl + "preventative_maintenance/record/:id/convertToRepairOrder",
		getFile: ApiConfiguration.apiBaseUrl + "file/:id",
		postFile: ApiConfiguration.apiBaseUrl + "file",
		getVehicle: ApiConfiguration.apiBaseUrl + "vehicle/:id",
		getUser: ApiConfiguration.apiBaseUrl + "user/:id",
		getCustomer: ApiConfiguration.apiBaseUrl + "customer/:id",
		getUserTypes: ApiConfiguration.apiBaseUrl + "userTypes",
		getQuotes: ApiConfiguration.apiBaseUrl + 'quotes',
		updateQuote: ApiConfiguration.apiBaseUrl + 'quotes/:id',
		createQuote: ApiConfiguration.apiBaseUrl + 'quotes/create',
		searchQuotes: ApiConfiguration.apiBaseUrl + 'quotes/search',
		deactivateQuote: ApiConfiguration.apiBaseUrl + 'quotes/:id',
		convertQuoteToRepairOrder: ApiConfiguration.apiBaseUrl + 'quotes/convert/:id',
		quickResetUserPassword: ApiConfiguration.apiBaseUrl + 'users/:id/quickreset',
		customerVehicles: ApiConfiguration.apiBaseUrl + 'customer/:id/vehicles'
	};
}

let RequestMethod = {
	Get: "GET",
	Post: "POST",
	Delete: "DELETE",
};

export class Cache {
	public vehicles: Vehicle[] = [];
	public repairOrders: RepairOrder[] = [];
	public lineItemProducts: { id: number; products: Product[] }[] = [];
	public repairOrderCustomers: { id: number; customers: Customer[] }[] = [];
	public repairOrderLineItems: { id: number; lineItems: LineItem[] }[] = [];
	public users: User[] = [];
	public purchaseOrders: PurchaseOrder[] = [];
	public customers: Customer[] = [];
	public products: Product[] = [];
	public vehicleProducts: { id: number; products: Product[] }[] = [];
	public stores: Store[] = [];
	public repairOrderVehicles: { id: number; vehicles: Vehicle[] }[] = [];
	public quotes: Quote[] = [];
}

@Injectable()
export class ApiService {
	public cache: Cache = new Cache();
	public token: string = null;

	//      Constructors
	constructor(private http: HttpClient) { }

	public authenticate(username: string, password: string): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.login, [{ key: "Content-Type", value: "application/json" }], RequestMethod.Post, {
			username: username,
			password: password,
		});
	}

	public authenticateWithGoogleId(googleId: string, username: string, password: string): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.login, [{ key: "Content-Type", value: "application/json" }], RequestMethod.Post, {
			google_id: googleId,
			username: username,
			password: password,
		});
	}

	public setNextInvoiceNumber(nextInvoiceNumber: number): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.setNextInvoiceNumber, this.getRequestHeaders(), RequestMethod.Post, { nextInvoiceNumber: nextInvoiceNumber - 1 });
	}

	public downloadFile(purchaseOrderId: number): Observable<any> {
		let requestHeaders = new HttpHeaders();
		let headers = this.getRequestHeaders();
		if (headers && headers.map)
			headers.map((element) => {
				requestHeaders = requestHeaders.set(element.key, element.value);
			});

		return this.http.post(
			ApiConfiguration.apiEndPoints.getPurchaseOrderFile,
			{ purchaseOrderId: purchaseOrderId },
			{
				responseType: "blob",
				headers: requestHeaders,
			}
		);
	}

	public downloadPurchaseOrderFiles(beginDate: string, endDate: string): Observable<any> {
		let requestHeaders = new HttpHeaders();
		let headers = this.getRequestHeaders();

		if (headers && headers.map) {
			headers.map((element) => {
				requestHeaders = requestHeaders.set(element.key, element.value);
			});
		}

		return this.http.get(ApiConfiguration.apiEndPoints.getPurchaseOrderFiles + `?beginDate=${beginDate}&endDate=${endDate}`, { responseType: "blob", headers: requestHeaders });
	}

	public searchRepairOrders(searchText): Observable<any> {
		if (!searchText) searchText = "";

		return this.sendRequest(ApiConfiguration.apiEndPoints.searchRepairOrders, this.getRequestHeaders(), RequestMethod.Post, { text: searchText });
	}

	public searchStores(searchText): Observable<any> {
		if (!searchText) searchText = "";

		return this.sendRequest(ApiConfiguration.apiEndPoints.searchStores, this.getRequestHeaders(), RequestMethod.Post, { text: searchText });
	}

	public deleteVehicleProductFromCache(vehicleId): void {
		//		Delete the item from the cache
		let vehicleProductsIndex = this.cache.vehicleProducts.findIndex((vehicleProducts) => vehicleProducts.id == vehicleId);

		if (vehicleProductsIndex > -1) {
			this.cache.vehicleProducts.splice(vehicleProductsIndex, 1);
		}
	}

	public deleteVehicleProduct(vehicleId, productId): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.deleteVehicleProduct.replace(":id", vehicleId).replace(":productId", productId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null).pipe(
			map((response) => {
				this.deleteVehicleProductFromCache(vehicleId);

				return response;
			})
		);
	}

	public linkUserToGoogleAccount(userId, googleId): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.linkGoogleAccount, this.getRequestHeaders(), RequestMethod.Post, {
			id: userId,
			google_id: googleId,
		});
	}

	public deleteUserFromCache(id): void {
		let userIndex = this.cache.users.findIndex((user) => user.id == id);
		if (userIndex > -1) {
			this.cache.users.splice(userIndex, 1);
		}
	}

	public deactivateUser(id): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.deactivateUser.replace(":id", id);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				this.deleteUserFromCache(id);

				return response;
			})
		);
	}

	public userClockIn(userId): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.userClockIn.replace(":id", userId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public userClockOut(userId): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.userClockOut.replace(":id", userId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getUserClockInStatus(userId): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getUserClockInStatus.replace(":id", userId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getUserPayrollInformation(userId, dateRange: Date[]): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getUserPayrollInformation.replace(":id", userId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, { dates: dateRange });
	}

	public deleteRepairOrderLineItemFromCache(repairOrderId) {
		//		Delete the item from the cache
		let repairOrderLineItemsIndex = this.cache.repairOrderLineItems.findIndex((repairOrderLineItems) => repairOrderLineItems.id == repairOrderId);

		if (repairOrderLineItemsIndex > -1) {
			this.cache.repairOrderLineItems.splice(repairOrderLineItemsIndex, 1);
		}
	}

	public removeRepairOrderLineItem(repairOrderId, lineItemId): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.removeRepairOrderLineItem.replace(":repairOrderId", repairOrderId).replace(":lineItemId", lineItemId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null).pipe(
			map((response) => {
				this.deleteRepairOrderLineItemFromCache(repairOrderId);

				return response;
			})
		);
	}

	public deleteLineItemProductFromCache(lineItemId): void {
		//		Delete the item from the cache
		let lineItemProductsIndex = this.cache.lineItemProducts.findIndex((lineItemProducts) => lineItemProducts.id == lineItemId);

		if (lineItemProductsIndex > -1) {
			this.cache.lineItemProducts.splice(lineItemProductsIndex, 1);
		}
	}

	public removeLineItemProduct(lineItemId, productId): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.removeLineItemProduct.replace(":id", lineItemId).replace(":productId", productId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null).pipe(
			map((response) => {
				this.deleteRepairOrderLineItemFromCache(lineItemId);

				return response;
			})
		);
	}

	public deleteRepairOrderFromCache(id): void {
		//		Delete the item from the cache
		let repairOrderIndex = this.cache.repairOrders.findIndex((repairOrder) => repairOrder.id == id);

		if (repairOrderIndex > -1) {
			this.cache.repairOrders.splice(repairOrderIndex, 1);
		}
	}

	public deactivateRepairOrder(id): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.deactivateRepairOrder.replace(":id", id);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null).pipe(
			map((response) => {
				this.deleteRepairOrderFromCache(id);

				return response;
			})
		);
	}

	public clearRepairOrderCache(): void {
		this.cache.repairOrders = [];
	}

	public clearQuotesCache() {
		this.cache.quotes = [];
	}

	public deleteQuoteFromCache(id: number) {
		//		Delete the item from the cache
		let quoteIndex = this.cache.quotes.findIndex((quote) => quote.id == id);

		if (quoteIndex > -1) {
			this.cache.quotes.splice(quoteIndex, 1);
		}
	}

	public upsertRepairOrder(order: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.upsertRepairOrder, this.getRequestHeaders(), RequestMethod.Post, order).pipe(
			map((response) => {
				//		Delete the item from the cache
				//let repairOrderIndex = this.cache.repairOrders.findIndex(repairOrder => repairOrder.id == order.id);

				//if(repairOrderIndex > -1) {
				this.clearRepairOrderCache();
				//}

				return response;
			})
		);
	}

	public deleteRepairOrderVehicleFromCache(orderId): void {
		let repairOrderIndex = this.cache.repairOrderVehicles.findIndex((repairOrder) => repairOrder.id == orderId);

		if (repairOrderIndex > -1) {
			this.cache.repairOrderVehicles.splice(repairOrderIndex, 1);
		}
	}

	public upsertRepairOrderVehicle(orderId, vehicle): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.upsertRepairOrderVehicle.replace(":id", orderId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, vehicle).pipe(
			map((response) => {
				this.deleteRepairOrderVehicleFromCache(orderId);

				return response;
			})
		);
	}

	public deleteRepairOrderCustomerFromCache(orderId): void {
		let repairOrderIndex = this.cache.repairOrderCustomers.findIndex((repairOrder) => repairOrder.id == orderId);

		if (repairOrderIndex > -1) {
			this.cache.repairOrderCustomers.splice(repairOrderIndex, 1);
		}
	}

	public updateRepairOrderCustomer(orderId, customerId): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.updateRepairOrderCustomer.replace(":orderId", orderId).replace(":customerId", customerId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, null).pipe(
			map((response) => {
				this.deleteRepairOrderCustomerFromCache(orderId);

				return response;
			})
		);
	}

	public upsertRepairOrderLineItem(orderId, lineItem): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.upsertRepairOrderLineItem.replace(":id", orderId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, lineItem).pipe(
			map((response) => {
				this.deleteRepairOrderLineItemFromCache(orderId);

				return response;
			})
		);
	}

	public upsertLineItemProduct(lineItemId, product): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.upsertLineItemProduct.replace(":id", lineItemId);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, product).pipe(
			map((response) => {
				this.deleteLineItemProductFromCache(lineItemId);

				return response;
			})
		);
	}

	public getNextPurchaseOrderName(): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.getNextPurchaseOrderName, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getRepairOrderVehicles(id: number, fromCache: boolean = true): Observable<Vehicle[]> {
		let repairOrderVehicles = this.cache.repairOrderVehicles.find((repairOrderVehicles) => repairOrderVehicles.id == id);

		if (fromCache && repairOrderVehicles) {
			return of(repairOrderVehicles.vehicles);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.getRepairOrderVehicle.replace(":id", id.toString()), this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					if (repairOrderVehicles) {
						repairOrderVehicles.vehicles = response;
					} else {
						this.cache.repairOrderVehicles.push({
							id: id,
							vehicles: response,
						});
					}
				}

				return response;
			})
		);
	}

	public getLineItemProducts(id: number, fromCache: boolean = true): Observable<Product[]> {
		let lineItemProducts = this.cache.lineItemProducts.find((lineItemProducts) => lineItemProducts.id == id);
		if (fromCache && lineItemProducts) {
			return of(lineItemProducts.products);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.getLineItemProducts.replace(":id", id.toString()), this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					if (lineItemProducts) {
						lineItemProducts.products = response;
					} else {
						this.cache.lineItemProducts.push({
							id: id,
							products: response,
						});
					}
				}

				return response;
			})
		);
	}

	public getRepairOrderCustomer(id: number, fromCache: boolean = true): Observable<Customer[]> {
		let repairOrderCustomers = this.cache.repairOrderCustomers.find((repairOrderCustomer) => repairOrderCustomer.id == id);

		if (fromCache && repairOrderCustomers) {
			return of(repairOrderCustomers.customers);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.getRepairOrderCustomer.replace(":id", id.toString()), this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					if (repairOrderCustomers) {
						repairOrderCustomers.customers = response;
					} else {
						this.cache.repairOrderCustomers.push({
							id: id,
							customers: response,
						});
					}
				}

				return response;
			})
		);
	}

	public getRepairOrderLineItems(id: number, fromCache: boolean = true): Observable<LineItem[]> {
		let repairOrderLineItems = this.cache.repairOrderLineItems.find((repairOrderLineItem) => repairOrderLineItem.id == id);

		if (fromCache && repairOrderLineItems) {
			return of(repairOrderLineItems.lineItems);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.getRepairOrderLineItems.replace(":id", id.toString()), this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					if (repairOrderLineItems) {
						repairOrderLineItems.lineItems = response;
					} else {
						this.cache.repairOrderLineItems.push({
							id: id,
							lineItems: response,
						});
					}
				}

				return response;
			})
		);
	}

	public getAllUsers(fromCache: boolean = true): Observable<User[]> {
		if (fromCache && this.cache.users && this.cache.users.length) {
			return of(this.cache.users);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.allUsers, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.users = response;
				}

				return response;
			})
		);
	}

	public clearUsersFromCache(): void {
		this.cache.users = [];
	}

	public createUser(user: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createUser, this.getRequestHeaders(), RequestMethod.Post, user).pipe(
			map((response) => {
				this.clearUsersFromCache();

				return response;
			})
		);
	}

	public updateUser(user: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.updateUser, this.getRequestHeaders(), RequestMethod.Post, user).pipe(
			map((response) => {
				this.clearUsersFromCache();
				return response;
			})
		);
	}

	public getAllPurchaseOrders(fromCache: boolean = true): Observable<PurchaseOrder[]> {
		if (fromCache && this.cache.purchaseOrders && this.cache.purchaseOrders.length) {
			return of(this.cache.purchaseOrders);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.allPurchaseOrders, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.purchaseOrders = response;
				}

				return response;
			})
		);
	}

	public clearPurchaseOrdersFromCache(): void {
		this.cache.purchaseOrders = [];
	}

	public createPurchseOrder(order: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createPurchaseOrder, this.getRequestHeaders(), RequestMethod.Post, order).pipe(
			map((response) => {
				this.clearPurchaseOrdersFromCache();

				return response;
			})
		);
	}

	public updatePurchaseOrder(order: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.updatePurchaseOrder, this.getRequestHeaders(), RequestMethod.Post, order).pipe(
			map((response) => {
				this.clearPurchaseOrdersFromCache();

				return response;
			})
		);
	}

	public addPurchaseOrderProducts(data: { purchaseOrderId: number; products: Product[] }): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.addPurchaseOrderProducts, this.getRequestHeaders(), RequestMethod.Post, data).pipe(
			map((response) => {
				this.clearPurchaseOrdersFromCache();

				return response;
			})
		);
	}

	public deletePurchaseOrderProducts(data: { purchaseOrderId: number; products: Product[] }): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.deletePurchaseOrderProducts, this.getRequestHeaders(), RequestMethod.Post, data).pipe(
			map((response) => {
				this.clearPurchaseOrdersFromCache();

				return response;
			})
		);
	}

	public deletePurchaseOrder(id: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.deletePurchaseOrder.replace(":id", id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null).pipe(
			map((response) => {
				this.clearPurchaseOrdersFromCache();

				return response;
			})
		);
	}

	public getAllCustomers(fromCache: boolean = true): Observable<Customer[]> {
		if (fromCache && this.cache.customers.length) {
			return of(this.cache.customers);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.allCustomers, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.customers = response;
				}

				return response;
			})
		);
	}

	public clearCustomersFromCache(): void {
		this.cache.customers = [];
	}

	public createCustomer(customer: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createCustomer, this.getRequestHeaders(), RequestMethod.Post, customer).pipe(
			map((response) => {
				this.clearCustomersFromCache();

				return response;
			})
		);
	}

	public createFile(formData: FormData): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createPurchaseOrderFiles, this.getAuthorizationHeader(), RequestMethod.Post, formData);
	}

	public getFile(id: number): Observable<any> {
		let requestHeaders = new HttpHeaders();
		let headers = this.getRequestHeaders();

		if (headers && headers.map) {
			headers.map((element) => {
				requestHeaders = requestHeaders.set(element.key, element.value);
			});
		}

		return this.http.get(ApiConfiguration.apiEndPoints.getFile.replace(":id", id.toString()), { headers: requestHeaders, responseType: "blob" });
	}

	public postFile(formData: FormData) {
		return this.sendRequest(ApiConfiguration.apiEndPoints.postFile, this.getAuthorizationHeader(), RequestMethod.Post, formData);
	}

	public updateCustomer(customer: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.updateCustomer, this.getRequestHeaders(), RequestMethod.Post, customer).pipe(
			map((response) => {
				this.clearCustomersFromCache();

				return response;
			})
		);
	}

	public getAllVehicles(fromCache: boolean = true): Observable<Vehicle[]> {
		if (fromCache && this.cache.vehicles && this.cache.vehicles.length) {
			return of(this.cache.vehicles);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.allVehicles, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.vehicles = response;
				}

				return response;
			})
		);
	}

	public clearVehiclesFromCache(): void {
		this.cache.vehicles = [];
	}

	public createVehicle(vehicle: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createVehicle, this.getRequestHeaders(), RequestMethod.Post, vehicle).pipe(
			map((response) => {
				this.clearVehiclesFromCache();

				return response;
			})
		);
	}

	public updateVehicle(vehicle: any): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.updateVehicle, this.getRequestHeaders(), RequestMethod.Post, vehicle).pipe(
			map((response) => {
				this.clearVehiclesFromCache();

				return response;
			})
		);
	}

	public getAllRepairOrders(offset = 0, fromCache: boolean = true): Observable<any> {
		//		Repair orders are returned by id desc
		let endpoint = ApiConfiguration.apiEndPoints.allRepairOrders;

		if (offset != null && offset != undefined && offset >= 0) {
			endpoint += `?offset=${offset}`;
		}

		if (fromCache && this.cache.repairOrders.hasOwnProperty(offset)) {
			let repairOrders: Array<RepairOrder> = [];

			for (const [key, value] of Object.entries(this.cache.repairOrders[offset])) {
				repairOrders.push(value as any);
			}

			if (repairOrders && repairOrders.length) {
				return of(repairOrders);
			}
		}
		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.repairOrders.push(response);
				}

				return response;
			})
		);
	}

	public getAllProducts(fromCache: boolean = true): Observable<Product[]> {
		if (fromCache && this.cache.products && this.cache.products.length) {
			return of(this.cache.products);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.allProducts, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.products = response;
				}

				return response;
			})
		);
	}

	public clearProductsFromCache(): void {
		this.cache.products = [];
	}

	public createProduct(product: Product): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createProduct, this.getRequestHeaders(), RequestMethod.Post, product).pipe(
			map((response) => {
				this.clearProductsFromCache();

				return response;
			})
		);
	}

	public createVehicleProducts(vehicleProducts: { vehicleId: number; products: Product[] }): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createVehicleProduct, this.getRequestHeaders(), RequestMethod.Post, vehicleProducts).pipe(
			map((response) => {
				this.deleteVehicleProductFromCache(vehicleProducts.vehicleId);

				return response;
			})
		);
	}

	public getVehicleProducts(vehicleId: number, fromCache: boolean = true): Observable<Product[]> {
		let vehicleProducts = this.cache.vehicleProducts.find((vehicleProducts) => {
			return vehicleProducts.id == vehicleId;
		});

		if (fromCache && vehicleProducts) {
			return of(vehicleProducts.products);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.getVehicleProducts + "/" + vehicleId, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					if (vehicleProducts) {
						vehicleProducts.products = response;
					} else {
						this.cache.vehicleProducts.push({
							id: vehicleId,
							products: response,
						});
					}
				}

				return response;
			})
		);
	}

	public updateVehicleProduct(vehicleProduct: { vehicleId: number; product: Product }): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.updateVehicleProduct, this.getRequestHeaders(), RequestMethod.Post, vehicleProduct).pipe(
			map((response) => {
				this.deleteVehicleProductFromCache(vehicleProduct.vehicleId);

				return response;
			})
		);
	}

	public deleteVehicleProducts(vehicleProducts: { vehicleId: number; products: Product[] }): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.deleteVehicleProducts, this.getRequestHeaders(), RequestMethod.Post, vehicleProducts).pipe(
			map((response) => {
				this.deleteVehicleProductFromCache(vehicleProducts.vehicleId);

				return response;
			})
		);
	}

	public getStores(fromCache: boolean = true): Observable<Store[]> {
		if (fromCache && this.cache.stores.length) {
			return of(this.cache.stores);
		}

		return this.sendRequest(ApiConfiguration.apiEndPoints.getStores, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.stores = response;
				}

				return response;
			})
		);
	}

	public clearStoresFromCache(): void {
		this.cache.stores = [];
	}

	public createStore(store: Store) {
		return this.sendRequest(ApiConfiguration.apiEndPoints.createStore, this.getRequestHeaders(), RequestMethod.Post, store).pipe(
			map((response) => {
				this.clearStoresFromCache();

				return response;
			})
		);
	}

	public updateStore(store: Store) {
		return this.sendRequest(ApiConfiguration.apiEndPoints.updateStore, this.getRequestHeaders(), RequestMethod.Post, store).pipe(
			map((response) => {
				this.clearStoresFromCache();
				return response;
			})
		);
	}

	public updateUserTime(userId: number, userTimeId: number, clockInTime: Date, clockOutTime: Date): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.updateUserTime.replace(":id", userId.toString()).replace(":userTimeId", userTimeId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, {
			clockInTime: clockInTime.toISOString(),
			clockOutTime: clockOutTime.toISOString(),
		});
	}

	public createUserTime(userId: number, clockInTime: Date, clockOutTime: Date): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.createUserTime.replace(":id", userId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, {
			clockInTime: clockInTime.toISOString(),
			clockOutTime: clockOutTime.toISOString(),
		});
	}

	public updateVehicleProducts(vehicleId: number, products: Product[]): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.updateVehicleProducts.replace(":id", vehicleId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, products).pipe(
			map((response) => {
				this.deleteVehicleProductFromCache(vehicleId);
				return response;
			})
		);
	}

	public snapshotVehicleInventory(vehicleId: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.snapshotVehicleInventory.replace(":id", vehicleId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post);
	}

	public getVehicleInventorySnapshots(vehicleId: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.snapshotVehicleInventory.replace(":id", vehicleId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get);
	}

	public deleteVehicleInventorySnapshot(vehicleId: number, snapshotId: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.vehicleInventorySnapshot.replace(":id", snapshotId.toString()).replace(":vehicleId", vehicleId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete);
	}

	public mergeIntoRepairOrder(repairOrderId: number, selectedOrderIds: number[]): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.repairOrderMergeInto.replace(":id", repairOrderId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, selectedOrderIds);
	}

	public deletePreventativeMaintenanceRecord(recordId: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.deletePreventativeMaintenanceRecord.replace(":id", recordId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null);
	}

	public savePreventativeMaintenanceRecord(record: PreventativeMaintenanceRecord): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.savePreventativeMaintenanceRecord, this.getRequestHeaders(), RequestMethod.Post, record);
	}

	public getConfigurationPreventativeMaintenanceRecords(configurationId: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getConfigurationPreventativeMaintenanceRecords.replace(":id", configurationId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public deletePreventativeMaintenanceConfiguration(configurationId: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.deletePreventativeMaintenanceConfiguration.replace(":id", configurationId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null);
	}

	public savePreventativeMaintenanceConfiguration(configuration: PreventativeMaintenanceConfiguration): Observable<any> {
		return this.sendRequest(ApiConfiguration.apiEndPoints.savePreventativeMaintenanceConfiguration, this.getRequestHeaders(), RequestMethod.Post, configuration);
	}

	public convertPreventativeMaintenanceRecordToRepairOrder(recordId: number) {
		let endpoint = ApiConfiguration.apiEndPoints.convertPreventativeMaintenanceRecordToRepairOrder.replace(":id", recordId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getCustomerPreventativeMaintenanceConfigurations(customerId: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getCustomerPreventativeMaintenanceConfigurations.replace(":id", customerId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getPreventativeMaintenanceRecord(id: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getPreventativeMaintenanceRecord.replace(":id", id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getPreventativeMaintenanceConfiguration(id: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getPreventativeMaintenanceConfiguration.replace(":id", id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getCustomer(id: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getCustomer.replace(":id", id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getUser(id: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getUser.replace(":id", id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getVehicle(id: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getVehicle.replace(":id", id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getUserTypes(): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.getUserTypes;

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null);
	}

	public getAllQuotes(offset = 0, fromCache: boolean = true): Observable<any> {
		//		Repair orders are returned by id desc
		let endpoint = ApiConfiguration.apiEndPoints.getQuotes;

		if (offset != null && offset != undefined && offset >= 0) {
			endpoint += `?offset=${offset}`;
		}

		if (fromCache && this.cache.quotes.hasOwnProperty(offset)) {
			let quotes: Array<Quote> = [];

			for (const [key, value] of Object.entries(this.cache.quotes[offset])) {
				quotes.push(value as any);
			}

			if (quotes && quotes.length) {
				return of(quotes);
			}
		}
		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				if (response) {
					this.cache.quotes.push(response);
				}

				return response;
			})
		);
	}

	public updateQuote(quote: Quote) {
		let endpoint = ApiConfiguration.apiEndPoints.updateQuote.replace(":id", quote.id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, quote).pipe(
			map((response) => {
				this.deleteQuoteFromCache(quote.id);
				return response;
			})
		);
	}


	public createQuote(quote: Quote) {
		let endpoint = ApiConfiguration.apiEndPoints.createQuote;

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, quote).pipe(map(response => {
			this.clearQuotesCache();

			return response;
		}));
	}

	public searchQuotes(searchText): Observable<any> {
		if (!searchText) searchText = "";

		return this.sendRequest(ApiConfiguration.apiEndPoints.searchQuotes, this.getRequestHeaders(), RequestMethod.Post, { text: searchText });
	}

	public deactivateQuote(id: number): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.deactivateQuote.replace(":id", id.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Delete, null).pipe(
			map((response) => {
				this.deleteQuoteFromCache(id);

				return response;
			})
		);
	}

	public convertQuoteToRepairOrder(quoteId: number, userId: number) {
		let endpoint = ApiConfiguration.apiEndPoints.convertQuoteToRepairOrder.replace(":id", quoteId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Post, { assignTo: userId }).pipe(
			map((response) => {
				return response;
			})
		);
	}

	public resetUserPassword(userId: string) {
		let endpoint = ApiConfiguration.apiEndPoints.quickResetUserPassword.replace(":id", userId.toString());

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get);
	}

	public customerVehicles(id): Observable<any> {
		let endpoint = ApiConfiguration.apiEndPoints.customerVehicles.replace(":id", id);

		return this.sendRequest(endpoint, this.getRequestHeaders(), RequestMethod.Get, null).pipe(
			map((response) => {
				return response;
			})
		);
	}

	protected getRequestHeaders() {
		return [
			{ key: "Content-Type", value: "application/json" },
			{ key: "token", value: this.token },
		];
	}

	protected getAuthorizationHeader() {
		return [{ key: "token", value: this.token }];
	}

	protected sendRequest(url: string, headers: any[], method: string, body: any = null): Observable<any> {
		if (!method) {
			throw new Error("Error sending web request. Request method type was null");
		}

		if (!navigator.onLine) {
			console.error("No network connection established. Cannot make request.");
			return throwError("No internet connection detected.");
		}

		if (!this.token) {
			console.warn("No api token has been obtained. Please get a token before calling the api.");
		}

		let requestHeaders = new HttpHeaders();
		if (headers && headers.map)
			headers.map((element) => {
				requestHeaders = requestHeaders.set(element.key, element.value);
			});

		let getDataFromResponse = map((response: any) => response.data);

		switch (method) {
			case RequestMethod.Get:
				return this.http.get(url, { headers: requestHeaders }).pipe(getDataFromResponse);
			case RequestMethod.Post:
				return this.http.post(url, body, { headers: requestHeaders }).pipe(getDataFromResponse);
			case RequestMethod.Delete:
				return this.http.delete(url, { headers: requestHeaders }).pipe(getDataFromResponse);
			default:
				throw new Error("Error sending web request. Unsupported request type: " + method);
		}
	}
}
