<div  class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<mat-card fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="50%" style="overflow: auto; max-height: 100vh;">
		<mat-card-title><h2 class="Anton">Purchase Order</h2></mat-card-title>

		<mat-card-content>
			<form *ngIf="orderForm" [formGroup]="orderForm">
				<div fxLayout.xs="column" fxLayout="row">
					<mat-form-field class="w-100 mr-3">
						<mat-label>Name</mat-label>
						<input readonly formControlName="name" matInput type="text">
					</mat-form-field>

					<div class="w-100 mr-3">
						<store-picker
							[readonly]="true"
							[storeId]="orderForm.get('store_id').value"
							[validators]="[{key: 'required', message: 'Store is <strong>required</strong>'}]" 
							[formGroupParent]="orderForm" 
							[formGroupControlName]="'store_id'">
						</store-picker>
					</div>

					<mat-form-field class="w-100">
						<mat-label>Total Cost</mat-label>
						<span *ngIf="orderForm.get('total_cost').value < 0" matPrefix>(-) &nbsp;</span>
						<input readonly formControlName="total_cost" matInput type="number">
					</mat-form-field>
				</div>

				<loading-button
					class="mr-3"
					[text]="'Add Product'"
					[action]="onAddProductClicked"
				></loading-button>
				
				<div style="max-width: calc(100vw - 100px); overflow: auto;">
					<entity-selector-table
						[tableMaxHeight]="'45vh'"
						[dataSource]="tableDataSource" 
						[selectorTableColumns]="selectorTableColumns"
					>
					</entity-selector-table>
				</div>
			
			</form>

			<mat-divider></mat-divider>

			<br>
			
			<div fxLayout="row" class="mb-3">
				<loading-button
					class="mr-3"
					[text]="'Submit'"
					[action]="onPurchaseOrderFormSubmit"
				></loading-button>

				<loading-button
					[text]="'Download Receipt'"
					[action]="onDownloadReceipt"
				></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>