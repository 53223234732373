import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { Stage } from '../../../classes/purchaseOrder';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';

@Component({
	templateUrl: './purchaseOrder.html',
	styleUrls: ['./purchaseOrder.scss'],
	selector: 'new-purchase-order'
})
export class NewPurchaseOrderComponent extends FormComponent {
	public orderForm: UntypedFormGroup = null;

	public selectedFiles: Array<File> = [];
	public loading = false;

	constructor(public userService: UserService, private api: ApiService, private formBuilder: UntypedFormBuilder) {
		super();
		this.initializeOrderForm();
	}

	public initializeOrderForm() {
		this.orderForm = this.formBuilder.group({
			id: new UntypedFormControl(null),
			user_id: new UntypedFormControl(this.userService.user.id),
			photos: new UntypedFormControl(null),
			total_cost: new UntypedFormControl(0, [Validators.required]),
			name: new UntypedFormControl(null, [Validators.required]),
			store_id: new UntypedFormControl(null, [Validators.required]),

			//      Will get submitted as moved to in receiving
			purchaseOrderProcessingStepId: new UntypedFormControl(Stage.InReceiving, [Validators.required])
		});
	}

	public onFileChange(event) {
		if (event.target.files && event.target.files.length) {
			this.selectedFiles = event.target.files;
		}
	}

	public onGetNextPurchaseOrderName = () => {
		return this.api.getNextPurchaseOrderName().toPromise().then(result => {
			this.orderForm.get('name').patchValue(result.nextPurchaseOrderName);
		}).catch(error => {
			console.error(error);
		});
	}

	public onPurchaseOrderFormSubmit = () => {
		let purchaseOrderSubmitted = Promise.resolve();

		if (!this.orderForm || this.orderForm.invalid || !this.selectedFiles || !this.selectedFiles.length) {
			this.orderForm.markAllAsTouched();
			return purchaseOrderSubmitted;
		}

		this.loading = true;

		return purchaseOrderSubmitted = this.api.createPurchseOrder(this.orderForm.value).toPromise().then(result => {
			//      Now we need to upload the selected files
			let formData: FormData = new FormData();

			formData.append('purchaseOrderId', result.id);
			for (var i = 0; i < this.selectedFiles.length; ++i) {
				formData.append('files', this.selectedFiles[i]);
			}

			return this.api.createFile(formData).toPromise().then(response => {
				this.initializeOrderForm();
				this.loading = false;
			}).then(() => {
				this.selectedFiles = [];
				SnackBarService.openSnackBarAlert('Purchase order succesfully created.');
			});
		}).catch(error => {
			SnackBarService.openSnackBarAlert(error.error.message, 'red');
			this.loading = false;
			console.error(error);
		})
	}
}