import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'customer-labor-rate',
	styleUrls: [],
	template: `
        <div *ngIf="selectedLaborRate" fxLayoutGap="15px" fxLayout="row" [formGroup]="selectedLaborRate">
			<mat-select #laborRateSelector (selectionChange)="laborRateSelected($event)">
				<mat-option *ngFor="let laborRate of laborRates.value; index as i" [value]="i">{{ capitalizeFirstLetter(laborRate.equipment_type) }}</mat-option>
			</mat-select>

			<mat-form-field>
				<mat-label>Rate</mat-label>
				<input formControlName="rate" matInput type="number" required/>
			</mat-form-field>
        </div>
    `
})
export class CustomerLaborRateComponent implements OnInit, AfterViewInit {
	@Input('laborRates') laborRates: FormArray<FormGroup>;
	public selectedLaborRate: FormGroup = null;

	@ViewChild(MatSelect) laborRateSelector: MatSelect;

	constructor(public changeDetector: ChangeDetectorRef) { }

	public ngOnInit(): void {
		if (this.laborRates) {
			this.selectedLaborRate = this.laborRates.at(0);
		}
	}

	public ngAfterViewInit(): void {
		if (this.selectedLaborRate && this.laborRateSelector) {
			this.laborRateSelector.options.forEach((item, index) => {
				if (index == 0) {
					item.select();
					this.changeDetector.detectChanges();
				}
			});
		}
	}

	public laborRateSelected(event: MatSelectChange) {
		this.selectedLaborRate = this.laborRates.at(event.value);
	}

	public capitalizeFirstLetter(value: string) {
		return value[0].toUpperCase() + value.slice(1, value.length);
	};


}

class CustomerLaborRate {
	equipment_type: string;
	rate: number;
}