<mat-form-field *ngIf="!loading" class="w-100">
	<mat-label>Store</mat-label>
	<input [readonly]="readonly" matInput placeholder="Store" [formControl]="control" [matAutocomplete]="auto">
	<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
		<mat-option *ngFor="let store of filteredStores | async" [value]="store.id">
			{{store.name}}
		</mat-option>
	</mat-autocomplete>
	<mat-error  *ngFor="let validator of validators">
		<span *ngIf="formControlHasError(control, validator.key)" [innerHTML]="validator.message"></span>
	</mat-error>
</mat-form-field>