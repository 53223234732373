<button 
	[style.backgroundImage]="'url(' + user.photoUrl + ')'" 
	mat-fab 
	style="width: 35px; height: 35px;"  
	[matMenuTriggerFor]="googleMenu" 
	*ngIf="user">
</button>

<google-login-button  *ngIf="!user" [height]="35" [width]="35"></google-login-button>

<mat-menu #googleMenu="matMenu">
	<button (click)="openGmail()" mat-menu-item *ngIf="authorization.isLoggedIn()">Gmail</button>
</mat-menu>

