import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, catchError } from 'rxjs/operators';
import { Vehicle } from 'src/app/classes/vehicle';
import { ApiService } from 'src/app/services/api.service';
import { ReactiveFormWrapper } from 'src/app/services/reactiveFormsWrapper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
	selector: 'vehicle-picker',
	templateUrl: './vehiclePicker.html',
	styleUrls: ['./vehiclePicker.scss'],
})
export class VehiclePicker extends ReactiveFormWrapper {
	private _vehicleId: number = null;
	@Output() vehicleIdChange = new EventEmitter<number>();
	get vehicleId(): number {
		return this._vehicleId;
	}
	@Input() set vehicleId(id: number) {
		this._vehicleId = id;
		this.control.patchValue(this._vehicleId, { emitEvent: false });
		this.vehicleIdChange.emit(this._vehicleId);
	}

	@Input() optionSelectedHandler = (event: MatAutocompleteSelectedEvent) => {};
	@Input() label = 'Vehicle';
	@Input() isDisabled: boolean = false;

	public vehicles: Vehicle[];
	public filteredVehicles: Observable<Vehicle[]> = of([]);

	constructor(private api: ApiService) {
		super();
		this.controlSet.subscribe(() => {
			this.refreshVehiclesList();
		});
		this.refreshVehiclesList();
	}

	public optionSelected(event: MatAutocompleteSelectedEvent) {
		if (this.optionSelectedHandler) {
			this.optionSelectedHandler(event);
		}

		if (event.option.selected) {
			if (!isNaN(event.option.value)) {
				this.vehicleId = event.option.value;
			}
		} else {
			this.vehicleId = null;
		}
	}

	public refreshVehiclesList(customerId?: number) {
		let gettingVehicles = Promise.resolve();

		//if (customerId) {
		//	gettingVehicles = this.api.customerVehicles(customerId).pipe(catchError(error => of([]))).toPromise() as any;
		//} else {
		gettingVehicles = this.api
			.getAllVehicles(false)
			.pipe(catchError((error) => of([])))
			.toPromise() as any;
		//}

		return gettingVehicles.then((vehicles: any) => {
			this.vehicles = vehicles;
			this.filteredVehicles = this.control.valueChanges.pipe(
				startWith(''),
				debounceTime(200),
				distinctUntilChanged(),
				map((userText) => this.filterVehicles(userText)),
				catchError((error) => {
					console.error(error);
					return of([]);
				})
			);
		});
	}

	public filterVehicles(value: string): Vehicle[] {
		if (this.vehicles) {
			return this.vehicles.filter((vehicle) => {
				if (value == null || value == undefined || value == '') return true;

				value = value.toString().toLowerCase();

				return (
					(vehicle.vin || '').toLowerCase().includes(value) ||
					(vehicle.unit_number || '').toLowerCase().includes(value) ||
					(vehicle.license_number || '').toLowerCase().includes(value)
				);
			});
		}

		return [];
	}

	public displayFn = (id) => {
		if (this.vehicles) {
			let theVehicle = this.vehicles.find((vehicle) => vehicle.id == id);
			let display = null;

			if (theVehicle && id >= 0) {
				display = (theVehicle.unit_number || '') + ' - ' + (theVehicle.vin || '');
			}

			return display;
		}

		return '';
	};
}
