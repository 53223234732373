<div class="mt-3 overflow maxHeight" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">

	<div *ngIf="record && record.fields" fxLayout="column" fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="50%" fxLayoutGap="15px">
			<mat-form-field appearance="fill">
				<mat-label>Completed Date</mat-label>
				<input readonly disabled="true" [(ngModel)]="record.completed" matInput [matDatepicker]="picker">
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
	
			<vehicle-picker
				isDisabled="true"
				[vehicleId]="record.vehicle_id"
				[optionSelectedHandler]="vehicleSelectedHandler">
			</vehicle-picker>

			<div fxLayout="row" fxLayoutGap="15px">
				<loading-button
					[text]="'Get PDF'"
					[action]="createAndDownloadPdf"
					[materialColor]="'primary'"
				></loading-button>

				<loading-button
					[text]="'Delete Record'"
					[action]="deleteRecord"
					[materialColor]="'warn'"
				></loading-button>
			</div>
			

			<mat-vertical-stepper [linear]="isLinear && !isReadOnly" #stepper>
				<mat-step *ngFor="let form of stepperFormGroups; index as i;" [stepControl]="form">
					<form [formGroup]="form">
						<ng-template matStepLabel>{{ form.get('title').value }}</ng-template>
						<div fxLayout="column" fxLayoutGap="15px">
							<mat-slide-toggle [disabled]="isReadOnly" (change)="needsRepairValueChanged(form)" formControlName="isNewLineItem">Needs Repair (NR)</mat-slide-toggle>

							<mat-form-field *ngIf="form.get('isNewLineItem').value">
								<mat-label>Reason</mat-label>
								<input [readonly]="isReadOnly" matInput formControlName="value" required>
							</mat-form-field>

							<photo-input
								[readonly]="isReadOnly"
								[(ids)]="record.fields[i].photoIds"
								[title]="'Add Photos'"
							></photo-input>
						</div>
						
						<div fxLayout="row" fxLayoutGap="15px">
							<button *ngIf="i > 0" mat-raised-button matStepperPrevious color="warn">Back</button>
							<button *ngIf="i < stepperFormGroups.length" mat-raised-button color="primary" matStepperNext>Next</button>
						</div>
					</form>
				</mat-step>
			
				<mat-step>
					<ng-template matStepLabel>Done</ng-template>

					<p>You have completed the preventative maintenance check. Please save your work to continue.</p>

					<div fxLayout="row" fxLayoutGap="15px">
						<button mat-raised-button matStepperPrevious color="warn">Back</button>
						<loading-button
							[text]="'Save'"
							[action]="saveRecord"
							[materialColor]="'primary'"
						></loading-button>
					</div>
				</mat-step>
			</mat-vertical-stepper>

		<!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
	
			<loading-button
				[text]="'Convert To Repair Order'"
				[action]="createRepairOrder"
				[materialColor]="'primary'"
			></loading-button>
	
		</div> -->
	</div>
</div>