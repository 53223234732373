<div
	class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<div fxLayout="column" fxFlex.xs="95" fxFlex.sm="95" fxFlex.md="50" fxFlex="50" fxLayoutGap="15px">
		<customer-picker
			name="customer"
			[customerId]="customerId"
			[customerSelectedHandler]="customerSelectedHandler"
			[onChange]="onCustomerChange"
		>
		</customer-picker>

		<div fxLayout="row" fxFlex.xs="95" fxFlex.sm="95" fxFlex.md="50" fxFlex="50" fxLayoutGap="15px">
			<button *ngIf="userService.isAdmin()" (click)="editConfigurationClicked()" mat-mini-fab color="primary">
				<mat-icon>edit</mat-icon>
			</button>

			<mat-form-field>
				<mat-label>Customer Configurations</mat-label>
				<mat-select [(value)]="selectedConfigurationId" (selectionChange)="onConfigurationChange($event)">
					<mat-option>None</mat-option>
					<mat-option *ngFor="let config of customerConfigurations" [value]="config.id">{{ config.title }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<vehicle-picker
			[(vehicleId)]="selectedVehicleId">
		</vehicle-picker>

		<button (click)="createRecord()" mat-raised-button color="primary">Start New Preventative Maintenance Record</button>

		<button *ngIf="userService.isAdmin()" (click)="createNewConfiguration()" mat-raised-button color="primary">Create New Configuration</button>

		<div style="max-width: calc(100vw - 100px); overflow: auto;">
			<entity-selector-table
				[dataSource]="tableDataSource" 
				[selectorTableColumns]="selectorTableColumns"
			>
			</entity-selector-table>
		</div>
	</div>
</div>