<mat-form-field *ngIf="!loading" class="w-100">
	<mat-label>User</mat-label>
	<input [(ngModel)]="userId" matInput placeholder="User" [formControl]="control" [matAutocomplete]="auto">
	<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
		<mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
			{{user.firstname }} {{ user.lastname }} - {{ user.employee_number }}
		</mat-option>
	</mat-autocomplete>
	<mat-error  *ngFor="let validator of validators">
		<span *ngIf="formControlHasError(control, validator.key)" [innerHTML]="validator.message"></span>
	</mat-error>
</mat-form-field>