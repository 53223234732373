import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserPicker } from '../users/user_picker/userPicker.component';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from '../snack_bar_alert/snackBarAlert';
import { MatPaginator } from '@angular/material/paginator';
import { PdfMakeService } from 'src/app/services/pdfMake.service';
import { User } from 'src/app/classes/user';
import { UserFriendlyHours } from 'src/app/pipes/hours.pipe';
import { DatePipe } from '@angular/common';

export class PayRollInformation {
	public days: Array<PayrollDay> = [];
}

export class NgbTimeParts {
	hour: number = null;
	minute: number = null;
}

export class NgbDateParts {
	year: number = null;
	month: number = null;
	day: number = null;
}

export class UserTimeInstance {
	date: Date = null;
	timeParts: NgbTimeParts = new NgbTimeParts();
	dateParts: NgbDateParts = new NgbDateParts();
}

export class PayrollDay {
	public userTimeId: number = null;
	public repairOrderHours: number = null;
	public workedHours: number = null;
	public day: Date = null;
	public clockIn: UserTimeInstance = null;
	public clockOut: UserTimeInstance = null;

}

@Component({
	selector: 'payroll',
	templateUrl: './payroll.html',
	styleUrls: ['./payroll.scss']
})
export class PayrollComponent implements AfterViewInit {
	@ViewChild(UserPicker) userPicker: UserPicker;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	public matTableDataSource = new MatTableDataSource<PayrollDay>();

	public payrollInformation: PayRollInformation = new PayRollInformation();
	public range: UntypedFormGroup = new UntypedFormGroup({
		start: new UntypedFormControl(),
		end: new UntypedFormControl()
	});


	public totalHours: number = 0;
	public repairOrderHours: number = 0;

	public loading: boolean = false;
	public tableColumns: string[] = ['saveButton', 'day', 'repairOrderHours', 'workedHours', 'clockIn', 'clockOut'];

	constructor(public userService: UserService,
		private api: ApiService,
		public pdfMakeService: PdfMakeService,
		private userFriendlyHours: UserFriendlyHours,
		private datePipe: DatePipe) { }

	public ngAfterViewInit() {
		if (this.matTableDataSource && this.paginator) {
			this.matTableDataSource.paginator = this.paginator;
		}
	}

	public getStartDateControl() { return this.range.get('start'); }
	public getEndDateControl() { return this.range.get('end'); }

	public refreshPayrollInformationClicked = () => {
		if (this.userPicker && this.userPicker.userId && this.range.valid) {
			this.loading = true;
			return this.api.getUserPayrollInformation(
				this.userPicker.userId,
				[this.getStartDateControl().value, this.getEndDateControl().value]
			).toPromise().then(response => {
				this.payrollInformation.days = response.map(day => {
					let payrollDay = new PayrollDay();
					payrollDay.repairOrderHours = day.repairOrderHours;
					payrollDay.userTimeId = day.userTimeId;
					payrollDay.workedHours = day.workedHours;
					payrollDay.day = day.day;

					if (day.clockInTime) {
						let clockInDate = new Date(day.clockInTime);
						payrollDay.clockIn = new UserTimeInstance();

						payrollDay.clockIn.date = clockInDate;
						payrollDay.clockIn.timeParts.hour = clockInDate.getHours();
						payrollDay.clockIn.timeParts.minute = clockInDate.getMinutes();
						payrollDay.clockIn.dateParts.day = clockInDate.getDate();
						payrollDay.clockIn.dateParts.month = clockInDate.getMonth() + 1;
						payrollDay.clockIn.dateParts.year = clockInDate.getFullYear();
					}

					if (day.clockOutTime) {
						let clockOutDate = new Date(day.clockOutTime);
						payrollDay.clockOut = new UserTimeInstance();

						payrollDay.clockOut.date = clockOutDate;
						payrollDay.clockOut.timeParts.hour = clockOutDate.getHours();
						payrollDay.clockOut.timeParts.minute = clockOutDate.getMinutes();
						payrollDay.clockOut.dateParts.day = clockOutDate.getDate();
						payrollDay.clockOut.dateParts.month = clockOutDate.getMonth() + 1;
						payrollDay.clockOut.dateParts.year = clockOutDate.getFullYear();
					}

					return payrollDay;
				});
				this.totalHours = 0;
				this.repairOrderHours = 0;

				if (this.payrollInformation.days && this.payrollInformation.days.length) {
					this.payrollInformation.days.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime()).map(day => {
						if (!Number.isNaN(day.workedHours)) {
							let hours = Number(day.workedHours);
							this.totalHours += hours;
						}

						if (!Number.isNaN(day.repairOrderHours)) {
							let hours = Number(day.repairOrderHours);
							this.repairOrderHours += hours;
						}
					});
				}

				this.matTableDataSource.data = this.payrollInformation.days;
			}).catch(error => {
				console.error(error);
			}).finally(() => {
				this.loading = false;
			});
		}

		return Promise.resolve();
	}

	public upsertUserTime = (day: PayrollDay) => {
		if (day.clockIn && day.clockOut) {
			//		Update the underlying Date object
			day.clockIn.date = new Date(
				day.clockIn.dateParts.year, day.clockIn.dateParts.month - 1, day.clockIn.dateParts.day,
				day.clockIn.timeParts.hour, day.clockIn.timeParts.minute, 0, 0
			)

			//		Update the underlying Date object
			day.clockOut.date = new Date(
				day.clockOut.dateParts.year, day.clockOut.dateParts.month - 1, day.clockOut.dateParts.day,
				day.clockOut.timeParts.hour, day.clockOut.timeParts.minute, 0, 0
			)

			let userTimesEdited = null;

			if (day.userTimeId) {
				userTimesEdited = this.api.updateUserTime(this.userPicker.userId, day.userTimeId, day.clockIn.date, day.clockOut.date).toPromise();
			} else {
				userTimesEdited = this.api.createUserTime(this.userPicker.userId, day.clockIn.date, day.clockOut.date).toPromise();
			}

			return userTimesEdited.then(() => {
				SnackBarService.openSnackBarAlert('User time saved.')
			}).catch(error => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
		}

		return Promise.resolve();
	}

	public addUserTimeClicked = () => {
		if (!this.payrollInformation || !this.payrollInformation.days) {
			this.payrollInformation = new PayRollInformation();
			this.payrollInformation.days = [];
		}

		let payrollDay = new PayrollDay();
		payrollDay.clockIn = new UserTimeInstance();
		payrollDay.clockOut = new UserTimeInstance();
		payrollDay.repairOrderHours = 0;
		payrollDay.workedHours = 0;

		let tableData = this.matTableDataSource.data;
		tableData.unshift(payrollDay);
		this.matTableDataSource.data = tableData;
		this.matTableDataSource.paginator.firstPage();

		return Promise.resolve();
	}

	public downloadUserTimeSheetClicked = () => {
		return this.api.getAllUsers().toPromise().then(users => {
			let theUser: User = null;
			if (users) {
				theUser = users.find(user => user.id == this.userPicker.userId);
			}

			if (theUser) {
				let timeSheetInformationTable = {
					table: {
						widths: ['*', '*', '*', '*', '*'],
						body: [
							[
								{
									bold: true,
									text: `Day`,
									border: [false, false, false, true]
								},
								{
									bold: true,
									text: `Repair Order Hours`,
									border: [false, false, false, true]
								},
								{
									bold: true,
									text: `Worked Hours`,
									border: [false, false, false, true]
								},
								{
									bold: true,
									text: `Clock In`,
									border: [false, false, false, true]
								},
								{
									bold: true,
									text: `Clock Out`,
									border: [false, false, false, true]
								}
							],
						]
					}
				};

				let timeSheetBody = timeSheetInformationTable.table.body as any[];

				if (this.payrollInformation && this.payrollInformation.days) {
					this.payrollInformation.days.forEach(payRollDay => {
						timeSheetBody.push([
							this.datePipe.transform(payRollDay.day, 'shortDate'),
							this.userFriendlyHours.transform(payRollDay.repairOrderHours),
							this.userFriendlyHours.transform(payRollDay.workedHours),
							payRollDay.clockIn ? this.datePipe.transform(payRollDay.clockIn.date, 'short') : '',
							payRollDay.clockOut ? this.datePipe.transform(payRollDay.clockOut.date, 'short') : ''
						]);
					});
				}


				let timesheetDocument = {
					content: [
						{
							table: {
								widths: ['*', 'auto', "*"],
								body: [
									[
										{ text: '', border: [false, false, false, false], },
										{ text: `Time Sheet ${this.datePipe.transform(this.getStartDateControl().value, 'shortDate')} - ${this.datePipe.transform(this.getEndDateControl().value, 'shortDate')}`, border: [false, false, false, false], bold: true },
										{ text: '', border: [false, false, false, false] }
									]
								]
							}
						},
						`FirstName: ${theUser.firstname || ''}
						LastName: ${theUser.lastname || ''}
						Employee Number: ${theUser.employee_number || ''}
						Username: ${theUser.username || ''}
						Email: ${theUser.email || ''}
						Phone: ${theUser.mobile_phone || ''}
						`,
						``,
						{ text: `Total Hours: ${this.userFriendlyHours.transform(this.totalHours)}`, bold: true },
						{ text: `Total Repair Order Hours: ${this.userFriendlyHours.transform(this.repairOrderHours)}`, bold: true },
						`
						
						`,
						timeSheetInformationTable
					]
				};

				let filename = `${theUser.username}_${theUser.employee_number}_${this.datePipe.transform(this.getStartDateControl().value, 'shortDate')}-${this.datePipe.transform(this.getEndDateControl().value, 'shortDate')}`;
				this.pdfMakeService.pdfMake.createPdf(timesheetDocument).download(filename);
			}
		}).catch(error => {
			SnackBarService.openSnackBarAlert(`An error occurred while creating the time sheet. Error: ${error.error.message}`);
			console.error(error);
		});
	}
}