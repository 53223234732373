import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild, AfterViewInit, ComponentFactoryResolver, ChangeDetectorRef } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray, AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { ApiService } from "../../../services/api.service";
import { ConfirmModal } from "../../confirm_modal/confirmModal";
import { RepairOrder, RepairOrderProcessingStep } from "../../../classes/repairOrder";
import { InvoiceService } from "../../../services/invoice.service";
import { GetVehicleProductsModal } from "../../vehicles/get_vehicle_products_modal/getVehicleProductsModal";
import { LocalStorageService } from "../../../services/local_storage.service";
import { FormComponent } from "src/app/services/FormComponentHelper";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Subscription } from "rxjs";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatTableDataSource } from "@angular/material/table";
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from "../../entity_selector_table/entitySelectorTable";
import { MatDialog } from "@angular/material/dialog";
import { SnackBarService } from "../../snack_bar_alert/snackBarAlert";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatTabGroup } from "@angular/material/tabs";
import { VehiclePicker } from "../../vehicles/vehicle_picker/vehiclePicker.component";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { trimToNumber } from "src/app/services/Helper";
import { Product, LineItem } from "src/app/classes/quote";
import { repairOrderStorageKey } from "../repairOrdersComponent";
import { MatSelectChange } from "@angular/material/select";

@Component({
	templateUrl: "./repairOrder.html",
	styleUrls: ["./repairOrder.scss"],
	selector: "repair-order",
})
export class RepairOrderComponent extends FormComponent implements OnDestroy, AfterViewInit {
	public orderForm: UntypedFormGroup = null;

	_order: RepairOrder = null;
	get order(): RepairOrder {
		return this._order;
	}

	@Input("order")
	set order(value: RepairOrder) {
		if (value) {
			//      Create copy of the order and use it for editing
			this._order = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeOrderForm();

			if (this.tabView) {
				this.tabView.realignInkBar();
			}
		}
	}

	@Output() refreshRepairOrders: EventEmitter<{ id: number, callback: any }> = new EventEmitter<any>();

	@ViewChild(MatTabGroup) tabView: MatTabGroup;
	public selectedTabIndex = null;
	public tabIndexSubscription: Subscription = null;

	@ViewChild(VehiclePicker) vehiclePicker: VehiclePicker;

	public loading = false;
	public taxExempt: boolean = false;
	public taxRate: number = 0.0;
	public otherFees: number = 0.0;
	public disposalFees: number = 0.0;

	public selectedLineItem: UntypedFormGroup = null;
	public selectedLineItemIndex: number = -1;

	public isClosed: boolean = false;
	public isComplete: boolean = false;
	public isBilled: boolean = false;
	private repairOrderChangedSubscription: Subscription = null;

	public tableDataSource = new MatTableDataSource();
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	@Output("initialized") initialized: EventEmitter<void> = new EventEmitter<void>();

	constructor(public userService: UserService, private api: ApiService, private formBuilder: UntypedFormBuilder, private dialog: MatDialog, private invoiceService: InvoiceService, private storage: LocalStorageService) {
		super();
		this.setupProductEntitySelectorTableColumns();
	}

	public ngAfterViewInit() {
		if (this.tabView) {
			this.tabIndexSubscription = this.tabView.selectedIndexChange.subscribe((index) => {
				this.selectedTabIndex = index;
			});

			if (this.selectedTabIndex != null) {
				this.tabView.selectedIndex = this.selectedTabIndex;
			}
		}
	}

	public setupProductEntitySelectorTableColumns() {
		let deleteButton = new EntitySelectorTableColumn();
		deleteButton.columnHeader = "";
		deleteButton.columnProperty = "delete";
		deleteButton.columnWidth = "60px";
		deleteButton.type = EntitySelectorTableColumnType.asyncButton;
		deleteButton.typeOptions = {
			icon: "fa fa-trash",
			click: (productForm: UntypedFormGroup, index: number) => {
				return this.removeProduct(index);
			},
			materialType: "mat-mini-fab",
			disabled: () => {
				return this.orderForm.disabled;
			},
		};
		this.selectorTableColumns.push(deleteButton);

		let partNumberColumn = new EntitySelectorTableColumn();
		partNumberColumn.columnHeader = "Part Number";
		partNumberColumn.inputLabel = "Part Number";
		partNumberColumn.columnProperty = "part_number";
		partNumberColumn.type = EntitySelectorTableColumnType.text;
		partNumberColumn.errors = [
			{
				message: "Part number is required!",
				name: Validators.required.name,
			},
			{
				message: "Duplicate part number identified.",
				name: "duplicatePartNumber",
			},
		];

		this.selectorTableColumns.push(partNumberColumn);

		let quantityColumn = new EntitySelectorTableColumn();
		quantityColumn.columnHeader = "Quantity";
		quantityColumn.columnWidth = "50px";
		quantityColumn.inputLabel = "Quantity";
		quantityColumn.columnProperty = "quantity";
		quantityColumn.type = EntitySelectorTableColumnType.number;
		quantityColumn.errors = [
			{
				message: "Quantity is required!",
				name: Validators.required.name,
			},
			{
				message: "Quantity must be greater than or equal to 0",
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(quantityColumn);

		let descriptionColumn = new EntitySelectorTableColumn();
		descriptionColumn.columnHeader = "Description";
		descriptionColumn.inputLabel = "Description";
		descriptionColumn.columnProperty = "description";
		descriptionColumn.type = EntitySelectorTableColumnType.text;
		descriptionColumn.errors = [
			{
				message: "Description is required!",
				name: Validators.required.name,
			},
		];

		this.selectorTableColumns.push(descriptionColumn);

		let costColumn = new EntitySelectorTableColumn();
		costColumn.columnHeader = "Cost";
		costColumn.inputLabel = "Cost";
		costColumn.columnWidth = "75px";
		costColumn.columnProperty = "cost";
		costColumn.type = EntitySelectorTableColumnType.number;
		costColumn.errors = [
			{
				message: "Cost is required!",
				name: Validators.required.name,
			},
			{
				message: "Cost must be greater than or equal to 0",
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(costColumn);

		if (this.userService.isAdmin() || this.userService.isManager()) {
			let upchargeButton = new EntitySelectorTableColumn();
			upchargeButton.columnHeader = "Upcharge";
			upchargeButton.columnProperty = "upcharge";
			upchargeButton.type = EntitySelectorTableColumnType.custom;
			upchargeButton.typeOptions = {
				componentType: ProductUpchargeToggle,
			};
			this.selectorTableColumns.push(upchargeButton);
		}

		this.tableDataSource.filterPredicate = (productForm: UntypedFormGroup, filterText: string) => {
			let product: Product = productForm.value || {};

			let name = product.part_number + " " + product.description;
			if (name == null || name == undefined) name = "";

			name = name.toLowerCase().trim();

			return name.includes(filterText.trim());
		};
	}

	public unsubscribeToFormChanges() {
		if (this.repairOrderChangedSubscription) {
			this.repairOrderChangedSubscription.unsubscribe();
			this.repairOrderChangedSubscription = null;
		}
	}

	public ngOnDestroy() {
		this.unsubscribeToFormChanges();
	}

	public initializeOrderForm() {
		//      Set the repair order processing step
		if (!this.order.id && !this.order.repairOrderProcessingStepId) {
			this.order.repairOrderProcessingStepId = RepairOrderProcessingStep.New;
		} else if (this.order.id && this.order.repairOrderProcessingStepId != RepairOrderProcessingStep.Closed && this.order.repairOrderProcessingStepId != RepairOrderProcessingStep.Complete) {
			this.order.repairOrderProcessingStepId = RepairOrderProcessingStep.NeedsBilled;
		}

		if (this.order.repairOrderProcessingStepId == null) {
			this.order.repairOrderProcessingStepId == RepairOrderProcessingStep.New;
		}

		this.isBilled = this.order.isBilled || false;
		this.isClosed = this.order.close_date != null;
		this.isComplete = this.order.complete_date != null;

		let vehicle: any = this.order.vehicle || {};

		//		Be sure to set the repair orders user id on a new order
		if (!this.order.id) {
			this.order.user.id = this.userService.user.id;
		}

		//      Initialize the repair order form object
		this.orderForm = this.formBuilder.group({
			id: new UntypedFormControl(this.order.id),
			user_id: new UntypedFormControl(this.order.user.id, [Validators.required]),
			user: this.formBuilder.group(this.order.user),
			lineItems: new UntypedFormArray(
				this.order.lineItems
					? this.order.lineItems.map((item) =>
						this.formBuilder.group({
							id: new UntypedFormControl(item.id),
							complaint: new UntypedFormControl(item.complaint, [Validators.required]),
							cause: new UntypedFormControl(item.cause, [Validators.required]),
							correction: new UntypedFormControl(item.correction, [Validators.required]),
							hours: new UntypedFormControl(item.hours, Validators.required),
							rate: new UntypedFormControl(item.rate >= 0 ? item.rate : this.getLineItemLaborRate() || 0.0),
							products: new UntypedFormArray(
								item.products
									? item.products.map((product) =>
										this.formBuilder.group({
											id: new UntypedFormControl(product.id),
											description: new UntypedFormControl(product.description),
											part_number: new UntypedFormControl(product.part_number),
											cost: new UntypedFormControl(product.cost || 0),
											quantity: new UntypedFormControl(product.quantity),
										})
									)
									: []
							),
						})
					)
					: []
			),
			total_cost: new UntypedFormControl(this.order.total_cost),
			customer_id: new UntypedFormControl(this.order.customer.id, Validators.required),
			customer: this.formBuilder.group(this.order.customer),
			purchaseordernumber: new UntypedFormControl(this.order.purchaseordernumber),
			vehicle: this.formBuilder.group({
				id: new UntypedFormControl(vehicle.id),
				vin: new UntypedFormControl(vehicle.vin, Validators.required),
				unit_number: new UntypedFormControl(vehicle.unit_number, Validators.required),
				milage: new UntypedFormControl(vehicle.milage, Validators.required),
				license_number: new UntypedFormControl(vehicle.license_number, Validators.required),
				equipment_type: new UntypedFormControl(vehicle.equipment_type, Validators.required)
			}),
			repairOrderProcessingStepId: new UntypedFormControl(this.userService.isAdmin() ? this.order.repairOrderProcessingStepId : RepairOrderProcessingStep.NeedsBilled),
			isBilled: new UntypedFormControl(this.order.isBilled),
			isClosed: new UntypedFormControl(this.order.close_date ? true : false),
			isComplete: new UntypedFormControl(this.order.complete_date ? true : false),
		});

		if (this.order.close_date) {
			this.orderForm.addControl("close_date", new UntypedFormControl(new Date(this.order.close_date)));
		}

		if (this.orderForm.value.customer_id) {
			this.customerSelectedHandler({ option: { value: this.orderForm.value.customer_id } } as any);
		}

		if (this.selectedLineItemIndex == -1) {
			//      Automatically select the first line item in the lsit
			let lineItems = (this.orderForm.get("lineItems") as UntypedFormArray).controls;

			if (lineItems && lineItems.length > 0) {
				this.selectedLineItem = lineItems[0] as UntypedFormGroup;
				this.selectedLineItemIndex = 0;
			} else {
				this.selectedLineItem = null;
				this.selectedLineItemIndex = -1;
			}
		}

		if (this.selectedLineItem) {
			this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
		}

		this.unsubscribeToFormChanges();
		this.repairOrderChangedSubscription = this.orderForm.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(
			(response) => {
				if (!this.orderForm.pristine && this.orderForm.dirty) {
					this.storage.add(repairOrderStorageKey, this.orderForm.value.id, this.orderForm.value);
				}
			},
			(error) => {
				console.error(error);
			}
		);

		if (this.storage.has(repairOrderStorageKey, this.order.id)) {
			this.orderForm.markAsDirty();
			this.orderForm.markAsTouched();
		}

		if (this.userService.user.id != this.orderForm.get("user_id").value && !this.userService.isAdmin() && this.orderForm.get("close_date").value) {
			this.orderForm.disable();
		}

		this.initialized.emit();
	}

	public getLineItemLaborRate(): number {
		let rate = this.orderForm.value.customer ? this.orderForm.value.customer.laborRate || 0.00 : 0.00;
		//		First see if customer and vehicle type are selected
		if (this.orderForm.value.customer && this.orderForm.value.vehicle && this.orderForm.value.vehicle.equipment_type) {
			//		Get the customer labor rate for the selected vehicle type
			let customerRate = this.orderForm.value.customer.labor_rates ? this.orderForm.value.customer.labor_rates.find(laborRate => laborRate.equipment_type.toLowerCase().trim() == this.orderForm.value.vehicle.equipment_type.toLowerCase().trim()) : null;

			if (customerRate != null && customerRate != undefined) {
				rate = customerRate.rate;
			}
		}

		return rate;
	}

	public vehicleTypeChanged(event: MatSelectChange) {
		if (this.orderForm.value.vehicle) {
			let lineItems: Array<LineItem> = this.orderForm.value.lineItems;

			if (lineItems && lineItems.length) {
				lineItems.map((lineItem) => {
					if (lineItem.rate <= 0 || lineItem.rate == null || lineItem.rate == undefined) {
						lineItem.rate = this.getLineItemLaborRate();
					}
				});

				this.orderForm.get("lineItems").patchValue(lineItems);
			}
		}
	}

	public vehicleSelectedHandler = (event: MatAutocompleteSelectedEvent) => {
		if (!event || !event.option) return;

		let vehicleId = event.option.value;

		this.api
			.getAllVehicles()
			.toPromise()
			.then((vehicles) => {
				let vehicle = vehicles.find((vehicle) => vehicle.id == vehicleId);

				if (vehicle) {
					let idControl = this.orderForm.get("vehicle.id");
					if (idControl) idControl.patchValue(vehicle.id);

					/*
				Client requested that his workers need to fill this in every time.

				let milageControl = this.orderForm.get('vehicle.milage');
				if(milageControl) milageControl.patchValue(vehicle.milage);
				*/

					let unitNumberControl = this.orderForm.get("vehicle.unit_number");
					if (unitNumberControl) unitNumberControl.patchValue(vehicle.unit_number);

					let vinControl = this.orderForm.get("vehicle.vin");
					if (vinControl) vinControl.patchValue(vehicle.vin);

					let licenseControl = this.orderForm.get("vehicle.license_number");
					if (licenseControl) licenseControl.patchValue(vehicle.license_number);

					let equipmentControl = this.orderForm.get('vehicle.equipment_type');
					if (equipmentControl) equipmentControl.patchValue(vehicle.equipment_type);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	public customerSelectedHandler = (event: MatAutocompleteSelectedEvent) => {
		if (this.orderForm && event.option.value) {
			this.api
				.getAllCustomers()
				.toPromise()
				.then((customers) => {
					let theCustomer = customers.find((customer) => customer.id == event.option.value);

					if (theCustomer) {
						this.taxExempt = theCustomer.taxExempt || false;
						this.taxRate = theCustomer.taxRate || 0.0;
						this.orderForm.get("customer").patchValue(theCustomer);

						let lineItems: Array<LineItem> = this.orderForm.value.lineItems;

						if (lineItems && lineItems.length) {
							lineItems.map((lineItem) => {
								if (lineItem.rate <= 0 || lineItem.rate == null || lineItem.rate == undefined) {
									lineItem.rate = this.getLineItemLaborRate();
								}
							});

							this.orderForm.get("lineItems").patchValue(lineItems);
						}

						if (this.vehiclePicker) {
							this.vehiclePicker.refreshVehiclesList(theCustomer.id);
						}
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	public isBilledChanged(event: MatSlideToggleChange) {
		if (this.orderForm) {
			this.orderForm.get("isBilled").patchValue(event.checked);
		}
	}

	public statusChangedClose(event: MatSlideToggleChange) {
		if (this.orderForm) {
			if (event.checked) {
				this.orderForm.get("repairOrderProcessingStepId").patchValue(RepairOrderProcessingStep.Closed);
			} else {
				this.orderForm.get("repairOrderProcessingStepId").patchValue(RepairOrderProcessingStep.NeedsBilled);
			}

			this.orderForm.get("isClosed").patchValue(event.checked);
		}
	}

	public statusChangedComplete(event: MatSlideToggleChange) {
		if (this.orderForm) {
			if (event.checked) {
				this.orderForm.get("repairOrderProcessingStepId").patchValue(RepairOrderProcessingStep.Complete);
			} else {
				this.orderForm.get("repairOrderProcessingStepId").patchValue(RepairOrderProcessingStep.NeedsBilled);
			}

			this.orderForm.get("isComplete").patchValue(event.checked);
		}
	}

	public openVehicleProductsSelector(): void {
		let modalref = this.dialog.open(GetVehicleProductsModal);
		modalref.componentInstance.vehicleId = this.userService.user.vehicleId;

		modalref
			.afterClosed()
			.toPromise()
			.then((results) => {
				if (results && results.length) {
					results.map((result: Product) => {
						result.quantity = 0;
						this.addProduct(result);
					});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public downloadInvoiceClicked = () => {
		let order: RepairOrder = new RepairOrder(JSON.parse(JSON.stringify(this.orderForm.value)));
		order.close_date = this.order.close_date ? new Date(this.order.close_date) : null;
		order.complete_date = this.order.complete_date ? new Date(this.order.complete_date) : null;

		let definition = this.invoiceService.createCustomerDocument(order, this.taxExempt, this.taxRate, this.disposalFees, this.otherFees);
		this.invoiceService.downloadDocument(definition);

		return Promise.resolve();
	};

	public selectLineItem(index) {
		let lineItems = this.orderForm.get("lineItems") as UntypedFormArray;

		if (lineItems && lineItems.length) {
			if (this.selectedLineItemIndex < 0) {
				this.selectedLineItemIndex = -1;
			}

			if (index < lineItems.length) {
				this.selectedLineItemIndex = index;
				this.selectedLineItem = lineItems.controls[this.selectedLineItemIndex] as UntypedFormGroup;

				if (this.selectedLineItem && this.selectedLineItem.get("products")) {
					this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
				}
			}
		}
	}

	public nextLineItem() {
		let lineItems = this.orderForm.get("lineItems") as UntypedFormArray;

		if (lineItems && lineItems.length) {
			if (this.selectedLineItemIndex < 0) {
				this.selectedLineItemIndex = -1;
			}

			if (this.selectedLineItemIndex + 1 < lineItems.length) {
				this.selectedLineItemIndex++;
				this.selectedLineItem = lineItems.controls[this.selectedLineItemIndex] as UntypedFormGroup;

				if (this.selectedLineItem && this.selectedLineItem.get("products")) {
					this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
				}
			}
		}
	}

	public previousLineItem() {
		let lineItems = this.orderForm.get("lineItems") as UntypedFormArray;

		if (lineItems && lineItems.length) {
			if (this.selectedLineItemIndex - 1 >= 0) {
				this.selectedLineItemIndex--;
				this.selectedLineItem = lineItems.controls[this.selectedLineItemIndex] as UntypedFormGroup;

				if (this.selectedLineItem && this.selectedLineItem.get("products")) {
					this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
				}
			}
		}
	}

	public addProduct(product: Product): void {
		if (!product) product = {} as any;

		if (this.selectedLineItem) {
			let productForm = this.formBuilder.group({
				id: new UntypedFormControl(product.id),
				cost: new UntypedFormControl(product.cost || 0.0, [Validators.required, Validators.min(0)]),
				quantity: new UntypedFormControl(product.quantity, [Validators.required, Validators.min(0)]),
				description: new UntypedFormControl(product.description, Validators.required),
				part_number: new UntypedFormControl(product.part_number, Validators.required),
			});

			let products = this.selectedLineItem.get("products") as UntypedFormArray;
			if (products) {
				this.orderForm.markAsDirty();
				products.insert(0, productForm);
			}

			this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
			this.tableDataSource.paginator.firstPage();
		}
	}

	public removeProduct(itemIndex: number): Promise<any> {
		if (itemIndex && itemIndex <= 0) return Promise.resolve();

		if (this.orderForm && this.selectedLineItem.value) {
			let products = this.selectedLineItem.get("products") as UntypedFormArray;

			if (products && products.value && itemIndex < products.value.length) {
				let product = products.value[itemIndex];
				let productRemovedFromTable = Promise.resolve();

				if (product && product.id && this.selectedLineItem.value.id && !product.new) {
					this.loading = true;
					productRemovedFromTable = this.api
						.removeLineItemProduct(this.selectedLineItem.value.id, product.id)
						.toPromise()
						.then((response) => {
							products.removeAt(itemIndex);
						})
						.catch((error) => {
							SnackBarService.openSnackBarAlert(error.error.message, "red");
							console.error(error);
						});
				} else {
					products.removeAt(itemIndex);
				}

				productRemovedFromTable
					.then(() => {
						if (this.order.id) {
							this.refreshRepairOrders.emit({ id: this.order.id, callback: null });
						}

						SnackBarService.openSnackBarAlert("Product removed.");
					})
					.finally(() => {
						this.tableDataSource.data = products.controls;
						this.loading = false;
					});
			}
		}
	}

	public addLineItem(): void {
		//      First get the line items form array
		let lineItems = this.orderForm.get("lineItems") as UntypedFormArray;

		if (lineItems) {
			//      Add a new form group to the array
			let newItem = this.formBuilder.group({
				id: new UntypedFormControl(null),
				complaint: new UntypedFormControl(null, Validators.required),
				cause: new UntypedFormControl(null, Validators.required),
				correction: new UntypedFormControl(null, Validators.required),
				hours: new UntypedFormControl(null, Validators.required),
				rate: new UntypedFormControl(this.getLineItemLaborRate() || 0.0),
				products: new UntypedFormArray([]),
			});
			this.orderForm.markAsDirty();
			lineItems.push(newItem);

			//      Set this as the new selected line item
			this.selectedLineItem = newItem;
			this.selectedLineItemIndex = lineItems.length - 1;
			this.tableDataSource.data = (newItem.get("products") as UntypedFormArray).controls;
		} else throw new Error("No line items array found!");
	}

	public removeLineItem(): void {
		if (!this.selectedLineItem) return;

		if (this.orderForm) {
			let items = this.orderForm.get("lineItems") as UntypedFormArray;

			if (items) {
				let index = items.controls.findIndex((item) => item === this.selectedLineItem);
				this.loading = true;

				let lineItemRemoved = Promise.resolve();

				//      Only submit the api request if the line item has an id
				if (this.selectedLineItem.value.id && this.order.id) {
					lineItemRemoved = this.api
						.removeRepairOrderLineItem(this.order.id, this.selectedLineItem.value.id)
						.toPromise()
						.then((response) => {
							items.removeAt(index);

							if (items.length > 0) {
								this.selectedLineItem = items.controls[0] as UntypedFormGroup;
								this.selectedLineItemIndex = this.selectedLineItemIndex - 1;
								this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
							} else {
								this.selectedLineItem = null;
								this.selectedLineItemIndex = -1;
								this.tableDataSource.data = [];
							}
						})
						.catch((error) => {
							SnackBarService.openSnackBarAlert(error.error.message, "red");
						});
				} else {

					items.removeAt(index);
					if (items.length > 0) {
						this.selectedLineItem = items.controls[0] as UntypedFormGroup;
						this.selectedLineItemIndex = this.selectedLineItemIndex - 1;
					} else {
						this.selectedLineItem = null;
						this.selectedLineItemIndex = -1;
					}
				}

				lineItemRemoved.then(() => {
					if (this.order.id) {
						this.refreshRepairOrders.emit({ id: this.order.id, callback: null });
					}

					SnackBarService.openSnackBarAlert("Line item removed.");
				}).finally(() => {
					this.loading = false;
				});
			}
		}

	}

	public getOrderProducts(): any {
		let products = [];

		if (this.selectedLineItem) {
			products = (this.selectedLineItem.get("products") as UntypedFormArray).controls || [];
		}

		return products;
	}

	public deleteRepairOrderClicked = () => {
		if (this.userCantEdit()) {
			return Promise.resolve();;
		}

		this.loading = true;

		let componentRef = this.dialog.open(ConfirmModal, {
			data: {
				dialogue: "This action cannot be undone. Are you sure you would like to delete this repair order?",
			},
			disableClose: true
		});

		componentRef
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result && this.order.id) {
					return this.api
						.deactivateRepairOrder(this.order.id)
						.toPromise()
						.then((response) => {
							SnackBarService.openSnackBarAlert("Repair order deleted.");
							this.order = null;
							this.refreshRepairOrders.emit({ id: null, callback: null });
						})
						.catch((error) => {
							SnackBarService.openSnackBarAlert(error.error.message, "red");
						});
				}
			}).catch(error => { }).finally(() => {
				this.loading = false;
			});
	};

	private saveRepairOrderInformation(): Promise<void> {
		return new Promise((resolve, reject) => {
			let repairOrder: RepairOrder = this.orderForm.value;

			if (typeof repairOrder.vehicle.id != "number") {
				repairOrder.vehicle.id = null;
			}

			this.api.upsertRepairOrder(this.orderForm.value).subscribe(
				(result) => {
					if (!this.order.id) {
						this.order.id = result;
						this.orderForm.get('id').patchValue(this.order.id, { emitEvent: false });
					}

					resolve();
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	private saveCustomerInformation(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!this.order.id || !this.orderForm.value.customer.id) resolve();

			this.api.updateRepairOrderCustomer(this.order.id, this.orderForm.value.customer_id).subscribe(
				(result) => {
					resolve(result);
				},
				(error) => reject(error)
			);
		});
	}

	private saveVehicleInformation(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!this.order.id || !this.orderForm.value.vehicle) resolve();

			this.api.upsertRepairOrderVehicle(this.order.id, this.orderForm.value.vehicle).subscribe(
				(result) => {
					if (this.vehiclePicker) {
						this.vehiclePicker.refreshVehiclesList().then(() => {
							this.orderForm.get("vehicle.id").patchValue(result);
						});
					}

					resolve(result);
				},
				(error) => reject(error)
			);
		});
	}

	private saveLineItemInformation(): Promise<void> {
		return new Promise((resolve, reject) => {
			let lineItems = this.orderForm.value.lineItems;

			if (!lineItems || !lineItems.length) return resolve();

			Promise.all(
				lineItems.map((item, lineItemIndex) => {
					return new Promise((resolve, reject) => {
						this.api.upsertRepairOrderLineItem(this.order.id, item).subscribe(
							(response) => {
								(this.orderForm.get('lineItems') as UntypedFormArray).at(lineItemIndex).get('id').patchValue(response, { emitEvent: false });
								if (this.order.lineItems && this.order.lineItems.length - 1 >= lineItemIndex) {
									this.order.lineItems[lineItemIndex].id = response;
								}

								resolve(response);
							},
							(error) => reject(error)
						);
					});
				})
			).then((lineItemIds: Array<any>) => {
				return Promise.all(
					lineItems
						.map((lineItem, lineItemIndex) => {
							if (!lineItem.products) {
								return Promise.resolve();
							}

							let lineItemId = lineItemIds[lineItemIndex] && lineItemIds[lineItemIndex] ? lineItemIds[lineItemIndex] : lineItems[lineItemIndex].id;

							lineItem.products.map((product) => {
								if (product.hasOwnProperty("new")) {
									delete product.new;
								}

								if (!product.id) {
									let existingProduct = (this.api.cache.products || []).find((cachedProduct) => cachedProduct.part_number.toLowerCase().trim() == product.part_number.toLowerCase().trim());
									if (existingProduct) product.id = existingProduct.id;
								}
							});

							return Promise.all(lineItem.products.map((product, productIndex) => {
								return new Promise((resolve, reject) => {
									if (((this.orderForm.get('lineItems') as FormArray).at(lineItemIndex).get('products') as FormArray).at(productIndex).get('part_number').dirty) {
										this.api.removeLineItemProduct(lineItemId, product.id).subscribe(response => { }, error => { });
									}

									this.api.upsertLineItemProduct(lineItemId, product).subscribe(response => {
										if (
											this.order.lineItems && this.order.lineItems.length - 1 >= lineItemIndex &&
											this.order.lineItems[lineItemIndex].products && this.order.lineItems[lineItemIndex].products.length - 1 >= productIndex
										) {
											this.order.lineItems[lineItemIndex].products[productIndex].id = response;
										}

										((this.orderForm.get('lineItems') as UntypedFormArray).at(lineItemIndex).get('products') as UntypedFormArray)

										resolve(response);
									}, error => reject(error));
								});
							})).catch((error) => reject(error));
						})
				)
					.then(() => {
						resolve();
					})
					.catch((error) => reject(error));
			})
				.catch((error) => reject(error));
		});
	}

	public getFormErrorMessage() {
		let message = 'Looks like your repair order is missing some required information some where. Please look it over and try again';

		if (this.orderForm) {
			let erroredControls: { control: FormControl, key: string }[] = [];

			let iterateFormAndGatherErroredControls = (abstractControl: AbstractControl, key: string) => {
				if (abstractControl instanceof FormGroup) {
					for (let key in abstractControl.controls) {
						iterateFormAndGatherErroredControls(abstractControl.controls[key], key);
					}
				} else if (abstractControl instanceof FormControl && (abstractControl as FormControl).invalid) {
					erroredControls.push({ control: abstractControl, key: key });
				} else if (abstractControl instanceof FormArray) {
					(abstractControl as FormArray).controls.map(item => {
						iterateFormAndGatherErroredControls(item, '');
					});
				}
			};

			iterateFormAndGatherErroredControls(this.orderForm, '');

			if (erroredControls.length > 0) {
				message = 'Looks like your repair order is missing some required information.\n\nHint:\n';

				erroredControls.map((erroredControl) => {
					message += `${erroredControl.key} is ${Object.keys(erroredControl.control.errors).join(', ')}.\n`;
				});
			}

		}

		return message;
	}

	public onRepairOrderSubmit = (doRefresh: boolean = true) => {
		let repairOrderSubmitted = Promise.resolve();

		if (!this.orderForm || this.orderForm.invalid || this.userCantEdit()) {
			if (this.userCantEdit()) {
				SnackBarService.openSnackBarAlert("Sorry, you do not have the appropriate permissions to complete this action.", "red");
			}

			if (this.orderForm.invalid) {
				SnackBarService.openSnackBarAlert(this.getFormErrorMessage(), "red");

				let iterateControls = (group: UntypedFormGroup | UntypedFormArray) => {
					Object.keys(group.controls).forEach((key: string) => {
						const abstractControl: AbstractControl = group.controls[key];

						if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
							iterateControls(abstractControl);
						} else {
							abstractControl.markAsTouched();
						}
					});
				};

				iterateControls(this.orderForm);
			}

			return Promise.reject();
		}

		if (this.loading) {
			return Promise.resolve();
		}

		this.loading = true;

		//		First create / update the repair order
		let repairOrderId = this.order.id;
		repairOrderSubmitted = this.saveRepairOrderInformation()
			.then(() => {
				//		Save all information related to the repair order that was either created or updated
				return Promise.all([this.saveCustomerInformation(), this.saveVehicleInformation(), this.saveLineItemInformation()])
					.then(() => {
						if (this.storage.has(repairOrderStorageKey, this.order.id)) {
							this.storage.remove(repairOrderStorageKey, this.order.id);
						}

						this.orderForm.markAsPristine();
						this.orderForm.markAsUntouched();

						SnackBarService.openSnackBarAlert("Repair order saved.");
					})
					.catch((error) => {
						SnackBarService.openSnackBarAlert(error.error.message, "red");
						console.error(error);
					})
					.finally(() => {
						//		Call for a refresh of this data
						if (doRefresh) {

							this.refreshRepairOrders.emit({
								id: repairOrderId, callback: () => {
									this.loading = false;
								}
							});
						} else {
							this.loading = false;
						}
					});
			})
			.catch((error) => {
				console.error(error);
				this.loading = false;
			});

		return repairOrderSubmitted;
	};

	public userCantEdit(): boolean {
		//	Block edits if the user is not an admin and its closed.
		//	Or we are loading
		return this.loading || (!this.userService.isAdmin() && this.order.repairOrderProcessingStepId == RepairOrderProcessingStep.Closed);
	}
}

@Component({
	selector: "product-upcharge-toggle",
	template: `
		<mat-button-toggle-group multiple (change)="selectionChanged($event)">
			<mat-button-toggle value=".1">10%</mat-button-toggle>
			<mat-button-toggle value=".2">20%</mat-button-toggle>
			<mat-button-toggle value=".3">30%</mat-button-toggle>
		</mat-button-toggle-group>
	`,
})
export class ProductUpchargeToggle {
	public data: UntypedFormGroup;
	public column: EntitySelectorTableColumn;
	public originalCost: number;

	constructor() { }

	public selectionChanged(event: MatButtonToggleChange) {
		if (event && this.data) {
			let toggle = event.source;
			if (toggle) {
				let group = toggle.buttonToggleGroup;
				if (event.value.some((item) => item == toggle.value)) {
					group.value = [toggle.value];
				}
			}

			this.data.value.upcharge = event.value && event.value.length ? Number(event.value[event.value.length - 1]) : null;

			if (!this.originalCost) {
				this.originalCost = this.data.value.cost;
			}

			this.data.value.cost = this.originalCost;

			if (this.data.value.upcharge) {
				this.data.value.cost = trimToNumber(this.data.value.cost + this.data.value.cost * this.data.value.upcharge, 2);
			}

			this.data.get("cost").patchValue(this.data.value.cost);
		}
	}
}
