import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { SnackBarService } from 'src/app/components/snack_bar_alert/snackBarAlert';

declare let gapi: any;

@Component({
	selector: 'google-login-button',
	template: `
		<div #googleLoginButton id="googleLoginButton" (click)="signIn()">
		</div>
	`,
	styleUrls: ['./loginButton.scss']
})
export class GoogleLoginButton implements AfterViewInit {
	@ViewChild('googleLoginButton') googleButton;

	@Input() width = 200;
	@Input() height = 50;

	constructor(private authorization: AuthorizationService) { }

	public ngAfterViewInit() {
		this.authorization.isInitialized.then(() => {
			if (gapi && this.googleButton) {
				gapi.signin2.render('googleLoginButton', {
					width: this.width,
					height: this.height,
					theme: 'dark',
					onsuccess: () => {
					},
					onfailure: () => {
						console.error('Google button failed to render');
					}
				});
			}
		});
	}

	public signIn() {
		SnackBarService.openSnackBarAlert('Sorry, this feature is not yet enabled.', 'red');
		/*
		this.authorization.login().catch(error => {
			console.error(error);
		});
		*/
	}
}