import { Injectable } from "@angular/core";
import { User, UserType } from "../classes/user";

//      Observables libarary to stream line subscribing
import { Subject } from "rxjs";

@Injectable()
export class UserService {
	public user: User = new User();
	public userSubject: Subject<User> = new Subject<User>();

	constructor() {}

	public setUser(user: User) {
		if (user != null && user != undefined) {
			this.user = user;

			//      Give the user to any listeners watching the subject
			this.userSubject.next(this.user);
		}
	}

	public getUser() {
		return this.user;
	}

	public createUserFromApiResponse(user) {
		if (user) {
			this.user.id = user.id || null;
			this.user.email = user.email || "";
			this.user.firstname = user.firstname || "";
			this.user.lastname = user.lastname || "";
			this.user.username = user.user || "";
			this.user.user_type_id = user.user_type_id || "1";
			this.user.id = user.id || null;
			this.user.employee_number = user.employee_number || "";
			this.user.mobile_phone = user.mobile_phone || "";
			this.user.vehicleId = user.vehicleId || null;
			this.user.isClockedIn = user.isClockedId || false;
			this.user.user_type = user.user_type || "user";

			this.userSubject.next(this.user);
		}
	}

	public isAdmin() {
		if (this.user.user_type == UserType.Admin) {
			return true;
		}

		return false;
	}

	public isManager() {
		if (this.user.user_type == UserType.Manager) {
			return true;
		}

		return false;
	}
}
