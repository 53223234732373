<mat-form-field class="w-100">
	<mat-label>Customer</mat-label>
	<input [(ngModel)]="customerId" matInput placeholder="Customer" (change)="onChange($event)" [formControl]="control" [matAutocomplete]="auto" />
	<mat-autocomplete (optionSelected)="customerSelectedHandler($event)" #auto="matAutocomplete" [displayWith]="displayFn">
		<mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.id"> {{ customer.name }} </mat-option>
	</mat-autocomplete>
	<mat-error *ngFor="let validator of validators">
		<span *ngIf="formControlHasError(control, validator.key)" [innerHTML]="validator.message"></span>
	</mat-error>
</mat-form-field>
