<div class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start"
	[hidden]="selectedStore || loading">
	<div fxLayout="column" fxLayoutGap="15px">
		<button (click)="newStoreClicked()" mat-raised-button color="primary">New Store</button>
		<div style="max-width: calc(100vw - 100px); overflow: auto;">
			<entity-selector-table [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns">
			</entity-selector-table>
		</div>
	</div>
</div>

<div *ngIf="loading" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center center">
	<mat-spinner></mat-spinner>
</div>

<div *ngIf="selectedStore && !loading" fxLayout="column">
	<button mat-icon-button (click)="backClicked()">
		<mat-icon>arrow_back</mat-icon> Back
	</button>
	<store (onRefresh)="loadStores($event)" [store]="selectedStore">
	</store>
</div>