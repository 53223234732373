export class Store {
	public id:number = null;
	public name:string = null;

	constructor(options?) {
		if(options) {
			if(options.hasOwnProperty('id')) {
				this.id = options.id;
			}
	
			if(options.hasOwnProperty('name')) {
				this.name = options.name;
			}
		}
	}
}