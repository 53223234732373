<div *ngIf="userForm" class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<mat-card fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="50%">
		<mat-card-title>
			<h2 *ngIf="!user.id" class="Anton"><span>New Account</span></h2>
			<h2 *ngIf="user.id" class="Anton">Account</h2>
		</mat-card-title>

		<mat-card-content>
			<div>
				<form [formGroup]="userForm">
					<div>
						<mat-form-field class="w-100">
							<mat-label>Email</mat-label>
							<input formControlName="email" matInput type="email" />
							<mat-error *ngIf="formGroupHasError(userForm, 'email', 'required')"> Email is
								<strong>required</strong> </mat-error>
							<mat-error *ngIf="formGroupHasError(userForm, 'email', 'email')"> Not a valid email address
							</mat-error>
						</mat-form-field>
					</div>

					<div>
						<mat-form-field class="w-100">
							<mat-label>Username</mat-label>
							<input formControlName="username" matInput type="text" />
							<mat-error *ngIf="formGroupHasError(userForm, username, 'required')"> Username is
								<strong>required</strong> </mat-error>
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>First Name</mat-label>
							<input formControlName="firstname" matInput type="text" />
							<mat-error *ngIf="formGroupHasError(userForm, 'firstname', 'required')"> First name is
								<strong>required</strong> </mat-error>
						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>Last Name</mat-label>
							<input formControlName="lastname" matInput type="text" />
							<mat-error *ngIf="formGroupHasError(userForm, 'lastname', 'required')"> Last name is
								<strong>required</strong> </mat-error>
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>Choose a user type</mat-label>
							<mat-select formControlName="user_type_id" [disabled]="!userService.isAdmin()">
								<mat-option *ngFor="let type of userTypes" [value]="type.id">{{ type.type
									}}</mat-option>
							</mat-select>
							<mat-error *ngIf="formGroupHasError(userForm, 'user_type_id', 'required')"> User type is
								<strong>required</strong> </mat-error>
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row">
						<vehicle-picker class="w-100"
							[validators]="[{key: 'required', message: 'Vehicle is <strong>required</strong>'}]"
							[formGroupParent]="userForm" [formGroupControlName]="'vehicleId'"> </vehicle-picker>
					</div>

					<div fxLayout.xs="column" fxLayout="row">
						<mat-form-field class="w-100">
							<mat-label>Mobile Phone</mat-label>
							<input formControlName="mobile_phone" matInput type="tel" />
							<mat-error *ngIf="formGroupHasError(userForm, 'mobile_phone', 'pattern')"> Not a valid phone
								number </mat-error>
						</mat-form-field>
					</div>

					<div>
						<mat-form-field class="w-100">
							<mat-label>Employee Number</mat-label>
							<input [readonly]="!userService.isAdmin()" formControlName="employee_number" matInput
								type="text" />
							<mat-error *ngIf="formGroupHasError(userForm, 'email', 'required')"> Employee Number is
								<strong>required</strong> </mat-error>
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row">
						<mat-checkbox disabled="true" class="mr-3" formControlName="active">Active</mat-checkbox>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>New Password</mat-label>
							<input formControlName="changedPasswordControl" matInput type="password" />
						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>Confirm New Password</mat-label>
							<input formControlName="changedPasswordCopyControl" matInput type="password" />
							<mat-error *ngIf="formGroupHasError(userForm, 'changedPasswordCopyControl', 'mismatch')">
								Passwords do not <strong>match</strong> </mat-error>
						</mat-form-field>
					</div>
				</form>
			</div>
			<br />

			<mat-divider></mat-divider>

			<br />

			<div>
				<loading-button [text]="'Submit'" [action]="onUserFormSubmit"></loading-button>

				<loading-button class="ml-3" [text]="'Deactivate User'" [action]="deactivateAccount"></loading-button>

				<loading-button class="ml-3" [text]="'Quick Reset Password'"
					[action]="quickResetUserPassword"></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>