import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';

@Component({
	selector: 'settings',
	templateUrl: './settings.html',
	styleUrls: ['./settings.scss']
})
export class SettingsComponent {
	public nextInvoiceNumber: number = null;

	public constructor(public userService: UserService, private apiService: ApiService) { }

	public changeInvoiceNumberClicked = () => {
		if (!this.nextInvoiceNumber)
			return Promise.resolve();

		return this.apiService.setNextInvoiceNumber(this.nextInvoiceNumber).toPromise().then(response => {
			console.log(response);
		}).catch(error => {
			console.error(error);
		});
	}
}