import { Injectable } from "@angular/core";
import { RepairOrder } from "../classes/repairOrder";
import { Decimal } from "decimal.js";
import { CurrencyPipe } from "@angular/common";
import { trimToNumber } from "./Helper";
import { PdfMakeService } from "./pdfMake.service";
import { Quote } from "../classes/quote";
import { padToLength } from "./Helper";
import { EquipmentType } from "../classes/vehicle";

@Injectable({
	providedIn: "root",
})
export class InvoiceService {
	public constructor(
		private currencyPipe: CurrencyPipe,
		public pdfMakeService: PdfMakeService
	) { }

	public createCustomerDocument(
		data: RepairOrder | Quote,
		taxExempt: boolean,
		taxRate: number,
		disposalFee: number,
		otherFee: number
	): any {
		let document = {
			content: [
				this.getAddressInformationTable(data),
				" ",
				this.getLineItemDefinitionPart1(data),
				" ",
				this.getLineItemDefinitionPart2(data),
				" ",
				this.getDisclaimerDefinition(
					data,
					taxExempt,
					taxRate,
					disposalFee,
					otherFee
				),
				` `,
				` `,
				`X_____________________________________________________________________________________________`,
			],

			styles: {
				font10: {
					fontSize: 10,
				},
				font8: {
					fontSize: 8,
				},
			},
		};

		if (!(data instanceof RepairOrder)) {
			document.content.unshift("");
			document.content.unshift("* * * Quotes only valid for 30 days * * *");
		}

		return document;
	}

	private getLineItemDefinitionPart1(data: RepairOrder | Quote): any {
		let definition = {
			style: "font10",
			table: {
				widths: [20, 100, 145, 35, 35, 60, 60],
				body: [
					[
						{
							bold: true,
							text: `Qty`,
						},
						{
							bold: true,
							text: `Part Number`,
						},
						{
							bold: true,
							text: `Description`,
						},
						{
							bold: true,
							text: `Time`,
						},
						{
							bold: true,
							text: `Labor`,
						},
						{
							bold: true,
							text: `Parts Price`,
						},
						{
							bold: true,
							text: `Sub Total`,
						},
					],
				],
			},
		};

		let tableBody = definition.table.body;
		//      Iterate over each line item and then the corresponding products
		data.lineItems.map((lineItem, index) => {
			//      Handling the output of LINE X except for the first one
			if (index != 0) {
				let headerRowColumns = [];
				headerRowColumns.push(this.getText("")); // QTY column
				headerRowColumns.push(this.getText("LINE " + (index + 1))); //  Part Number column
				headerRowColumns.push(this.getText("")); // Desciption column
				headerRowColumns.push(this.getText("")); // Time column
				headerRowColumns.push(this.getText("")); // Labor column
				headerRowColumns.push(this.getText("")); // Parts Price column
				headerRowColumns.push(this.getText("")); // Subtotal column

				tableBody.push(headerRowColumns);
			}

			//      Add the labor row
			let laborRow = [];
			laborRow.push(this.getText(lineItem.hours));
			laborRow.push(this.getText("0001"));
			laborRow.push(this.getText(`${data.vehicle.equipment_type.toUpperCase()} LABOR`));
			laborRow.push(this.getText(""));
			laborRow.push(this.getText(""));
			laborRow.push(this.getText(lineItem.rate.toFixed(2)));
			laborRow.push(
				this.getText((lineItem.rate * lineItem.hours).toFixed(2))
			);

			tableBody.push(laborRow);

			//      Iterate over each product now and create a row
			lineItem.products.map((product) => {
				let productRow = [];

				productRow.push(this.getText(Math.abs(product.quantity)));
				productRow.push(this.getText(product.part_number));
				productRow.push(this.getText(product.description));
				productRow.push(this.getText(""));
				productRow.push(this.getText(""));
				productRow.push(this.getText(product.cost.toFixed(2)));
				productRow.push(
					this.getText((product.quantity * product.cost).toFixed(2))
				);

				tableBody.push(productRow);
			});
		});

		return definition;
	}

	private getLineItemDefinitionPart2(data: RepairOrder | Quote): any {
		let definition = {
			style: "font10",
			table: {
				widths: [510],
				body: [],
			},
		};

		let text = "Description\r\n";
		//      Iterate over each line item and then the corresponding products
		data.lineItems.map((lineItem, index) => {
			//      Handling the output of LINE X except for the first one
			if (index != 0) {
				text += "LINE " + (index + 1); //  Part Number column
				text += "\r\n";
			}

			text += "Complaint: " + lineItem.complaint + "\r\n";
			text += "Cause: " + lineItem.cause + "\r\n";
			text += "Correction: " + lineItem.correction + "\r\n";
		});

		definition.table.body.push([this.getText(text)]);

		return definition;
	}

	private getDisclaimerDefinition(
		data: RepairOrder | Quote,
		taxExempt: boolean,
		taxRate: number,
		disposalFee: number = 0,
		otherFee: number = 0
	): any {
		let definition = {
			style: "font8",
			table: {
				widths: [375, 125],
				body: [
					[
						{
							text: `PAYMENT TERMS: CUSTOMER ACKNOWLEDGES THAT HE AS INSPECTED THE REPAIRS TO THE VEHICLE ON THIS INVOICE AND THE REPAIRS MEET THE CUSTOMERS REQUIREMENTS AND EXPECTATIONS. CUSTOMER FURTHER AGREES TO PAY THE INVOICE IN FULL, AS SHOWN, WITHOUT DEDUCTION OR SET OFF. ANY WARRANTIES ON THE PARTS AND ACCESSORIES SOLD HEREBY ARE MADE BY THE MANUFACTURER. IMPERIAL TRUCK SERVICES, INC MAKES NO WARRANTIES OF ANY KIND, EXPRESSES OR IMPLIED, AND DISCLAIMS ALL WARRANTIES OF THE MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WITH REGARDS TO 
							THE SERVICE, PARTS, LABOR, AND/OR ACCESSORIES PURCHASED AND THAT IN NO EVENT SHALL IMPERIAL TRUCK SERVICES, INC BE LIABLE FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR COMMERCIAL LOSS ARISING OUT OF SUCH PURCHASES.`,
						},
					],
				],
			},
		};
		let text = "";

		let laborTotal = 0.0;
		let partsTotal = 0.0;

		disposalFee = trimToNumber(disposalFee, 2);
		otherFee = trimToNumber(otherFee, 2);

		data.lineItems.map((lineItem) => {
			let hours = new Decimal(trimToNumber(lineItem.hours, 2));
			let rate = new Decimal(trimToNumber(lineItem.rate, 2));

			laborTotal = hours.times(rate).add(laborTotal).toNumber();

			//      Iterate over the products to get the product total
			lineItem.products.map((product) => {
				let cost = new Decimal(trimToNumber(product.cost, 2));
				let quantity = new Decimal(trimToNumber(product.quantity, 2));

				let partTimesQuantity = cost.times(quantity);
				partsTotal = partTimesQuantity.add(partsTotal).toNumber();
			});
		});

		let subTotal = new Decimal(partsTotal).add(laborTotal).add(otherFee).add(disposalFee);
		let total = 0.0;
		let tax = null;

		if (!taxExempt && taxRate > 0) {
			tax = new Decimal(subTotal).times(taxRate).toNumber();
			total = new Decimal(subTotal).add(tax).toNumber();
			tax = this.currencyPipe.transform(trimToNumber(tax, 2));
		} else {
			total = subTotal.toNumber();
			tax = "TAX EXEMPT";
		}

		//      Create our text to put into the pdf now
		text = `Labor: ${this.currencyPipe.transform(
			trimToNumber(laborTotal, 2)
		)}
			Parts: ${this.currencyPipe.transform(trimToNumber(partsTotal, 2))}
			Other Fees: ${this.currencyPipe.transform(trimToNumber(otherFee, 2))}
			Disposal Fees: ${this.currencyPipe.transform(trimToNumber(disposalFee, 2))}
			Subtotal: ${this.currencyPipe.transform(trimToNumber(subTotal.toNumber(), 2))}
			Tax: ${tax}
			Total: ${this.currencyPipe.transform(trimToNumber(total, 2))}
		`;

		definition.table.body[0].push(this.getText(text));

		return definition;
	}

	private getText(content): any {
		return { text: content };
	}

	private getAddressInformationTable(data: RepairOrder | Quote): any {
		let date = new Date();

		let closeDate = '', completedDate = '';

		if (data instanceof RepairOrder) {
			closeDate = data.close_date
				? `\r\nSubmitted: ${data.close_date.getUTCMonth() + 1
				}-${data.close_date.getUTCDate()}-${data.close_date.getUTCFullYear()}`
				: ``;
			completedDate = data.complete_date
				? `\r\nCompleted: ${data.complete_date.getUTCMonth() + 1
				}-${data.complete_date.getUTCDate()}-${data.complete_date.getUTCFullYear()}`
				: ``;
		}

		let tableDefinition = {
			style: "font10",
			table: {
				widths: [250, 250],
				body: [
					[
						{
							border: [false, false, false, false],
							bold: true,
							text: `Imperial Truck Services, Inc.
						P.O. Box 239 
						Bath, OH 44210 
						ImperialTruckServices@gmail.com 
						(330) 573-4777
						`,
						},
						`${data instanceof RepairOrder ? `Invoice # ${data.id}` : `Quote # ${padToLength(data.id, 5)}`}
					
						Date: ${date.getMonth() + 1
						}-${date.getDate()}-${date.getFullYear()}${completedDate}${closeDate}

						${data instanceof RepairOrder ? `P.O. # ${(data as RepairOrder).purchaseordernumber || ""}` : ''}
						`,
					],
					[
						`Customer: ${data.customer.name}
						
						${data.customer.address} ${data.customer.street} ${data.customer.city
						}, ${data.customer.state} ${data.customer.zipcode
						}
						${data.customer.phone || ""}
						`,
						`Unit #: ${data.vehicle.unit_number || ""}
						Milage: ${data.vehicle.milage || ""}
						License Plate #: ${data.vehicle.license_number || ""}
						VIN: ${data.vehicle.vin || ""}
						Employee Number: ${data.user.employee_number || ""}
						`,
					],
				],
			},
		};

		return tableDefinition;
	}

	public downloadDocument(
		documentDefinition: any,
		documentName: string = null
	) {
		if (documentName == null)
			documentName = new Date().toLocaleDateString() + ".pdf";

		this.pdfMakeService.pdfMake
			.createPdf(documentDefinition)
			.download(documentName);
	}
}
