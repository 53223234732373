<div class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<mat-card fxFlex="95">
		<mat-card-title>
			<h2 class="Anton">Payroll</h2>
		</mat-card-title>
	
		<mat-card-content>
			<form [formGroup]="range">
				<mat-form-field appearance="fill">
					<mat-label>Enter a date range</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate formControlName="start" placeholder="Start date">
						<input matEndDate formControlName="end" placeholder="End date">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
			
					<mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
					<mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
				</mat-form-field>
			</form>

			<div>
				<user-picker [validators]="[{key: 'required', message: 'Store is <strong>required</strong>'}]">
				</user-picker>
			</div>

			<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
				<div>
					<strong>Total Hours:</strong> {{ totalHours | userFriendlyHours }}
				</div>

				<div>
					<strong>Total Repair Order Hours: </strong> {{ repairOrderHours | userFriendlyHours }}
				</div>
			</div>

			<div style="max-width: calc(100vw - 100px); max-height: 50vh; overflow: auto;">
				<table mat-table [dataSource]="matTableDataSource">
					<ng-container matColumnDef="saveButton">
						<div>
							<th mat-header-cell *matHeaderCellDef></th>
							<td style="padding-right: 15px;" mat-cell *matCellDef="let element">
								<loading-button
									*ngIf="userService.isAdmin()"
									[iconClass]="'fa fa-save'"
									[buttonClass]="'btn'"
									[action]="upsertUserTime"
									[actionArgs]="element"
									[disabled]="loading"
								></loading-button>
							</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="day">
						<div>
							<th mat-header-cell *matHeaderCellDef> Day </th>
							<td style="padding-right: 15px;" mat-cell *matCellDef="let element">
								{{ element.day  | date: 'shortDate' : 'GMT-400' }}
							</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="repairOrderHours">
						<div>
							<th mat-header-cell *matHeaderCellDef> Repair Order Hours </th>
							<td style="padding-right: 15px;" mat-cell *matCellDef="let element">
								{{ element.repairOrderHours }}
							</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="workedHours">
						<div>
							<th mat-header-cell *matHeaderCellDef> Worked Hours </th>
							<td style="padding-right: 15px;" mat-cell *matCellDef="let element">
								{{ element.workedHours | userFriendlyHours }}
							</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="clockIn">
						<div>
							<th mat-header-cell *matHeaderCellDef> Clock In </th>
							<td style="padding-right: 15px;" mat-cell *matCellDef="let element">
								<div *ngIf="element.clockIn" class="form-group mt-3">
									<div class="input-group">
										<input [disabled]="!userService.isAdmin()" class="form-control" placeholder="yyyy-mm-dd"
												name="dp" [(ngModel)]="element.clockIn.dateParts" ngbDatepicker #d="ngbDatepicker">
										<div class="input-group-append">
										<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
										</div>
									</div>
								</div>
								<ngb-timepicker name="clockOutTime" *ngIf="element.clockIn" [readonlyInputs]="!userService.isAdmin()" [(ngModel)]="element.clockIn.timeParts" [meridian]="true"></ngb-timepicker>
							</td>
						</div>
					</ng-container>

					<ng-container matColumnDef="clockOut">
						<div>
							<th mat-header-cell *matHeaderCellDef> Clock Out </th>
							<td style="padding-right: 15px;" mat-cell *matCellDef="let element">
								<div *ngIf="element.clockOut" class="form-group mt-3">
									<div class="input-group">
										<input [disabled]="!userService.isAdmin()" class="form-control" placeholder="yyyy-mm-dd"
												name="dp" [(ngModel)]="element.clockOut.dateParts" ngbDatepicker #d="ngbDatepicker">
										<div class="input-group-append">
										<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
										</div>
									</div>
								</div>
								<ngb-timepicker name="clockOutTime" *ngIf="element.clockOut" [readonlyInputs]="!userService.isAdmin()" [(ngModel)]="element.clockOut.timeParts" [meridian]="true"></ngb-timepicker>
							</td>
						</div>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
				</table>
			</div>
	
			<mat-paginator
				style="display: flex; justify-content: left;"
				showFirstLastButtons="true"
				[pageSizeOptions]="[5, 10, 20]"
				[pageSize]="10"
			>
			</mat-paginator>

			<br>
	
			<mat-divider></mat-divider>
	
			<br>
			
			<div fxLayout="row" fxLayout.xs="row" fxLayoutGap="15px">
				<loading-button
					[text]="'Search'"
					[action]="refreshPayrollInformationClicked"
				></loading-button>

				<loading-button
					*ngIf="userService.isAdmin()"
					[text]="'Add User Time'"
					[action]="addUserTimeClicked"
				></loading-button>

				<loading-button
					*ngIf="userService.isAdmin()"
					[text]="'Download Time Sheet'"
					[action]="downloadUserTimeSheetClicked"
				></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>