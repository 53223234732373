<div *ngIf="vehicleForm" class="mt-3 overflow maxHeight" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<mat-card fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="50%">
		<mat-card-title>
			<h2 *ngIf="!vehicle.id" class="Anton"><span>New Vehicle</span></h2>
			<h2 *ngIf="vehicle.id" class="Anton">Vehicle</h2>
		</mat-card-title>
	
		<mat-card-content>
			<form  [formGroup]="vehicleForm">
				<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
					<mat-form-field class="w-100">
						<mat-label>Unit #</mat-label>
						<input formControlName="unit_number" matInput type="text">
						<mat-error *ngIf="formGroupHasError(vehicleForm, 'unit_number', 'required')">
							Unit # is <strong>required</strong>
						</mat-error>
					</mat-form-field>
	
					<mat-form-field class="w-100">
						<mat-label>VIN</mat-label>
						<input formControlName="vin" matInput type="text">
						<mat-error *ngIf="formGroupHasError(vehicleForm, 'vin', 'required')">
							Street is <strong>required</strong>
						</mat-error>
					</mat-form-field>
				</div>

				<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
					<mat-form-field class="w-100">
						<mat-label>License Number</mat-label>
						<input formControlName="license_number" matInput type="text">
						<mat-error *ngIf="formGroupHasError(vehicleForm, 'license_number', 'required')">
							License # is <strong>required</strong>
						</mat-error>
					</mat-form-field>
	
					<mat-form-field class="w-100">
						<mat-label>Milage</mat-label>
						<input formControlName="milage" matInput type="number">
						<mat-error *ngIf="formGroupHasError(vehicleForm, 'milage', 'required')">
							Milage is <strong>required</strong>
						</mat-error>
					</mat-form-field>
				</div>
			</form>

			<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
				<mat-form-field >
					<mat-label>Current Vehicle Inventory Amount</mat-label>
					<input readonly [(ngModel)]="totalProductsWorth" matInput type="text">
				</mat-form-field>

				<loading-button
					[text]="'Take Inventory'"
					[action]="onTakeInventoryClicked"
				></loading-button>

				<loading-button
					[text]="'Show All Inventories'"
					[action]="onShowAllInventoriesClicked"
				></loading-button>

				<loading-button
					[text]="'Add Product'"
					[action]="onAddProductClicked"
				></loading-button>

				<loading-button
					[text]="showingOnlyErroredRows ? 'Show All Rows' : 'Show Only Errored Rows'"
					[action]="showOnlyErroredRows"
				></loading-button>
			</div>

			<!-- Products -->
			<div fxFlexFill fxLayoutAlign="center center" fxLayout="row">
				<div fxFlex="100">
					<div style="max-width: calc(100vw - 100px); overflow: auto;">
						<entity-selector-table
							[tableMaxHeight]="'32vh'"
							[dataSource]="tableDataSource" 
							[selectorTableColumns]="selectorTableColumns"
							[filters]="entitySelectorTableFilters"
						>
						</entity-selector-table>
					</div>
				</div>
				
				<mat-spinner *ngIf="loading" style="margin-top: 45vh;"></mat-spinner>
			</div>
			<br>
	
			<mat-divider></mat-divider>
	
			<br>
			
			<div>
				<loading-button
					[text]="'Submit'"
					[action]="onVehicleFormSubmit"
				></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>