<div class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start"
	[hidden]="selectedCustomer || loading">
	<div fxLayout="column" fxFlex.xs="95" fxFlex.sm="95" fxFlex.md="50" fxFlex="50">
		<button (click)="newCustomerClicked()" mat-raised-button color="primary">Create Customer</button>
		<div style="max-width: calc(100vw - 100px); overflow: auto;">
			<entity-selector-table [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns">
			</entity-selector-table>
		</div>
	</div>
</div>

<div *ngIf="loading" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center center">
	<mat-spinner></mat-spinner>
</div>

<div *ngIf="selectedCustomer && !loading" fxLayout="column">
	<button mat-icon-button (click)="backClicked()">
		<mat-icon>arrow_back</mat-icon> Back
	</button>
	<customer (onRefresh)="loadCustomers($event)" [customer]="selectedCustomer">
	</customer>
</div>