<div *ngIf="orderForm" class="mt-3 overflow maxHeight" fxFlexFill fxFlex="calc(100vw - 50px)"
	fxLayoutAlign="center start">
	<mat-card fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="60%">
		<mat-card-title>
			<h5 *ngIf="!order.id" class="Anton"><span>New Repair Order</span></h5>
			<h5 *ngIf="order.id" class="Anton">Repair Order - {{ order.id }}</h5>
		</mat-card-title>

		<mat-card-content>
			<form [formGroup]="orderForm">
				<mat-tab-group>
					<mat-tab label="Basic Information">
						<div class="mt-3">
							<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
								<mat-slide-toggle *ngIf="userService.isAdmin() && this.order.id"
									(change)="statusChangedClose($event)" [(ngModel)]="isClosed"
									[ngModelOptions]="{standalone: true}"> Closed </mat-slide-toggle>
								<mat-slide-toggle
									*ngIf="(this.order.id && !isClosed) || (this.order.id && userService.isAdmin())"
									(change)="statusChangedComplete($event)" [(ngModel)]="isComplete"
									[ngModelOptions]="{standalone: true}">
									Done
								</mat-slide-toggle>
								<mat-slide-toggle *ngIf="userService.isAdmin() && this.order.id"
									(change)="isBilledChanged($event)" [(ngModel)]="isBilled"
									[ngModelOptions]="{standalone: true}"> PO </mat-slide-toggle>
							</div>
						</div>

						<div fxLayout="column" fxLayoutGap="15px">
							<customer-picker [customerSelectedHandler]="customerSelectedHandler"
								[customerId]="orderForm.get('customer_id').value"
								[validators]="[{key: 'required', message: 'Customer is <strong>required</strong>'}]"
								[formGroupParent]="orderForm" [formGroupControlName]="'customer_id'">
							</customer-picker>

							<mat-form-field class="w-100">
								<mat-label>Purchase Order Number</mat-label>
								<input formControlName="purchaseordernumber" matInput type="text" />
							</mat-form-field>

							<vehicle-picker class="w-100" [formGroupParent]="orderForm.get('vehicle')"
								[label]="'Originating Vehicle'" [formGroupControlName]="'id'"
								[validators]="[{key: 'required', message: 'Vehicle is <strong>required</strong>'}]"
								[optionSelectedHandler]="vehicleSelectedHandler">
							</vehicle-picker>
						</div>

						<div fxLayout="column" [formGroup]="orderForm.get('vehicle')" fxLayoutGap="15px">
							<mat-form-field>
								<mat-label>Vehicle Type</mat-label>
								<mat-select (selectionChange)="vehicleTypeChanged($event)"
									formControlName="equipment_type">
									<mat-option></mat-option>
									<mat-option [value]="'truck'">Truck</mat-option>
									<mat-option [value]="'trailer'">Trailer</mat-option>
									<mat-option [value]="'equipment'">Equipment</mat-option>
								</mat-select>
								<mat-error
									*ngIf="formGroupHasError(orderForm.get('vehicle'), 'equipment_type', 'required')">Vehicle
									type is <strong>required</strong></mat-error>
							</mat-form-field>
						</div>

						<div class="mt-3" fxLayout.xs="column" fxLayout="row" [formGroup]="orderForm.get('vehicle')"
							fxLayoutGap="15px">
							<mat-form-field>
								<mat-label>Unit Number</mat-label>
								<input formControlName="unit_number" matInput type="text" />
								<mat-error
									*ngIf="formGroupHasError(orderForm.get('vehicle'), 'unit_number', 'required')"> Unit
									number is <strong>required</strong> </mat-error>
							</mat-form-field>

							<mat-form-field>
								<mat-label>VIN</mat-label>
								<input formControlName="vin" matInput type="text" />
								<mat-error *ngIf="formGroupHasError(orderForm.get('vehicle'), 'vin', 'required')"> VIN
									is <strong>required</strong> </mat-error>
							</mat-form-field>
						</div>

						<div [formGroup]="orderForm.get('vehicle')" fxLayout.xs="column" fxLayout="row"
							fxLayoutGap="15px">
							<mat-form-field>
								<mat-label>Milage</mat-label>
								<input formControlName="milage" matInput type="number" />
								<mat-error *ngIf="formGroupHasError(orderForm.get('vehicle'), 'milage', 'required')">
									Milage is <strong>required</strong> </mat-error>
							</mat-form-field>

							<mat-form-field>
								<mat-label>License Number</mat-label>
								<input formControlName="license_number" matInput type="text" />
								<mat-error
									*ngIf="formGroupHasError(orderForm.get('vehicle'), 'license_number', 'required')">
									License number is <strong>required</strong> </mat-error>
							</mat-form-field>
						</div>
					</mat-tab>
					<mat-tab label="Line Items">
						<div style="max-height: 65vh; overflow: auto">
							<div class="mt-3" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
								<span class="mr-3"><strong>Item: </strong>{{ selectedLineItemIndex + 1 }} of {{
									orderForm.get('lineItems').length }}</span>

								<button (click)="previousLineItem()" class="mr-3" mat-stroked-button
									color="warn">Previous Item</button>
								<button (click)="nextLineItem()" class="mr-3" mat-stroked-button color="primary">Next
									Item</button>

								<button (click)="addLineItem()" class="mr-3" mat-stroked-button color="basic">Add New
									Item</button>
								<button (click)="removeLineItem()" class="mr-3" mat-stroked-button color="basic">Delete
									Current Item</button>
							</div>

							<div *ngIf="selectedLineItem" [formGroup]="selectedLineItem">
								<div fxLayout.xs="column" fxLayout="row" class="mt-3 mb-3" fxLayoutGap="15px">
									<mat-form-field class="w-50">
										<mat-label>Complaint</mat-label>
										<textarea rows="3" formControlName="complaint" matInput type="text"></textarea>
										<mat-error *ngIf="formGroupHasError(selectedLineItem, 'complaint', 'required')">
											Customer complaint is <strong>required</strong> </mat-error>
									</mat-form-field>

									<mat-form-field class="w-50">
										<mat-label>Cause</mat-label>
										<textarea rows="3" formControlName="cause" matInput type="text"></textarea>
										<mat-error *ngIf="formGroupHasError(selectedLineItem, 'cause', 'required')">
											Cause is <strong>required</strong> </mat-error>
									</mat-form-field>

									<mat-form-field class="w-50">
										<mat-label>Correction</mat-label>
										<textarea rows="3" formControlName="correction" matInput type="text"></textarea>
										<mat-error
											*ngIf="formGroupHasError(selectedLineItem, 'correction', 'required')">
											Correction is <strong>required</strong> </mat-error>
									</mat-form-field>
								</div>

								<div fxLayout.xs="column" fxLayout="row" class="mt-1" fxLayoutGap="15px">
									<div class="mr-3"><button (click)="addProduct()" mat-stroked-button color="basic">+
											New Product</button></div>

									<div class="mr-3"><button (click)="openVehicleProductsSelector()" mat-stroked-button
											color="basic">+ Inventory Product</button></div>

									<mat-form-field class="w-25 mr-3">
										<mat-label>Hours</mat-label>
										<input formControlName="hours" matInput type="number" />
										<mat-error *ngIf="formGroupHasError(selectedLineItem, 'hours', 'required')">
											Hours are <strong>required</strong> </mat-error>
									</mat-form-field>
									<mat-form-field *ngIf="userService.isAdmin() || userService.isManager()"
										class="w-25">
										<mat-label>Rate</mat-label>
										<input formControlName="rate" matInput type="number" />
										<mat-error *ngIf="formGroupHasError(selectedLineItem, 'rate', 'required')"> Rate
											is <strong>required</strong> </mat-error>
									</mat-form-field>
								</div>

								<div style="max-width: calc(100vw - 100px); overflow: auto">
									<entity-selector-table [tableMaxHeight]="'35vh'" [dataSource]="tableDataSource"
										[selectorTableColumns]="selectorTableColumns" [pageSize]="50">
									</entity-selector-table>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab *ngIf="userService.isAdmin() || userService.isManager()" label="Invoice">
						<div fxLayout="row" fxLayout.xs="column" class="mt-3 mb-3" fxLayoutGap="15px">
							<mat-slide-toggle *ngIf="userService.isAdmin() && this.order.id"
								(change)="statusChangedClose($event)" [(ngModel)]="isClosed"
								[ngModelOptions]="{standalone: true}"> Closed </mat-slide-toggle>
							<mat-slide-toggle
								*ngIf="(this.order.id && !isClosed) || (this.order.id && userService.isAdmin())"
								(change)="statusChangedComplete($event)" [(ngModel)]="isComplete"
								[ngModelOptions]="{standalone: true}">
								Done
							</mat-slide-toggle>
							<mat-slide-toggle (change)="isBilledChanged($event)" [(ngModel)]="isBilled"
								[ngModelOptions]="{standalone: true}"> PO </mat-slide-toggle>
						</div>

						<div class="mt-3" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
							<mat-form-field>
								<mat-label>Tax Rate</mat-label>
								<input [(ngModel)]="taxRate" matInput type="number"
									[ngModelOptions]="{standalone: true}" />
							</mat-form-field>
							<div class="mb-3">
								<mat-slide-toggle [(ngModel)]="taxExempt" [ngModelOptions]="{standalone: true}">Tax
									Exempt </mat-slide-toggle>
							</div>
						</div>

						<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
							<mat-form-field>
								<mat-label>Other Fee's</mat-label>
								<input [(ngModel)]="otherFees" matInput type="number"
									[ngModelOptions]="{standalone: true}" />
							</mat-form-field>
							<mat-form-field>
								<mat-label>Disposal Fee</mat-label>
								<input [(ngModel)]="disposalFees" matInput type="number"
									[ngModelOptions]="{standalone: true}" />
							</mat-form-field>
						</div>

						<div>
							<loading-button [text]="'Download Invoice'"
								[action]="downloadInvoiceClicked"></loading-button>
						</div>
					</mat-tab>
				</mat-tab-group>
			</form>
			<br />

			<mat-divider></mat-divider>

			<br />

			<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
				<loading-button [text]="'Submit Order'" [action]="onRepairOrderSubmit"
					[disabled]="userCantEdit() || loading"></loading-button>

				<loading-button *ngIf="this.order.id" [text]="'Delete Order'" [action]="deleteRepairOrderClicked"
					[disabled]="userCantEdit() || loading"></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>