export class PreventativeMaintenanceRecord {
	public id:number = null;
	public completed:string = null;
	public fields:PreventativeMaintenanceField[] = [];
	public configuration_id:number = null;
	public vehicle_id:number = null;
	public repair_order_id:number = null;
	public user_id:number = null;
}

export class PreventativeMaintenanceConfiguration {
	public id:number = null;
	public fields:PreventativeMaintenanceField[] = [];
	public customer_id:number = null;
	public title:string = null;
}

export class PreventativeMaintenanceField {
	public title:string = null;
	public value:any = null;
	public isNewLineItem:boolean = false;
	public photoIds:number[] = [];
}