import { Component, Input, OnInit } from '@angular/core';
import Decimal from 'decimal.js';
import { Product } from 'src/app/classes/product';
import { ApiService } from 'src/app/services/api.service';
import { trimToNumber } from 'src/app/services/Helper';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

export class VehicleInventorySnapshot {
	id:number;
	createdAt:string;
	updatedAt:string;
	snapshot:Product[];
	vehicleId:number;
}

@Component({
	selector: 'vehicle-inventory',
	template: `
		<h1 mat-dialog-title>Vehicle Inventory Snapshots</h1>
		<div mat-dialog-content>

			<div *ngIf="snapshots" class="row">
				<div class="col">

					<div *ngIf="snapshots && snapshots.length" class="row">
						<div *ngIf="selectedSnapshot" class="col-1">
							<loading-button
								[iconClass]="'fa fa-trash'"
								[buttonClass]="'btn'"
								[action]="deleteSelectedSnapshot"
							></loading-button>
						</div>
						<div class="col">
							<select (change)="onSnapshotChanged($event)" [(ngModel)]="selectedSnapshot" class="form-control">
								<option *ngFor="let snapshot of snapshots" [ngValue]="snapshot">{{ snapshot.createdAt | date : 'mediumDate' }}</option>
							</select>
						</div>
					</div>

					<div *ngIf="!snapshots || !snapshots.length" class="row mt-3 ml-3">
						<h5><strong>No Snapshots to display!</strong></h5>
					</div>

					<div *ngIf="selectedSnapshot" class="row mt-3 mb-3">
						<div class="col">
							<strong>Total Cost: {{ totalCost | currency }}</strong>
						</div>
					</div>

					<div *ngIf="selectedSnapshot" class="row">
						<div class="col">
							<div style="max-height: 450px;" class="overflow-auto">
								<table class="table table-striped">
									<thead>
										<tr>
											<th scope="col">Part Number</th>
											<th scope="col">Cost</th>
											<th scope="col">Quantity</th>
											<th scope="col">Description</th>
										</tr>
									</thead>
									<tbody>
									<tr *ngFor="let product of selectedSnapshot.snapshot">
										<td>{{ product.part_number }}</td>
										<td>{{ product.cost | currency }}</td>
										<td>{{ product.quantity }}</td>
										<td>{{ product.description }}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div mat-dialog-actions>
			<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
		</div>
	`
})
export class VehicleInventory implements OnInit {
	@Input('vehicleId') vehicleId:number = null;
	public snapshots:VehicleInventorySnapshot[] = [];
	public selectedSnapshot:VehicleInventorySnapshot = null;
	public totalCost = 0;

	constructor(private api:ApiService, public dialogRef:MatDialogRef<VehicleInventory>, public dialog:MatDialog) {}

	public ngOnInit() {
		this.initialize();
	}

	public initialize() {
		if(this.vehicleId) {
			this.api.getVehicleInventorySnapshots(this.vehicleId).subscribe((snapshots:VehicleInventorySnapshot[]) => {
				this.snapshots = snapshots;

				this.selectedSnapshot = this.snapshots[0];
				this.calculateTotalCost();
			}, error => {
				console.error(error);
			})
		}
	}

	public calculateTotalCost() {
		this.totalCost = 0;

		//		Calculate the amount of money on the vehicle
		if(this.selectedSnapshot) {
			this.selectedSnapshot.snapshot.map(product => {
				let cost = new Decimal(trimToNumber(product.cost || 0, 2));
				let quantity = new Decimal(trimToNumber(product.quantity || 0, 2));
	
				this.totalCost += (cost.times(quantity)).toNumber();
			});
		}
	
		this.totalCost = trimToNumber(this.totalCost, 2);
	}

	public onSnapshotChanged(event) {
		this.calculateTotalCost();
	}

	public deleteSelectedSnapshot = () => {
		if(this.selectedSnapshot) {
			const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
			return dialogRef.afterClosed().toPromise().then(result => {
				if(result) {
					return this.api.deleteVehicleInventorySnapshot(this.vehicleId, this.selectedSnapshot.id).toPromise().finally(() => {
						this.initialize();
					});
				}
			}).catch(error => {
				console.error(error);
			});
		}
	}
}