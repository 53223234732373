import { Component } from "@angular/core";
import { UserService } from "../../services/user.service";
import { ApiService } from "../../services/api.service";
import { User } from "../../classes/user";
import { MatTableDataSource } from "@angular/material/table";
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from "../entity_selector_table/entitySelectorTable";

@Component({
	templateUrl: "./users.html",
	styleUrls: ["./users.scss"],
	selector: "users",
})
export class UsersComponent {
	public selectedUser: User = null;
	public tableDataSource = new MatTableDataSource();

	public loading: boolean = false;
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	constructor(public userService: UserService, private api: ApiService) {
		this.loadUsers();
		this.setupEntitySelectorTableDataSource();
	}

	public setupEntitySelectorTableDataSource() {
		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = "";
		editButton.columnProperty = "edit";
		editButton.columnWidth = "60px";
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: "edit",
			click: (user: User) => {
				this.selectedUser = user;
			},
		};
		this.selectorTableColumns.push(editButton);

		let nameColumn = new EntitySelectorTableColumn();
		nameColumn.columnHeader = "Username";
		nameColumn.columnProperty = "username";
		nameColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(nameColumn);

		let firstName = new EntitySelectorTableColumn();
		firstName.columnHeader = "First Name";
		firstName.columnProperty = "firstname";
		firstName.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(firstName);

		let lastName = new EntitySelectorTableColumn();
		lastName.columnHeader = "Last Name";
		lastName.columnProperty = "lastname";
		lastName.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(lastName);
		this.tableDataSource.filterPredicate = (user: User, filterText: string) => {
			let name = user.firstname + user.lastname + user.username;
			if (name == null || name == undefined) name = "";

			return name.toLowerCase().indexOf(filterText.trim()) > -1;
		};
	}

	public backClicked() {
		this.selectedUser = null;
		//		Force filtering to reoccur
		this.tableDataSource.filter = this.tableDataSource.filter + " ";
	}

	public loadUsers() {
		this.loading = true;
		return this.api
			.getAllUsers()
			.toPromise()
			.then((results) => {
				let users = results;

				//		Only allow admins to asee all accounts.
				//		Normal users should only be able to see their account.
				if (!this.userService.isAdmin()) {
					users = users.filter((user) => user.id == this.userService.user.id);
				}

				if (users && users.length) {
					users = users.sort((a, b) => {
						let aUser = a.firstname + a.lastname || "";
						let bUser = b.firstname + b.lastname || "";

						if (aUser > bUser) return 1;
						if (aUser < bUser) return -1;
						return 0;
					});
				}

				this.tableDataSource.data = users;

				if (this.selectedUser && this.selectedUser.id) {
					this.selectedUser = users.find((user) => user.id == this.selectedUser.id);
				} else {
					this.selectedUser = null;
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				this.loading = false;
			});
	}

	public newUserClicked() {
		this.selectedUser = new User();
	}
}
