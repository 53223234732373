import { Component, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ReactiveFormWrapper } from 'src/app/services/reactiveFormsWrapper';
import { Customer } from 'src/app/classes/customer';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
	selector: 'customer-picker',
	templateUrl: './customerPicker.html',
	styleUrls: ['./customerPicker.scss'],
})
export class CustomerPicker extends ReactiveFormWrapper {
	@Input() customerId: number = null;
	@Input() customerSelectedHandler: (event: MatAutocompleteSelectedEvent) => {};

	@Input() onChange: (event) => {};

	public customers: Customer[];
	public filteredCustomers: Observable<Customer[]> = of([]);

	constructor(private api: ApiService) {
		super();
		this.controlSet.subscribe(() => {
			this.init();
		});
		this.init();
	}

	public init() {
		this.api
			.getAllCustomers()
			.pipe(catchError((error) => of([])))
			.toPromise()
			.then((customers) => {
				this.customers = customers;
				this.filteredCustomers = this.control.valueChanges.pipe(
					startWith(''),
					debounceTime(200),
					distinctUntilChanged(),
					map((customerText) => this.filterCustomers(customerText)),
					catchError((error) => {
						console.error(error);
						return of([]);
					})
				);
			});
	}

	public filterCustomers(value: string): Customer[] {
		if (!this.customers) return [];

		return this.customers.filter((customer) => {
			if (value == null || value == undefined || value == '') return true;

			value = value.toString();

			return (customer.name || '').toLowerCase().includes(value.toLowerCase());
		});
	}

	public displayFn = (id) => {
		if (!this.customers) return '';

		let theCustomer = this.customers.find((user) => user.id == id);
		let display = null;

		if (theCustomer && id >= 0) {
			display = theCustomer.name || '';
		}

		return display;
	};
}
