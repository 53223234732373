<div *ngIf="storeForm" class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<mat-card fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="50%">
		<mat-card-title>
			<h2 *ngIf="!store.id" class="Anton"><span>New Store</span></h2>
			<h2 *ngIf="store.id" class="Anton">Store</h2>
		</mat-card-title>
	
		<mat-card-content>
			<div>
				<form  [formGroup]="storeForm">
					<div>
						<mat-form-field class="w-100">
							<mat-label>Name</mat-label>
							<input formControlName="name" matInput type="text">
							<mat-error *ngIf="formGroupHasError(storeForm, 'name', 'required')">
								Name is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</div>
				</form>
			</div>
			<br>
	
			<mat-divider></mat-divider>
	
			<br>
			
			<div>
				<loading-button
					[text]="'Submit'"
					[action]="onStoreFormSubmit"
				></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>