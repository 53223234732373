<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<div>
		<mat-card class="p-3">
			<mat-card-title>
				<h2>Settings / Options</h2>
			</mat-card-title>

			<mat-card-content>
				<div class="mb-3 flex-container flex-container-column flex-gap">
					<div><h3>UI Settings</h3></div>
					<div>
						<mat-slide-toggle (change)="toolbarVerticalChanged($event)" [checked]="this.settingsService.uiSettings().toolbarVertical">
							Align Toolbar Left
						</mat-slide-toggle>
					</div>
				</div>

				<div class="flex-container flex-container-column flex-gap">
					<div><h3>Adminstrative</h3></div>
					<mat-form-field class="w-100">
						<mat-label>Next Invoice Number</mat-label>
						<input [(ngModel)]="nextInvoiceNumber" matInput type="text" />
						<loading-button buttonClass="m-3" matSuffix [text]="'Submit'" [action]="changeInvoiceNumberClicked"></loading-button>
					</mat-form-field>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>
