export class Customer {
	public id: number;
	public name: string;
	public address: string;
	public street: string;
	public zipcode: string;
	public city: string;
	public state: string;
	public phone: string;
	public email: string;
	public taxRate: number;
	public taxExempt: boolean;
	public laborRate: number;
	public accouting_email: string;
	public po_email: string;
	public labor_rates: any[];

	constructor(data?: Customer) {
		if (data) {
			this.id = data.id;
			this.name = data.name;
			this.address = data.address;
			this.street = data.street;
			this.zipcode = data.zipcode;
			this.city = data.city;
			this.state = data.state;
			this.phone = data.phone;
			this.email = data.email
			this.taxExempt = data.taxExempt;
			this.taxRate = data.taxRate;
			this.laborRate = data.laborRate;
			this.accouting_email = data.accouting_email;
			this.po_email = data.po_email;
			this.labor_rates = data.labor_rates;
		}
	}
}