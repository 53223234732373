import { Component, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { InjectorInstance } from '../../app.module';

@Component({
	selector: 'snack-bar-alert',
	template: `
		<pre #container [style.color]="color">{{ content }}</pre>`,
	styles: []
})
export class SnackBarAlert {
	@Input() color;
	@Input() content;
}

export class SnackBarService {
	constructor() { }

	public static openSnackBarAlert(content: string = '', color: string = '#2aa22a'): MatSnackBarRef<SnackBarAlert> {
		const snackbar = InjectorInstance.get<MatSnackBar>(MatSnackBar);

		let alert = snackbar.openFromComponent(SnackBarAlert, {
			duration: 5000
		});

		alert.instance.color = color;
		alert.instance.content = content;

		return alert;
	}
}