<div class="mt-3" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start">
	<mat-card fxFlex.xs="95%" fxFlex.sm="70%" fxFlex.md="70" fxFlex="50%">
		<mat-card-title><h2 class="Anton">New Purchase Order</h2></mat-card-title>

		<mat-card-content>
			<form *ngIf="orderForm" [formGroup]="orderForm">
				<store-picker [validators]="[{key: 'required', message: 'Store is <strong>required</strong>'}]" [formGroupParent]="orderForm" [formGroupControlName]="'store_id'">
				</store-picker>
		
				<br>
		
				<mat-form-field class="w-100">
					<mat-label>Total Cost</mat-label>
					<span *ngIf="orderForm.get('total_cost').value < 0" matPrefix>(-) &nbsp;</span>
					<input formControlName="total_cost" matInput type="number">
					<mat-error *ngIf="formGroupHasError(orderForm, 'total_cost', 'required')">
						Total cost is <strong>required</strong>
					</mat-error>
					<mat-error *ngIf="formGroupHasError(orderForm, 'total_cost', 'min')">
						Total cost must be greater than or equal to <strong>0</strong>
					</mat-error>
				</mat-form-field>
		
				<br>
				
				<div>
					<button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
					<input hidden (change)="onFileChange($event)" #fileInput type="file" id="file">
					<span style="margin-left: 15px;" *ngIf="selectedFiles && selectedFiles.length > 0">{{ selectedFiles[0].name }}</span>
				</div>

				<br>

				<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
					<loading-button
						[text]="' Get Order Number'"
						[action]="onGetNextPurchaseOrderName"
					></loading-button>
					
					<mat-form-field>
						<mat-label>Purchase Order Number</mat-label>
						<input readonly formControlName="name" matInput type="text">
						<mat-error *ngIf="formGroupHasError(orderForm, 'name', 'required')">
							Purchase order name is <strong>required</strong>
						</mat-error>
					</mat-form-field>
				</div>
		
			</form>
			
			<br>

			<mat-divider></mat-divider>

			<br>
			
			<div>
				<loading-button
					[text]="'Submit'"
					[action]="onPurchaseOrderFormSubmit"
				></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>