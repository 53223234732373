import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { GoogleLoginButton } from './loginButton.component';
import { GoogleProfileButton } from './profile_button/profile.component';
import { MaterialModule } from '../material/material.module';

export { AuthorizationService } from './authorization.service';
export { GoogleLoginButton } from './loginButton.component';
export { GoogleProfileButton } from './profile_button/profile.component';

@NgModule({
    declarations: [
        GoogleLoginButton,
        GoogleProfileButton
    ],
    imports: [
        CommonModule,
        MaterialModule
    ],
    providers: [
        AuthorizationService
    ],
    exports: [
        GoogleLoginButton,
        GoogleProfileButton
    ]
})
export class GoogleModule { }
