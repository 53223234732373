import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { environment } from "../../environments/environment";

const configuration = {
	url: environment.webSocketUrl
};

export class SocketApiEvent {
	public operation: string;
	public user: number;
	public data: any;
	public type: string;
}

@Injectable({
	providedIn: 'root'
})
export class SocketService extends Socket {

	constructor(private api: ApiService, private userService: UserService) {
		super({ url: configuration.url, options: {} });
		this.handleSocketEvents();
	}

	private handleSocketEvents() {
		this.fromEvent('activity').subscribe((event: SocketApiEvent) => {
			//		Dont handle events that come from this user
			if (this.userService.user.id == event.user) {
				return;
			}

			if (event.operation && event.type) {
				switch (event.type) {
					case 'repairorder':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearRepairOrderCache();
								break;
							default:
								break;
						}

						break;
					case 'repairorder/lineitem':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.deleteRepairOrderLineItemFromCache(event.data.repairOrderid);
								break;
							default:
								break;
						}
						break;
					case 'repairorder/vehicle':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.deleteRepairOrderVehicleFromCache(event.data.repairOrderid);
								break;
							default:
								break;
						}
						break;
					case 'repairorder/customer':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.deleteRepairOrderCustomerFromCache(event.data.repairOrderid);
								break;
							default:
								break;
						}

						break;
					case 'customer':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearCustomersFromCache();
								break;
							default:
								break;
						}
						break;
					case 'lineitem':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':

								break;
							default:
								break;
						}
						break;
					case 'lineitem/product':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.deleteLineItemProductFromCache(event.data.lineItemId);
								break;
							default:
								break;
						}
						break;
					case 'product':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearProductsFromCache();
								break;
							default:
								break;
						}
						break;
					case 'purchaseorder/product':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearPurchaseOrdersFromCache();
								break;
							default:
								break;
						}
						break;
					case 'purchaseorder':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearPurchaseOrdersFromCache();
								break;
							default:
								break;
						}
						break;
					case 'store':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearStoresFromCache();
								break;
							default:
								break;
						}
						break;
					case 'user':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearUsersFromCache();
								break;
							default:
								break;
						}
						break;
					case 'vehicle/product':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.deleteVehicleProductFromCache(event.data.vehicleId);
								break;
							default:
								break;
						}
						break;
					case 'vehicle':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearVehiclesFromCache();
								break;
							default:
								break;
						}
						break;
					case 'quote':
						switch (event.operation) {
							case 'create':
							case 'update':
							case 'delete':
								this.api.clearQuotesCache();
								break;
							default:
								break;
						}
						break;
					default:
						break;
				}
			}
		});
	}
}