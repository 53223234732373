import { Component, ViewChild, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { ApiService } from "../../services/api.service";
import { RepairOrder, RepairOrderProcessingStep } from "../../classes/repairOrder";
import { RepairOrderComponent } from "./repair_order/repairOrder.component";
import { Subject, Observable, concat, of } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from "rxjs/operators";
import { LocalStorageService } from "../../services/local_storage.service";
import { ConfirmModal } from "../confirm_modal/confirmModal";
import { MatTableDataSource } from "@angular/material/table";
import { EntitySelectorTable, EntitySelectorTableColumn, EntitySelectorTableColumnType } from "../entity_selector_table/entitySelectorTable";
import { SnackBarService } from "../snack_bar_alert/snackBarAlert";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import { ActivatedRoute } from "@angular/router";

enum StatusFilter {
	All = 1,
	Open = 2,
	Closed = 3,
	Complete = 4,
}

enum Mode {
	Merge = "merge",
	Normal = "normal",
}

export const repairOrderStorageKey = 'repairOrder';

@Component({
	templateUrl: "./repairOrders.html",
	styleUrls: ["./repairOrders.scss"],
	selector: "repair-orders",
})
export class RepairOrdersComponent implements OnInit {
	public selectedOrder: RepairOrder = null;
	public filterText = "";
	public offset = 0;
	public mode = Mode.Normal;

	public tableDataSource = new MatTableDataSource();

	public loading: boolean = false;
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	@ViewChild(RepairOrderComponent) repairOrder: RepairOrderComponent;
	@ViewChild(EntitySelectorTable) table: EntitySelectorTable;

	public statusFilter: StatusFilter = StatusFilter.All;

	constructor(public userService: UserService, private api: ApiService, private storage: LocalStorageService, public dialog: MatDialog, public datePipe: DatePipe, public activatedRoute: ActivatedRoute) { }

	public ngOnInit() {
		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = "";
		editButton.columnProperty = "edit";
		editButton.columnWidth = "60px";
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: "edit",
			click: (order: RepairOrder) => {
				this.loadOrder(order);
			},
		};
		this.selectorTableColumns.push(editButton);

		let idColumn = new EntitySelectorTableColumn();
		idColumn.columnHeader = "Id";
		idColumn.columnWidth = "65px";
		idColumn.columnProperty = "id";
		idColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(idColumn);

		let nameColumn = new EntitySelectorTableColumn();
		nameColumn.columnHeader = "Customer";
		nameColumn.columnProperty = "customer.name";
		nameColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(nameColumn);

		let servicedBy = new EntitySelectorTableColumn();
		servicedBy.columnHeader = "Serviced By";
		servicedBy.columnProperty = "user";
		servicedBy.type = EntitySelectorTableColumnType.data;
		servicedBy.getData = (order: RepairOrder) => {
			if (!order || !order.user) {
				return 'Not set';
			}

			return `Emp# ${order.user.employee_number || ''}`;
		};

		this.selectorTableColumns.push(servicedBy);

		let unitNumber = new EntitySelectorTableColumn();
		unitNumber.columnHeader = "Vehicle Unit#";
		unitNumber.columnProperty = "vehicle.unit_number";
		unitNumber.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(unitNumber);

		let status = new EntitySelectorTableColumn();
		status.columnHeader = "Status";
		status.columnProperty = "repairOrderProcessingStepId";
		status.getData = (order: RepairOrder) => {
			let state = "";

			switch (order.repairOrderProcessingStepId) {
				case RepairOrderProcessingStep.New:
					state = " Open ";
					break;
				case RepairOrderProcessingStep.Closed:
					state = " Closed ";
					break;
				case RepairOrderProcessingStep.NeedsBilled:
					state = " Open ";
					break;
				case RepairOrderProcessingStep.Complete:
					state = " Complete ";
					break;
				default:
					break;
			}

			if (!order.lineItems || !order.lineItems.length) {
				state += "Unsubmitted";
			}

			return state;
		};
		status.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(status);

		let closeDate = new EntitySelectorTableColumn();
		closeDate.columnHeader = "Close Date";
		closeDate.columnProperty = "close_date";
		closeDate.getData = (element) => {
			let date = "";

			if (element.close_date) {
				date = this.datePipe.transform(new Date(element.close_date), "shortDate");
			}

			return date;
		};
		closeDate.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(closeDate);

		let completeDate = new EntitySelectorTableColumn();
		completeDate.columnHeader = "Complete Date";
		completeDate.columnProperty = "complete_date";
		completeDate.getData = (element) => {
			let date = "";

			if (element.complete_date) {
				date = this.datePipe.transform(new Date(element.complete_date), "short");
			}

			return date;
		};
		completeDate.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(completeDate);

		let isBilled = new EntitySelectorTableColumn();
		isBilled.columnHeader = "Billed";
		isBilled.columnProperty = "isBilled";
		isBilled.getData = (element: RepairOrder) => {
			if (element.isBilled) {
				return "Yes";
			}

			return "No";
		};
		isBilled.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(isBilled);

		this.tableDataSource.filterPredicate = (order: RepairOrder, filterText: string) => {
			let name = order.customer.name + order.vehicle.unit_number;

			//		name == ' ' when we have to manually trigger filtering when the status filter is updated
			if (name == null || name == undefined || name == " ") name = "";

			let includedByStatus = null;

			switch (this.statusFilter) {
				case StatusFilter.Open:
					includedByStatus = order.repairOrderProcessingStepId != RepairOrderProcessingStep.Closed && !order.close_date;
					break;
				case StatusFilter.Closed:
					includedByStatus = order.close_date != null;
					break;
				case StatusFilter.Complete:
					includedByStatus = order.complete_date != null;
					break;
				case StatusFilter.All:
					includedByStatus = true;
				default:
					break;
			}

			return name.trim().toLowerCase().indexOf(filterText.trim().toLowerCase()) > -1 && includedByStatus;
		};

		//      Initialize searching of repair orders
		this.searchRepairOrders();

		this.loadOrders().then(() => {
			if (this.activatedRoute.snapshot.queryParams.id) {
				let order = this.tableDataSource.data.find((order: RepairOrder) => order.id == this.activatedRoute.snapshot.queryParams.id);

				if (order) {
					this.loadOrder(order);
				}
			}
		});
	}

	public toggleMode = () => {
		this.mode = this.mode == Mode.Normal ? Mode.Merge : Mode.Normal;

		if (this.mode == Mode.Merge) {
			//		Add a check box data property to the repair orders
			this.tableDataSource.data.forEach((order: any) => {
				order.selected = false;
			});

			//		Add a button and check box column to the entity selector table
			let mergeButton = new EntitySelectorTableColumn();
			mergeButton.columnHeader = "";
			mergeButton.columnProperty = "merge";
			mergeButton.columnWidth = "60px";
			mergeButton.type = EntitySelectorTableColumnType.asyncButton;
			mergeButton.typeOptions = {
				icon: "fa fa-link",
				click: (order: RepairOrder) => {
					//	Gather all selected repair orders
					let selectedOrderIds: number[] = (this.tableDataSource.data.filter((order: any) => order.selected) as RepairOrder[]).map((order) => order.id);
					let thisOrderIndex = selectedOrderIds.findIndex((id) => id == order.id);

					if (thisOrderIndex > -1) {
						selectedOrderIds.splice(thisOrderIndex, 1);
					}

					if (selectedOrderIds.length > 0) {
						return this.api
							.mergeIntoRepairOrder(order.id, selectedOrderIds)
							.toPromise()
							.then(() => {
								SnackBarService.openSnackBarAlert("Merge successful. The repair orders table has now been refreshed.");
								return this.onRefresh({ id: null, callback: null });
							});
					}

					SnackBarService.openSnackBarAlert("Please select repair orders to merge into this order.", "red");
					return Promise.resolve();
				},
				materialType: "mat-mini-fab",
			};
			this.selectorTableColumns.unshift(mergeButton);

			let checkbox = new EntitySelectorTableColumn();
			checkbox.columnHeader = "";
			checkbox.columnProperty = "selected";
			checkbox.columnWidth = "35px";
			checkbox.type = EntitySelectorTableColumnType.checkbox;

			this.selectorTableColumns.unshift(checkbox);

			this.table.updateDisplayedColumns();
		} else {
			this.selectorTableColumns.shift();
			this.selectorTableColumns.shift();
			this.table.updateDisplayedColumns();

			//		Add a check box data property to the repair orders
			this.tableDataSource.data.forEach((order: any) => {
				delete order.selected;
			});
		}

		return Promise.resolve();
	};

	public backButtonClicked() {
		let userInputResolved: any = Promise.resolve();

		if (this.repairOrder.orderForm.dirty) {
			const dialogRef = this.dialog.open(ConfirmModal, { width: "250px" });
			dialogRef.componentInstance.dialogue = "It looks like you have not saved your order. Would you like to save? ";

			if (this.selectedOrder.id) {
				dialogRef.componentInstance.dialogue += "If not, your changes will be temporarily cached and can be loaded later.";
			}

			userInputResolved = dialogRef
				.afterClosed()
				.toPromise()
				.then((result) => {
					if (result) {
						return this.repairOrder.onRepairOrderSubmit(false).then(() => {
							this.onRefresh({ id: null, callback: null });
						});
					} else {
						//		Cache the unsaved changes for later
						this.storage.add(repairOrderStorageKey, this.repairOrder.orderForm.value.id, this.repairOrder.orderForm.value);
					}
				});
		}

		userInputResolved
			.then(() => {
				if (this.repairOrder) {
					this.repairOrder.tabView.selectedIndex = 0;
					this.repairOrder.tabView.realignInkBar();
				}

				this.selectedOrder = null;
				//		Force filtering to reoccur
				this.tableDataSource.filter = this.tableDataSource.filter + " ";
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public statusFilterUpdated(event) {
		this.statusFilter = event.value;

		//		Force filtering to reoccur
		this.tableDataSource.filter = this.tableDataSource.filter + " ";
	}

	public loadOrder(order) {
		if (order) {
			if (order.id && this.storage.has(repairOrderStorageKey, order.id)) {
				const dialogRef = this.dialog.open(ConfirmModal, { width: "250px" });
				dialogRef.componentInstance.dialogue = "It looks like last time you worked on this order some changes went unsaved. Would you like to load your unsaved changes?";

				return dialogRef
					.afterClosed()
					.toPromise()
					.then((result) => {
						let cachedOrder = this.storage.get(repairOrderStorageKey, order.id);

						//	If user wants to load the cached order and the cached copy exists
						//	Else, if they dont want to load it then remove it from the cache
						if (result && cachedOrder) {
							order = JSON.parse(cachedOrder);
						} else if (!result && cachedOrder) {
							this.storage.remove(repairOrderStorageKey, order.id);
						}

						this.selectedOrder = order;
					})
					.catch((error) => {
						//      Dont need to do anything here
					});
			} else {
				this.selectedOrder = order;
				return Promise.resolve();
			}
		}

		return Promise.resolve();
	}

	public loadMoreOrders = () => {
		this.offset += 200;
		return this.loadOrders();
	};

	public onRefresh(args: { id: number, callback: any }) {
		//      Reset the offset to 0 to load the first 50
		//      Clear out the orders array
		this.offset = 0;
		this.tableDataSource.data = [];

		let lineItemIndex = null;
		let tabIndex = null;
		if (this.repairOrder) {
			lineItemIndex = this.repairOrder.selectedLineItemIndex;
			tabIndex = this.repairOrder.selectedTabIndex;
		}

		return this.loadOrders(true).then(() => {
			if (args.id) {
				let order = this.tableDataSource.data.find((order: RepairOrder) => order.id == args.id);

				if (order) {
					if (this.repairOrder) {
						let subscription = this.repairOrder.initialized.subscribe(() => {
							if (lineItemIndex != null && tabIndex != null) {
								this.repairOrder.selectedLineItemIndex = lineItemIndex;
								this.repairOrder.selectLineItem(lineItemIndex);
							}

							//		Unsubscribe afterwards
							subscription.unsubscribe();
						});
					}

					return this.loadOrder(order);
				}
			} else {
				this.selectedOrder = null;
			}
		}).finally(() => {
			if (args.callback) {
				args.callback();
			}
		});
	}

	public repairOrdersSearchLoading = false;
	public repairOrderSearchInput = new Subject<string>();
	public searchedRepairOrders: Observable<Array<any>> = null;

	private searchRepairOrders() {
		this.searchedRepairOrders = concat(
			of([]),
			this.repairOrderSearchInput.pipe(
				debounceTime(200),
				distinctUntilChanged(),
				tap(() => (this.repairOrdersSearchLoading = true)),
				switchMap((term) =>
					this.api.searchRepairOrders(term).pipe(
						switchMap((response) => {
							return of(response);
						}),
						catchError(() => of([])),
						tap(() => (this.repairOrdersSearchLoading = false))
					)
				)
			)
		);
	}

	public onSearchRepairOrdersChange(selectedItem: any) {
		if (selectedItem && selectedItem.id) {
			this.repairOrdersSearchLoading = true;

			let downloadedOrder = this.tableDataSource.data.find((order: RepairOrder) => order.id == selectedItem.id);
			if (downloadedOrder) {
				if (this.mode == Mode.Normal) {
					this.loadOrder(downloadedOrder);
				}

				this.repairOrdersSearchLoading = false;
			} else {
				if (this.mode != Mode.Merge) {
					this.loadOrder(selectedItem);
				} else {
					let data = this.tableDataSource.data;
					data.unshift(selectedItem);
					this.tableDataSource.data = data;
				}

				this.repairOrdersSearchLoading = false;
			}
		}
	}

	private loadOrders(skipLoading: boolean = false): Promise<any> {
		if (!skipLoading) this.loading = true;

		return this.api
			.getAllRepairOrders(this.offset, false)
			.toPromise()
			.then((results) => {
				let orders: RepairOrder[] = results;

				//		Filter down the repair orders to only the ones that the user is able to see
				if (!this.userService.isAdmin()) {
					if (this.userService.isManager()) {
						orders = orders.filter((repairOrder) => (repairOrder.close_date && !repairOrder.complete_date) || repairOrder.user.id == this.userService.user.id);
					} else {
						orders = orders.filter((repairOrder) => repairOrder.user.id == this.userService.user.id);
					}
				}

				if (orders && orders.length) {
					orders = orders.sort((a, b) => {
						let aOrder = (a.id && a.id.toString().toLowerCase()) || "";
						let bOrder = (b.id && b.id.toString().toLowerCase()) || "";

						if (aOrder > bOrder) return -1;
						if (aOrder < bOrder) return 1;
						return 0;
					});
				}

				this.tableDataSource.data = this.tableDataSource.data.concat(orders);
				this.loading = false;
			})
			.catch((error) => {
				console.error(error);
				SnackBarService.openSnackBarAlert(`Error occurred while loading repair orders. Error: ${error}`, "red");
				this.loading = false;
			});
	}

	public newOrderClicked = () => {
		this.selectedOrder = new RepairOrder();
	};
}
