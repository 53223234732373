<mat-form-field class="w-100">
	<mat-label>{{ label }}</mat-label>

	<input [readonly]="isDisabled" matInput placeholder="Vehicle" [formControl]="control" [matAutocompleteDisabled]="isDisabled" [matAutocomplete]="auto" />

	<mat-autocomplete (optionSelected)="optionSelected($event)" #auto="matAutocomplete" [displayWith]="displayFn">
		@for(vehicle of filteredVehicles | async; track vehicle.id) {
		<mat-option [value]="vehicle.id"> {{ vehicle.unit_number }} - {{ vehicle.vin }} </mat-option>
		}
	</mat-autocomplete>

	@for(validator of validators; track $index) { @if(formControlHasError(control, validator.key)) {
	<mat-error>
		<span [innerHTML]="validator.message"></span>
	</mat-error>
	} }
</mat-form-field>
