import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { saveAs } from 'file-saver';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SnackBarService } from '../snack_bar_alert/snackBarAlert';

@Component({
	selector: 'reports',
	templateUrl: './reports.html',
	styleUrls: ['./reports.scss']
})
export class ReportsComponent {
	public range = new UntypedFormGroup({
		start: new UntypedFormControl(),
		end: new UntypedFormControl()
	})

	constructor(public userService: UserService, private api: ApiService) { }

	public downloadPurchaseOrderReceipts = () => {
		let receiptsDownloaded = Promise.resolve();

		if (this.range.invalid) {
			return receiptsDownloaded;
		}

		let beginDate = this.range.get('start').value.toLocaleDateString().replace(/\//g, "-");
		let endDate = this.range.get('end').value.toLocaleDateString().replace(/\//g, "-");

		receiptsDownloaded = this.api.downloadPurchaseOrderFiles(beginDate, endDate).toPromise().then(response => {
			let fileName = `${beginDate}_${endDate}_receipts.zip`;
			saveAs(response, fileName);

			SnackBarService.openSnackBarAlert('Purchase order files successfully downloaded.');
		}).catch(error => {
			SnackBarService.openSnackBarAlert(error.error.message, 'red');
			console.error(error);
		});

		return receiptsDownloaded;
	}
}