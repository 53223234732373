import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, catchError } from 'rxjs/operators';
import { Store } from 'src/app/classes/store';
import { ApiService } from 'src/app/services/api.service';
import { ReactiveFormWrapper } from 'src/app/services/reactiveFormsWrapper';

@Component({
	selector: 'store-picker',
	templateUrl: './storePicker.html',
	styleUrls: ['./storePicker.scss']
})
export class StorePicker extends ReactiveFormWrapper {
	@Input('storeId') storeId: number = null;
	@Input() readonly: boolean = false;

	public stores: Store[];
	public filteredStores: Observable<Store[]>;
	public loading: boolean = true;

	constructor(private api: ApiService) {
		super();
		this.controlSet.subscribe(() => {
			this.init();
		});
		this.init();
	}

	public init() {
		this.loading = true;
		this.api.getStores().pipe(catchError(error => of([]))).toPromise().then(stores => {
			this.stores = stores;
			this.filteredStores = this.control.valueChanges.pipe(
				startWith(''),
				debounceTime(200),
				distinctUntilChanged(),
				map(userText => this.filterStores(userText)),
				catchError(error => {
					console.error(error);
					return of([])
				})
			);
		}).finally(() => {
			this.loading = false;
		});
	}

	public filterStores(value: string): Store[] {
		return this.stores.filter(store => {
			if (value == null || value == undefined || value == '') return true;

			value = value.toString().toLowerCase();

			return store.name.toLowerCase().includes(value.toLowerCase());
		});
	}

	public displayFn = (id) => {
		let theStore = this.stores.find(store => store.id == id);
		let display = null;

		if (theStore && id >= 0) {
			display = theStore.name;
		}

		return display;
	}
}