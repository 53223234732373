<div class="mt-3" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start" fxLayout="row">
	<div fxFlex.xs="95" fxFlex.sm="95" fxFlex.md="70" fxFlex="50">
		<mat-card>
			<mat-card-title>
				<h2 class="Anton">Settings / Options</h2>
			</mat-card-title>

			<mat-card-content class="pageOverflow">
				<div>
					<div>
						<mat-form-field class="w-100">
							<mat-label>Next Invoice Number</mat-label>
							<input [(ngModel)]="nextInvoiceNumber" matInput type="text">
						</mat-form-field>
					</div>
				</div>

				<br>
				<mat-divider></mat-divider>

				<br>

				<div>
					<loading-button [text]="'Set Next Invoice Number'"
						[action]="changeInvoiceNumberClicked"></loading-button>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>