@if(userForm) {
	<div class="flex-container flex-container-column flex-gap flex-align-items-center flex-justify-center">
		<mat-card class="p-3">
			<mat-card-title>
				@if(!user.id) {
					<h2>
						<span>New Account</span>
					</h2>
				} @else {
					<h2>Account</h2>
				}
			</mat-card-title>
	
			<mat-card-content>
				<form [formGroup]="userForm" class="flex-container flex-gap flex-container-column">
					<div>
						<mat-form-field class="w-100">
							<mat-label>Email</mat-label>
							<input formControlName="email" matInput type="email" />

							@if(formGroupHasError(userForm, 'email', 'required')) {
								<mat-error> Email is
									<strong>required</strong>
								</mat-error>
							} @else if (formGroupHasError(userForm, 'email', 'email')) {
								<mat-error>
									Not a valid email address
								</mat-error>
							}

						</mat-form-field>
					</div>

					<div>
						<mat-form-field class="w-100">
							<mat-label>Username</mat-label>
							<input formControlName="username" matInput type="text" />

							@if(formGroupHasError(userForm, username, 'required')) {
								<mat-error> Username is
									<strong>required</strong>
								</mat-error>
							}
						</mat-form-field>
					</div>

					<div [class.flex-container-column]="device.isPhone" [class.flex-container-row]="!device.isPhone" class="flex-container flex-gap flex-align-items-center flex-justify-center">
						<mat-form-field class="w-100">
							<mat-label>First Name</mat-label>
							<input formControlName="firstname" matInput type="text" />

							@if(formGroupHasError(userForm, 'firstname', 'required')) {
								<mat-error> 
									First name is <strong>required</strong>
								</mat-error>
							}

						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>Last Name</mat-label>
							<input formControlName="lastname" matInput type="text" />

							@if(formGroupHasError(userForm, 'lastname', 'required')) {
								<mat-error>
									Last name is <strong>required</strong> 
								</mat-error>
							}

						</mat-form-field>
					</div>

					<div [class.flex-container-column]="device.isPhone" [class.flex-container-row]="!device.isPhone" class="flex-container flex-gap flex-align-items-center flex-justify-center">
						<mat-form-field class="w-100">
							<mat-label>Choose a user type</mat-label>
							<mat-select formControlName="user_type_id">
								<mat-option *ngFor="let type of userTypes" [value]="type.id">{{ type.type
									}}</mat-option>
							</mat-select>

							@if(formGroupHasError(userForm, 'user_type_id', 'required')) {
								<mat-error>
									User type is <strong>required</strong> 
								</mat-error>
							}

						</mat-form-field>
					</div>

					<div>
						<vehicle-picker class="w-100"
							[validators]="[{key: 'required', message: 'Vehicle is <strong>required</strong>'}]"
							[formGroupParent]="userForm" [formGroupControlName]="'vehicleId'"> </vehicle-picker>
					</div>

					<div>
						<mat-form-field class="w-100">
							<mat-label>Mobile Phone</mat-label>
							<input formControlName="mobile_phone" matInput type="tel" />

							@if(formGroupHasError(userForm, 'mobile_phone', 'pattern')) {
								<mat-error>
									Not a valid phone number
								</mat-error>
							}

						</mat-form-field>
					</div>

					<div>
						<mat-form-field class="w-100">
							<mat-label>Employee Number</mat-label>
							<input [readonly]="!userService.isAdmin()" formControlName="employee_number" matInput
								type="text" />

								@if(formGroupHasError(userForm, 'email', 'required')) {
									<mat-error>
										Employee Number is <strong>required</strong>
									</mat-error>
								}

						</mat-form-field>
					</div>

					<div>
						<mat-checkbox disabled="true" class="mr-3" formControlName="active">Active</mat-checkbox>
					</div>

					<div [class.flex-container-column]="device.isPhone" [class.flex-container-row]="!device.isPhone" class="flex-container flex-gap flex-align-items-center flex-justify-center p-3">

						<mat-form-field class="w-100">
							<mat-label>New Password</mat-label>
							<input formControlName="changedPasswordControl" matInput type="password" />
						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>Confirm New Password</mat-label>
							<input formControlName="changedPasswordCopyControl" matInput type="password" />

							@if(formGroupHasError(userForm, 'changedPasswordCopyControl', 'mismatch')) {
								<mat-error>
									Passwords do not <strong>match</strong>
								</mat-error>								
							}

						</mat-form-field>
					</div>
				</form>
				<br />
	
				<mat-divider></mat-divider>
	
				<br />
	
				<div [class.flex-container-column]="device.isPhone" [class.flex-container-row]="!device.isPhone" class="flex-container flex-gap flex-align-items-center flex-justify-center p-3">
					<loading-button [text]="'Submit'" [action]="onUserFormSubmit"></loading-button>
	
					<loading-button class="ml-3" [text]="'Deactivate User'" [action]="deactivateAccount"></loading-button>
	
					<loading-button class="ml-3" [text]="'Quick Reset Password'"
						[action]="quickResetUserPassword"></loading-button>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
}