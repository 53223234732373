<div class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start"
	[hidden]="selectedOrder || loading">
	<div fxLayout="column" fxLayoutGap="15px">
		<button (click)="newOrderClicked()" mat-raised-button color="primary">New Order</button>

		<div>
			<ng-select [items]="searchedRepairOrders | async" [selectOnTab]="true" [virtualScroll]="true"
				placeholder="Search Repair Orders..." bindLabel="name" [loading]="repairOrdersSearchLoading"
				[typeahead]="repairOrderSearchInput" (change)="onSearchRepairOrdersChange($event)">
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					{{ item.vehicle.unit_number }} - {{ item.customer.name }}
				</ng-template>
				<ng-template ng-label-tmp let-item="item">
					{{ item.vehicle.unit_number }} - {{ item.customer.name }}
				</ng-template>
			</ng-select>
		</div>

		<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
			<mat-button-toggle-group #group (change)="statusFilterUpdated($event)" [(ngModel)]="statusFilter"
				name="filterGroup">
				<mat-button-toggle [value]="1">All</mat-button-toggle>
				<mat-button-toggle [value]="2">Open</mat-button-toggle>
				<mat-button-toggle [value]="4">Complete</mat-button-toggle>
				<mat-button-toggle [value]="3">Closed</mat-button-toggle>
			</mat-button-toggle-group>

			<loading-button [text]="'Load 200 more'" [action]="loadMoreOrders"></loading-button>

			<loading-button *ngIf="userService.isAdmin()" [text]="mode != 'merge' ? 'Merge Mode' : 'Normal Mode'"
				[action]="toggleMode"></loading-button>
		</div>

		<div style="max-width: calc(100vw - 100px); overflow: auto;">
			<entity-selector-table [tableMaxHeight]="'55vh'" [dataSource]="tableDataSource"
				[selectorTableColumns]="selectorTableColumns" [pageSize]="50">
			</entity-selector-table>
		</div>
	</div>
</div>

<div *ngIf="loading" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center center">
	<mat-spinner></mat-spinner>
</div>

<div *ngIf="selectedOrder && !loading" fxLayout="column">
	<button mat-icon-button (click)="backButtonClicked()">
		<mat-icon>arrow_back</mat-icon> Back
	</button>
	<repair-order (refreshRepairOrders)="onRefresh($event)" [order]="selectedOrder"></repair-order>
</div>