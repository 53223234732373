<div *ngIf="customerForm" class="mt-3 overflow maxHeight" fxFlexFill fxFlex="calc(100vw - 50px)"
	fxLayoutAlign="center start">
	<mat-card fxFlex.xs="95%" fxFlex.sm="95%" fxFlex.md="70" fxFlex="50%">
		<mat-card-title>
			<h2 *ngIf="!customer.id" class="Anton"><span>New Customer</span></h2>
			<h2 *ngIf="customer.id" class="Anton">Customer</h2>
		</mat-card-title>

		<mat-card-content>
			<div>
				<form [formGroup]="customerForm">
					<div>
						<mat-form-field class="w-100">
							<mat-label>Name</mat-label>
							<input formControlName="name" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'name', 'required')">
								Name is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</div>

					<div>
						<mat-form-field class="w-100">
							<mat-label>Phone</mat-label>
							<input formControlName="phone" matInput type="text">
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>Address</mat-label>
							<input formControlName="address" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'address', 'required')">
								Address is <strong>required</strong>
							</mat-error>
						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>Street</mat-label>
							<input formControlName="street" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'street', 'required')">
								Street is <strong>required</strong>
							</mat-error>
						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>City</mat-label>
							<input formControlName="city" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'city', 'required')">
								City is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>State</mat-label>
							<input formControlName="state" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'state', 'required')">
								State is <strong>required</strong>
							</mat-error>
						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>Zip</mat-label>
							<input formControlName="zipcode" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'zip', 'required')">
								Zip is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>Accouting Email</mat-label>
							<input formControlName="email" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'email', 'required')">
								Email is <strong>required</strong>
							</mat-error>
							<mat-error *ngIf="formGroupHasError(customerForm, 'email', 'email')">
								Not a valid email address
							</mat-error>
						</mat-form-field>

						<mat-form-field class="w-100">
							<mat-label>PO Email</mat-label>
							<input formControlName="po_email" matInput type="text">
							<mat-error *ngIf="formGroupHasError(customerForm, 'po_email', 'required')">
								PO email is <strong>required</strong>
							</mat-error>
							<mat-error *ngIf="formGroupHasError(customerForm, 'po_email', 'email')">
								Not a valid email address
							</mat-error>
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>Tax Rate</mat-label>
							<input formControlName="taxRate" matInput type="number">
						</mat-form-field>
					</div>

					<div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
						<mat-form-field class="w-100">
							<mat-label>Default Labor Rate</mat-label>
							<input formControlName="laborRate" matInput type="number">
						</mat-form-field>

						<customer-labor-rate class="w-100" *ngIf="customerForm && customerForm.get('labor_rates').value"
							[laborRates]="customerForm.get('labor_rates')"></customer-labor-rate>
					</div>



					<div fxLayout="row">
						<mat-checkbox class="mr-3" formControlName="taxExempt">Tax Exempt</mat-checkbox>
						<mat-checkbox formControlName="active">Active</mat-checkbox>
					</div>
				</form>
			</div>
			<br>

			<mat-divider></mat-divider>

			<br>

			<div>
				<loading-button [text]="'Submit'" [action]="onCustomerFormSubmit"></loading-button>
			</div>
		</mat-card-content>
	</mat-card>
</div>