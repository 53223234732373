<div class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start"
	[hidden]="selectedVehicle || loading">
	<div fxLayout="column" fxLayoutGap="15px">
		<button (click)="newVehicleClicked()" mat-raised-button color="primary">Create Vehicle</button>
		<mat-slide-toggle class="ml-3" (change)="onlyUserVehiclesChanged()" [(ngModel)]="showOnlyUserVehicles">
			Only User Vehicles
		</mat-slide-toggle>

		<div style="max-width: calc(100vw - 100px); overflow: auto;">
			<entity-selector-table [dataSource]="tableDataSource" [selectorTableColumns]="selectorTableColumns">
			</entity-selector-table>
		</div>
	</div>
</div>

<div *ngIf="loading" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center center">
	<mat-spinner></mat-spinner>
</div>

<div *ngIf="selectedVehicle && !loading" fxLayout="column">
	<button mat-icon-button (click)="backClicked()">
		<mat-icon>arrow_back</mat-icon> Back
	</button>
	<vehicle (onRefresh)="loadVehicles($event)" [vehicle]="selectedVehicle">
	</vehicle>
</div>