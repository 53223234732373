import { Component, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { User } from "../../classes/user";
import packageInformation from "../../../../package.json";
import { NetworkService } from "src/app/services/network.service";
import { SocketService } from "src/app/services/socket.service";
import { AuthorizationService } from "src/app/modules/google/google.module";
import { environment } from "../../../environments/environment";

@Component({
	templateUrl: "./home.html",
	styleUrls: ["./home.scss"],
	selector: "home",
})
export class HomeComponent implements AfterViewInit {
	public user: User = this.userService.getUser();
	public version: string = packageInformation.version;
	public isOnline: boolean = true;
	public environmentTitle: string = environment.title;

	constructor(
		private userService: UserService,
		public router: Router,
		public network: NetworkService,
		public socketService: SocketService, //		Runs on its own, just need it initalized
		public google: AuthorizationService
	) { }

	public ngAfterViewInit() {
		this.network.watchForNetworkChanges((isOnline) => {
			this.isOnline = isOnline;
		});

		this.google.getEmailLables();
	}

	public logoutClicked() {
		let loggedOut = Promise.resolve();

		/*if(this.google && this.google.isLoggedIn()) {
			loggedOut = this.google.logout();
		}*/

		return loggedOut
			.then(() => {
				this.router.navigate(["../login"]);
			})
			.catch((error) => {
				console.error(error);
			});
	}
}
