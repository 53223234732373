<div class="mt-3 overflow" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center start"
    [hidden]="selectedQuote || loading">
    <div fxLayout="column" fxLayoutGap="15px">
        <button (click)="newQuoteClicked()" mat-raised-button color="primary">New Quote</button>

        <div>
            <ng-select [items]="searchedQuotes | async" [selectOnTab]="true" [virtualScroll]="true"
                placeholder="Search Repair Quotes..." bindLabel="name" [loading]="quotesSearchLoading"
                [typeahead]="quotesSearchInput" (change)="onSearchQuotesChange($event)">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    {{ item.vehicle.unit_number }} - {{ item.customer.name }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    {{ item.vehicle.unit_number }} - {{ item.customer.name }}
                </ng-template>
            </ng-select>
        </div>

        <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
            <loading-button [text]="'Load 200 more'" [action]="loadMoreQuotes"></loading-button>
        </div>

        <div style="max-width: calc(100vw - 100px); overflow: auto;">
            <entity-selector-table [tableMaxHeight]="'55vh'" [dataSource]="tableDataSource"
                [selectorTableColumns]="selectorTableColumns" [pageSize]="50">
            </entity-selector-table>
        </div>
    </div>
</div>

<div *ngIf="loading" fxFlexFill fxFlex="calc(100vw - 50px)" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="selectedQuote && !loading" fxLayout="column">
    <button mat-icon-button (click)="backButtonClicked()">
        <mat-icon>arrow_back</mat-icon> Back
    </button>
    <quote (refreshQuotes)="onRefresh($event)" [quote]="selectedQuote"></quote>
</div>