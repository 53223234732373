import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { Vehicle } from '../../../classes/vehicle';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { VehicleInventory } from '../vehicle_inventory/vehicleInventory.component';
import { MatTableDataSource } from '@angular/material/table';
import { EntitySelectorTable, EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../../entity_selector_table/entitySelectorTable';
import Decimal from 'decimal.js';
import { trimToNumber } from 'src/app/services/Helper';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { Product } from 'src/app/classes/quote';

@Component({
	templateUrl: './vehicle.html',
	styleUrls: ['./vehicle.scss'],
	selector: 'vehicle'
})
export class VehicleComponent extends FormComponent implements OnInit, AfterViewInit {
	public vehicleForm: UntypedFormGroup = null;
	public vehicleProductsForm: UntypedFormArray = null;

	_vehicle: Vehicle = null;
	get vehicle(): Vehicle {
		return this._vehicle;
	}

	@Input('vehicle')
	set vehicle(value: Vehicle) {
		if (value) {
			//      Create copy of the order and use it for editing
			this._vehicle = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeVehicleForm();
		}
	}

	@Output('onRefresh')
	onRefresh = new EventEmitter<any>();

	public loading: boolean = false;
	public totalProductsWorth: string = '0.00';

	public tableDataSource = new MatTableDataSource();
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	@ViewChild(EntitySelectorTable) entityTable: EntitySelectorTable;
	public entitySelectorTableFilters: any[] = [
		(dataSource: MatTableDataSource<any>) => {
			dataSource.data = dataSource.data.filter((productForm: UntypedFormGroup) => {
				//		Touch each control
				Object.keys(productForm.controls).forEach(key => {
					productForm.get(key).markAsTouched();
				});
				return !productForm.valid;
			});
		}
	];

	constructor(public userService: UserService, private api: ApiService, private formBuilder: UntypedFormBuilder, public dialog: MatDialog) {
		super();
	}

	public ngOnInit() {
		//		Set up the columns for the selector table
		let deleteButton = new EntitySelectorTableColumn();
		deleteButton.columnHeader = "";
		deleteButton.columnProperty = "delete";
		deleteButton.columnWidth = "75px";
		deleteButton.type = EntitySelectorTableColumnType.asyncButton;
		deleteButton.typeOptions = {
			icon: 'fa fa-trash',
			click: (productForm: UntypedFormGroup, index: number) => {
				const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
				return dialogRef.afterClosed().toPromise().then(result => {
					if (result) {
						if (!productForm || !productForm.value || !productForm.value.id) {
							if (index > -1) {
								let data = this.tableDataSource.data;
								data.splice(index, 1);
								this.tableDataSource.data = data;
							}

							return Promise.resolve();
						}

						return this.deleteProducts([productForm]);
					}
				}).catch(error => {
					console.error(error);
				});
			},
			materialType: 'mat-mini-fab'
		};
		this.selectorTableColumns.push(deleteButton);

		let costColumn = new EntitySelectorTableColumn();
		costColumn.columnHeader = "Cost";
		costColumn.inputLabel = "Cost";
		costColumn.columnProperty = "cost";
		costColumn.type = EntitySelectorTableColumnType.number;
		costColumn.errors = [{
			message: 'Cost is required!',
			name: Validators.required.name
		},
		{
			message: 'Cost must be greater than or equal to 0',
			name: Validators.min.name
		}];



		this.selectorTableColumns.push(costColumn);

		let quantityColumn = new EntitySelectorTableColumn();
		quantityColumn.columnHeader = "Quantity";
		quantityColumn.inputLabel = "Quantity";
		quantityColumn.columnProperty = "quantity";
		quantityColumn.type = EntitySelectorTableColumnType.number;
		quantityColumn.errors = [{
			message: 'Quantity is required!',
			name: Validators.required.name
		},
		{
			message: 'Quantity must be greater than or equal to 0',
			name: Validators.min.name
		}];

		this.selectorTableColumns.push(quantityColumn);

		let descriptionColumn = new EntitySelectorTableColumn();
		descriptionColumn.columnHeader = "Description";
		descriptionColumn.inputLabel = "Description";
		descriptionColumn.columnProperty = "description";
		descriptionColumn.type = EntitySelectorTableColumnType.text;
		descriptionColumn.errors = [{
			message: 'Description is required!',
			name: Validators.required.name
		}];

		this.selectorTableColumns.push(descriptionColumn);

		let partNumberColumn = new EntitySelectorTableColumn();
		partNumberColumn.columnHeader = "Part Number";
		partNumberColumn.inputLabel = "Part Number"
		partNumberColumn.columnProperty = "part_number";
		partNumberColumn.type = EntitySelectorTableColumnType.text;
		partNumberColumn.errors = [{
			message: 'Part number is required!',
			name: Validators.required.name
		},
		{
			message: 'Duplicate part number identified.',
			name: 'duplicatePartNumber'
		}];

		this.selectorTableColumns.push(partNumberColumn);

		this.tableDataSource.filterPredicate = (productForm: UntypedFormGroup, filterText: string) => {
			let product: Product = productForm.value || {};

			let name = product.part_number + ' ' + product.description;
			if (name == null || name == undefined) name = '';

			name = name.toLowerCase().trim();

			let results = name.includes(filterText.trim());

			return results;
		};
	}

	public ngAfterViewInit() {
		this.getVehicleProducts();
	}

	public showingOnlyErroredRows = false;
	public showOnlyErroredRows = () => {
		this.showingOnlyErroredRows = !this.showingOnlyErroredRows;

		if (this.entityTable) {
			if (this.showingOnlyErroredRows) {
				this.entityTable.applyFilters();
			} else {
				this.entityTable.unapplyFilters();
			}
		}

		return Promise.resolve();
	}

	public calculateTotalProductWorth() {
		this.totalProductsWorth = '0.00';

		//		Calculate the amount of money on the vehicle
		let calculatedAmount: number = 0;
		(this.vehicleProductsForm.value as Product[]).map(form => {
			let vehicleProduct: Product = form || {} as Product;

			let cost = new Decimal(trimToNumber(vehicleProduct.cost || 0, 2));
			let quantity = new Decimal(trimToNumber(vehicleProduct.quantity || 0, 2));

			calculatedAmount += (cost.times(quantity)).toNumber();
		});

		this.totalProductsWorth = '$' + trimToNumber(calculatedAmount, 2).toString();
	}

	public deleteProducts(productForms: UntypedFormGroup[]): Promise<any> {
		if (!productForms || productForms.length <= 0 || !this.vehicleForm.get('id') || !(this.vehicleForm.get('id').value >= 0)) {
			return Promise.resolve();
		}

		productForms = productForms.filter(productForm => productForm && productForm.value && productForm.value.id >= 0);

		return this.api.deleteVehicleProducts({ vehicleId: this.vehicleForm.get('id').value as number, products: productForms.map(form => form.value) }).toPromise().then(() => {
			SnackBarService.openSnackBarAlert('Vehicle product deleted.');
		}).catch(error => {
			SnackBarService.openSnackBarAlert(error.error.message, 'red');
			console.error(error);
		}).finally(() => {
			return this.getVehicleProducts();
		});
	}

	public saveProducts(productForms: UntypedFormGroup[]): Promise<any> {
		if (!productForms || productForms.length <= 0 || !this.vehicleForm.get('id') || !((this.vehicleForm.get('id').value as number) >= 0)) {
			return Promise.resolve();
		}

		return this.api.getAllProducts().toPromise().then((allProducts) => {
			if (productForms.find(form => form.invalid)) {
				productForms.forEach((form: UntypedFormGroup) => { Object.keys(form.controls).forEach(key => { form.controls[key].markAsDirty(); form.controls[key].markAsTouched(); }); });
				return Promise.reject({ error: { message: 'Cannot save new or existing product edits. Missing or invalid information found.' } });
			}

			//		Filter out invalid forms for the request
			productForms = productForms.filter(productForm => productForm && productForm.valid);

			let newProducts: UntypedFormGroup[] = productForms.filter(productForm => productForm.value && !productForm.value.id);

			newProducts.map((productForm: UntypedFormGroup) => {
				let product: Product = productForm.value || {};
				let partNumber = (product.part_number || '').toLowerCase().trim();

				let foundProduct = allProducts.find(theProduct => {
					let theProductPartNumber = (theProduct.part_number || '').toLowerCase().trim();

					return theProductPartNumber == partNumber;
				});

				if (foundProduct) {
					productForm.get('id').patchValue(foundProduct.id);
				}
			});

			let existingProducts: UntypedFormGroup[] = productForms.filter(productForm => productForm.value && productForm.value.id).map(productForm => {
				let existingProduct: Product = productForm.value;
				let existingPartNumber = ((existingProduct && existingProduct.part_number) || '').toLowerCase().trim();

				let newProductInfoIndex = newProducts.findIndex(newProductForm => {
					let newProduct: Product = newProductForm.value;
					let newPartNumber = ((newProduct && newProduct.part_number) || '').toLowerCase().trim();

					return existingPartNumber == newPartNumber;
				});

				if (newProductInfoIndex >= 0) {
					let newProduct: Product = newProducts[newProductInfoIndex].value;
					existingProduct.cost = newProduct.cost;
					existingProduct.quantity = newProduct.quantity;

					newProducts.splice(newProductInfoIndex, 1);
				}

				return productForm;
			});

			let newProductsCreated = Promise.all(newProducts.map(newProduct => {
				return this.api.createProduct(newProduct.value).toPromise();
			})).then(createdProducts => {
				createdProducts = createdProducts.map((createdProduct: Product, index) => {
					let newProductForm = newProducts[index];
					let newProduct: Product = newProductForm.value || null;

					createdProduct.quantity = newProduct && newProduct.quantity;
					createdProduct.cost = newProduct && newProduct.cost;

					return createdProduct;
				});

				return this.api.createVehicleProducts({ vehicleId: this.vehicleForm.get('id').value, products: createdProducts }).toPromise();
			});

			let productsSaved = Promise.all([
				this.api.updateVehicleProducts(this.vehicleForm.get('id').value as number, existingProducts.map(productForm => productForm.value)).toPromise(),
				newProductsCreated
			]).then(() => {
				SnackBarService.openSnackBarAlert('Products saved.');
			}).catch(error => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});

			return productsSaved.finally(() => {
				return this.getVehicleProducts();
			});
		});
	}

	public onAddProductClicked = () => {
		let productAdded = Promise.resolve();

		let tableData = this.tableDataSource.data;
		let productForm = this.getProductForm();
		productForm.markAsDirty();
		productForm.markAsTouched();

		tableData.unshift(productForm);
		this.tableDataSource.data = tableData;

		this.tableDataSource.paginator.firstPage();

		return productAdded;
	}

	public initializeVehicleForm() {
		if (this.vehicle) {
			this.vehicleForm = this.formBuilder.group({
				id: new UntypedFormControl(this.vehicle.id),
				vin: new UntypedFormControl(this.vehicle.vin, [Validators.required]),
				unit_number: new UntypedFormControl(this.vehicle.unit_number, [Validators.required]),
				license_number: new UntypedFormControl(this.vehicle.license_number, [Validators.required]),
				milage: new UntypedFormControl(this.vehicle.milage, Validators.required)
			});
		}
	}

	public onVehicleFormSubmit = () => {
		//	Promise to be returned
		let vehicleSubmitted = Promise.resolve();

		if (!this.vehicleForm || this.vehicleForm.invalid) {
			this.vehicleForm.markAllAsTouched();
			return vehicleSubmitted;
		}
		this.loading = true;

		if (this.vehicleForm.value.id) {
			vehicleSubmitted = this.api.updateVehicle(this.vehicleForm.value).toPromise();
		} else {
			vehicleSubmitted = this.api.createVehicle(this.vehicleForm.value).toPromise().then(id => {
				return Promise.resolve(this.vehicleForm.get('id').patchValue(id));
			});
		}

		//		submit the products
		return vehicleSubmitted
			.then(() => this.saveProducts(this.tableDataSource.data.filter((form: UntypedFormGroup) => form.dirty) as UntypedFormGroup[]))
			.then(() => this.getVehicleProducts())
			.then(() => {
				SnackBarService.openSnackBarAlert('Vehicle saved.');
				this.onRefresh.emit();
			})
			.catch(error => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			})
			.finally(() => {
				this.loading = false;
			});
	}

	public onTakeInventoryClicked = () => {
		const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
		return dialogRef.afterClosed().toPromise().then(result => {
			if (result) {
				return this.saveProducts(this.vehicleProductsForm.controls as UntypedFormGroup[]).then(() => {
					return this.api.snapshotVehicleInventory(this.vehicle.id).toPromise();
				});
			}
		}).then(() => {
			SnackBarService.openSnackBarAlert('Inventory snapshot saved.');
		}).catch(error => {
			SnackBarService.openSnackBarAlert(error.message || error, 'red');
			console.error(error);
		});
	};

	public onShowAllInventoriesClicked = () => {
		const dialogRef = this.dialog.open(VehicleInventory, { width: '60vw' });
		dialogRef.componentInstance.vehicleId = this._vehicle.id;

		return dialogRef.afterClosed().toPromise().catch(error => {
			console.error(error);
		});
	};

	public getVehicleProducts(): Promise<any> {
		if (this.vehicle.id) {
			return this.api.getVehicleProducts(this.vehicle.id, false).toPromise().then(response => {
				let vehicleProducts = response;
				vehicleProducts.sort((a, b) => {
					let aDescription = (a.description && a.description.toLowerCase()) || '';
					let bDescription = (b.description && b.description.toLowerCase()) || '';

					if (aDescription < bDescription) { return -1; }
					if (aDescription > bDescription) { return 1; }
					return 0;
				});
				this.vehicleProductsForm = new UntypedFormArray(vehicleProducts.map(product => {
					return this.getProductForm(product);
				}));
				this.tableDataSource.data = this.vehicleProductsForm.controls;
				this.calculateTotalProductWorth();
			}).catch(error => {
				console.error(error);
			});
		}

		return Promise.resolve();
	}

	public getProductForm(product?: Product): UntypedFormGroup {
		if (!product) { product = new Product(); }

		return this.formBuilder.group({
			id: new UntypedFormControl(product.id),
			cost: new UntypedFormControl(product.cost, [Validators.required, Validators.min(0)]),
			quantity: new UntypedFormControl(product.quantity, [Validators.required]),
			description: new UntypedFormControl(product.description, [Validators.required]),
			part_number: new UntypedFormControl(product.part_number, [Validators.required])
		});
	}
}