import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray, AbstractControl, FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Product, LineItem } from 'src/app/classes/quote';
import { ApiService } from 'src/app/services/api.service';
import { FormComponent } from 'src/app/services/FormComponentHelper';
import { InvoiceService } from 'src/app/services/invoice.service';
import { LocalStorageService } from 'src/app/services/local_storage.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../../entity_selector_table/entitySelectorTable';
import { ProductUpchargeToggle } from '../../repair_orders/repair_order/repairOrder.component';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { GetVehicleProductsModal } from '../../vehicles/get_vehicle_products_modal/getVehicleProductsModal';
import { VehiclePicker } from '../../vehicles/vehicle_picker/vehiclePicker.component';
import { quotesStorageKey } from '../quotes';
import { Quote } from '../../../classes/quote';
import { padToLength } from '../../../../app/services/Helper';
import { User } from 'src/app/classes/user';

@Component({
	selector: 'quote',
	styleUrls: ['./quote.scss'],
	templateUrl: './quote.html'
})
export class QuoteComponent extends FormComponent implements OnDestroy, AfterViewInit {
	public quoteForm: UntypedFormGroup = null;

	_quote: Quote = null;
	get quote(): Quote {
		return this._quote;
	}

	@Input("quote")
	set quote(value: Quote) {
		if (value) {
			//      Create copy of the quote and use it for editing
			this._quote = JSON.parse(JSON.stringify(value));

			//      Create our form group instance
			this.initializeQuoteForm();

			if (this.tabView) {
				this.tabView.realignInkBar();
			}
		}
	}

	@Output() refreshQuotes: EventEmitter<{ id: number, callback: any }> = new EventEmitter<any>();

	@ViewChild(MatTabGroup) tabView: MatTabGroup;
	public selectedTabIndex = null;
	public tabIndexSubscription: Subscription = null;

	@ViewChild(VehiclePicker) vehiclePicker: VehiclePicker;

	public loading = false;
	public taxExempt: boolean = false;
	public taxRate: number = 0.0;
	public otherFees: number = 0.0;
	public disposalFees: number = 0.0;

	public selectedLineItem: UntypedFormGroup = null;
	public selectedLineItemIndex: number = -1;

	private quoteChangedSubscription: Subscription = null;

	public tableDataSource = new MatTableDataSource();
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	@Output("initialized") initialized: EventEmitter<void> = new EventEmitter<void>();

	constructor(public userService: UserService, private api: ApiService, private formBuilder: UntypedFormBuilder, private dialog: MatDialog, private invoiceService: InvoiceService, private storage: LocalStorageService) {
		super();
		this.setupProductEntitySelectorTableColumns();
	}

	public ngAfterViewInit() {
		if (this.tabView) {
			this.tabIndexSubscription = this.tabView.selectedIndexChange.subscribe((index) => {
				this.selectedTabIndex = index;
			});

			if (this.selectedTabIndex != null) {
				this.tabView.selectedIndex = this.selectedTabIndex;
			}
		}
	}

	public setupProductEntitySelectorTableColumns() {
		let deleteButton = new EntitySelectorTableColumn();
		deleteButton.columnHeader = "";
		deleteButton.columnProperty = "delete";
		deleteButton.columnWidth = "60px";
		deleteButton.type = EntitySelectorTableColumnType.asyncButton;
		deleteButton.typeOptions = {
			icon: "fa fa-trash",
			click: (productForm: UntypedFormGroup, index: number) => {
				return this.removeProduct(index);
			},
			materialType: "mat-mini-fab",
			disabled: () => {
				return this.quoteForm.disabled;
			},
		};
		this.selectorTableColumns.push(deleteButton);

		let partNumberColumn = new EntitySelectorTableColumn();
		partNumberColumn.columnHeader = "Part Number";
		partNumberColumn.inputLabel = "Part Number";
		partNumberColumn.columnProperty = "part_number";
		partNumberColumn.type = EntitySelectorTableColumnType.text;
		partNumberColumn.errors = [
			{
				message: "Part number is required!",
				name: Validators.required.name,
			},
			{
				message: "Duplicate part number identified.",
				name: "duplicatePartNumber",
			},
		];

		this.selectorTableColumns.push(partNumberColumn);

		let quantityColumn = new EntitySelectorTableColumn();
		quantityColumn.columnHeader = "Quantity";
		quantityColumn.columnWidth = "50px";
		quantityColumn.inputLabel = "Quantity";
		quantityColumn.columnProperty = "quantity";
		quantityColumn.type = EntitySelectorTableColumnType.number;
		quantityColumn.errors = [
			{
				message: "Quantity is required!",
				name: Validators.required.name,
			},
			{
				message: "Quantity must be greater than or equal to 0",
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(quantityColumn);

		let descriptionColumn = new EntitySelectorTableColumn();
		descriptionColumn.columnHeader = "Description";
		descriptionColumn.inputLabel = "Description";
		descriptionColumn.columnProperty = "description";
		descriptionColumn.type = EntitySelectorTableColumnType.text;
		descriptionColumn.errors = [
			{
				message: "Description is required!",
				name: Validators.required.name,
			},
		];

		this.selectorTableColumns.push(descriptionColumn);

		let costColumn = new EntitySelectorTableColumn();
		costColumn.columnHeader = "Cost";
		costColumn.inputLabel = "Cost";
		costColumn.columnWidth = "75px";
		costColumn.columnProperty = "cost";
		costColumn.type = EntitySelectorTableColumnType.number;
		costColumn.errors = [
			{
				message: "Cost is required!",
				name: Validators.required.name,
			},
			{
				message: "Cost must be greater than or equal to 0",
				name: Validators.min.name,
			},
		];

		this.selectorTableColumns.push(costColumn);

		if (this.userService.isAdmin() || this.userService.isManager()) {
			let upchargeButton = new EntitySelectorTableColumn();
			upchargeButton.columnHeader = "Upcharge";
			upchargeButton.columnProperty = "upcharge";
			upchargeButton.type = EntitySelectorTableColumnType.custom;
			upchargeButton.typeOptions = {
				componentType: ProductUpchargeToggle,
			};
			this.selectorTableColumns.push(upchargeButton);
		}

		this.tableDataSource.filterPredicate = (productForm: UntypedFormGroup, filterText: string) => {
			let product: Product = productForm.value || {};

			let name = product.part_number + " " + product.description;
			if (name == null || name == undefined) name = "";

			name = name.toLowerCase().trim();

			return name.includes(filterText.trim());
		};
	}

	public unsubscribeToFormChanges() {
		if (this.quoteChangedSubscription) {
			this.quoteChangedSubscription.unsubscribe();
			this.quoteChangedSubscription = null;
		}
	}

	public ngOnDestroy() {
		this.unsubscribeToFormChanges();
	}

	public initializeQuoteForm() {
		let vehicle: any = this.quote.vehicle || {};

		//		Be sure to set the quotess user id on a new quote
		if (!this.quote.id) {
			this.quote.user.id = this.userService.user.id;
		}

		//      Initialize the quote form object
		this.quoteForm = this.formBuilder.group({
			id: new UntypedFormControl(this.quote.id),
			user_id: new UntypedFormControl(this.quote.user.id, [Validators.required]),
			user: this.formBuilder.group(this.quote.user),
			lineItems: new UntypedFormArray(
				this.quote.lineItems
					? this.quote.lineItems.map((item) =>
						this.formBuilder.group({
							id: new UntypedFormControl(item.id),
							complaint: new UntypedFormControl(item.complaint, [Validators.required]),
							cause: new UntypedFormControl(item.cause, [Validators.required]),
							correction: new UntypedFormControl(item.correction, [Validators.required]),
							hours: new UntypedFormControl(item.hours, Validators.required),
							rate: new UntypedFormControl(item.rate >= 0 ? item.rate : this.getLineItemLaborRate() || 0.0),
							products: new UntypedFormArray(
								item.products
									? item.products.map((product) =>
										this.formBuilder.group({
											id: new UntypedFormControl(product.id),
											description: new UntypedFormControl(product.description),
											part_number: new UntypedFormControl(product.part_number),
											cost: new UntypedFormControl(product.cost || 0),
											quantity: new UntypedFormControl(product.quantity),
										})
									)
									: []
							),
						})
					)
					: []
			),
			customer_id: new UntypedFormControl(this.quote.customer.id, Validators.required),
			customer: this.formBuilder.group(this.quote.customer),
			vehicle: this.formBuilder.group({
				id: new UntypedFormControl(vehicle.id),
				vin: new UntypedFormControl(vehicle.vin, Validators.required),
				unit_number: new UntypedFormControl(vehicle.unit_number, Validators.required),
				milage: new UntypedFormControl(vehicle.milage, Validators.required),
				license_number: new UntypedFormControl(vehicle.license_number, Validators.required),
				equipment_type: new UntypedFormControl(vehicle.equipment_type, Validators.required)
			})
		});

		if (this.quoteForm.value.customer_id) {
			this.customerSelectedHandler({ option: { value: this.quoteForm.value.customer_id } } as any);
		}

		if (this.selectedLineItemIndex == -1) {
			//      Automatically select the first line item in the lsit
			let lineItems = (this.quoteForm.get("lineItems") as UntypedFormArray).controls;

			if (lineItems && lineItems.length > 0) {
				this.selectedLineItem = lineItems[0] as UntypedFormGroup;
				this.selectedLineItemIndex = 0;
			} else {
				this.selectedLineItem = null;
				this.selectedLineItemIndex = -1;
			}
		}

		if (this.selectedLineItem) {
			this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
		}

		this.unsubscribeToFormChanges();
		this.quoteChangedSubscription = this.quoteForm.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(
			(response) => {
				if (!this.quoteForm.pristine && this.quoteForm.dirty) {
					this.storage.add(quotesStorageKey, this.quoteForm.value.id, this.quoteForm.value);
				}
			},
			(error) => {
				console.error(error);
			}
		);

		if (this.storage.has(quotesStorageKey, this.quote.id)) {
			this.quoteForm.markAsDirty();
			this.quoteForm.markAsTouched();
		}

		if (this.userService.user.id != this.quoteForm.get("user_id").value && !this.userService.isAdmin() && this.quoteForm.get("close_date").value) {
			this.quoteForm.disable();
		}

		this.initialized.emit();
	}

	public vehicleSelectedHandler = (event: MatAutocompleteSelectedEvent) => {
		if (!event || !event.option) return;

		let vehicleId = event.option.value;

		this.api
			.getAllVehicles()
			.toPromise()
			.then((vehicles) => {
				let vehicle = vehicles.find((vehicle) => vehicle.id == vehicleId);

				if (vehicle) {
					let idControl = this.quoteForm.get("vehicle.id");
					if (idControl) idControl.patchValue(vehicle.id);

					/*
				Client requested that his workers need to fill this in every time.

				let milageControl = this.quoteForm.get('vehicle.milage');
				if(milageControl) milageControl.patchValue(vehicle.milage);
				*/

					let unitNumberControl = this.quoteForm.get("vehicle.unit_number");
					if (unitNumberControl) unitNumberControl.patchValue(vehicle.unit_number);

					let vinControl = this.quoteForm.get("vehicle.vin");
					if (vinControl) vinControl.patchValue(vehicle.vin);

					let licenseControl = this.quoteForm.get("vehicle.license_number");
					if (licenseControl) licenseControl.patchValue(vehicle.license_number);

					let equipmentControl = this.quoteForm.get('vehicle.equipment_type');
					if (equipmentControl) equipmentControl.patchValue(vehicle.equipment_type);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	public customerSelectedHandler = (event: MatAutocompleteSelectedEvent) => {
		if (this.quoteForm && event.option.value) {
			this.api
				.getAllCustomers()
				.toPromise()
				.then((customers) => {
					let theCustomer = customers.find((customer) => customer.id == event.option.value);

					if (theCustomer) {
						this.taxExempt = theCustomer.taxExempt || false;
						this.taxRate = theCustomer.taxRate || 0.0;
						this.quoteForm.get("customer").patchValue(theCustomer);

						let lineItems: Array<LineItem> = this.quoteForm.value.lineItems;

						if (lineItems && lineItems.length) {
							lineItems.map((lineItem) => {
								if (lineItem.rate <= 0 || lineItem.rate == null || lineItem.rate == undefined) {
									lineItem.rate = this.getLineItemLaborRate();
								}
							});

							this.quoteForm.get("lineItems").patchValue(lineItems);
						}
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	public openVehicleProductsSelector(): void {
		let modalref = this.dialog.open(GetVehicleProductsModal);
		modalref.componentInstance.vehicleId = this.userService.user.vehicleId;

		modalref
			.afterClosed()
			.toPromise()
			.then((results) => {
				if (results && results.length) {
					results.map((result: Product) => {
						result.quantity = 0;
						this.addProduct(result);
					});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	public downloadInvoiceClicked = () => {
		let quote: Quote = new Quote(JSON.parse(JSON.stringify(this.quoteForm.value)));

		let definition = this.invoiceService.createCustomerDocument(quote as any, this.taxExempt, this.taxRate, this.disposalFees, this.otherFees);
		this.invoiceService.downloadDocument(definition);

		return Promise.resolve();
	};

	public selectLineItem(index) {
		let lineItems = this.quoteForm.get("lineItems") as UntypedFormArray;

		if (lineItems && lineItems.length) {
			if (this.selectedLineItemIndex < 0) {
				this.selectedLineItemIndex = -1;
			}

			if (index < lineItems.length) {
				this.selectedLineItemIndex = index;
				this.selectedLineItem = lineItems.controls[this.selectedLineItemIndex] as UntypedFormGroup;

				if (this.selectedLineItem && this.selectedLineItem.get("products")) {
					this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
				}
			}
		}
	}

	public nextLineItem() {
		let lineItems = this.quoteForm.get("lineItems") as UntypedFormArray;

		if (lineItems && lineItems.length) {
			if (this.selectedLineItemIndex < 0) {
				this.selectedLineItemIndex = -1;
			}

			if (this.selectedLineItemIndex + 1 < lineItems.length) {
				this.selectedLineItemIndex++;
				this.selectedLineItem = lineItems.controls[this.selectedLineItemIndex] as UntypedFormGroup;

				if (this.selectedLineItem && this.selectedLineItem.get("products")) {
					this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
				}
			}
		}
	}

	public previousLineItem() {
		let lineItems = this.quoteForm.get("lineItems") as UntypedFormArray;

		if (lineItems && lineItems.length) {
			if (this.selectedLineItemIndex - 1 >= 0) {
				this.selectedLineItemIndex--;
				this.selectedLineItem = lineItems.controls[this.selectedLineItemIndex] as UntypedFormGroup;

				if (this.selectedLineItem && this.selectedLineItem.get("products")) {
					this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
				}
			}
		}
	}

	public addProduct(product: Product): void {
		if (!product) product = {} as any;

		if (this.selectedLineItem) {
			let productForm = this.formBuilder.group({
				id: new UntypedFormControl(product.id),
				cost: new UntypedFormControl(product.cost || 0.0, [Validators.required, Validators.min(0)]),
				quantity: new UntypedFormControl(product.quantity, [Validators.required, Validators.min(0)]),
				description: new UntypedFormControl(product.description, Validators.required),
				part_number: new UntypedFormControl(product.part_number, Validators.required),
			});

			let products = this.selectedLineItem.get("products") as UntypedFormArray;
			if (products) {
				this.quoteForm.markAsDirty();
				products.insert(0, productForm);
			}

			this.tableDataSource.data = (this.selectedLineItem.get("products") as UntypedFormArray).controls;
			this.tableDataSource.paginator.firstPage();
		}
	}

	public removeProduct(itemIndex: number): Promise<any> {
		if (itemIndex && itemIndex <= 0) return Promise.resolve();

		if (this.quoteForm && this.selectedLineItem.value) {
			let products = this.selectedLineItem.get("products") as UntypedFormArray;

			if (products && products.value && itemIndex < products.value.length) {
				products.removeAt(itemIndex);
				this.quoteForm.markAsDirty();

				SnackBarService.openSnackBarAlert("Product removed.");
				this.tableDataSource.data = products.controls;
			}
		}
	}

	public addLineItem(): void {
		//      First get the line items form array
		let lineItems = this.quoteForm.get("lineItems") as UntypedFormArray;

		if (lineItems) {
			//      Add a new form group to the array
			let newItem = this.formBuilder.group({
				id: new UntypedFormControl(null),
				complaint: new UntypedFormControl(null, Validators.required),
				cause: new UntypedFormControl(null, Validators.required),
				correction: new UntypedFormControl(null, Validators.required),
				hours: new UntypedFormControl(null, Validators.required),
				rate: new UntypedFormControl(this.getLineItemLaborRate()),
				products: new UntypedFormArray([]),
			});
			this.quoteForm.markAsDirty();
			lineItems.push(newItem);

			//      Set this as the new selected line item
			this.selectedLineItem = newItem;
			this.selectedLineItemIndex = lineItems.length - 1;
			this.tableDataSource.data = (newItem.get("products") as UntypedFormArray).controls;
		} else throw new Error("No line items array found!");
	}

	public getLineItemLaborRate(): number {
		let rate = this.quoteForm.value.customer ? this.quoteForm.value.customer.laborRate || 0.00 : 0.00;
		//		First see if customer and vehicle type are selected
		if (this.quoteForm.value.customer && this.quoteForm.value.vehicle && this.quoteForm.value.vehicle.equipment_type) {
			//		Get the customer labor rate for the selected vehicle type
			let customerRate = this.quoteForm.value.customer.labor_rates ? this.quoteForm.value.customer.labor_rates.find(laborRate => laborRate.equipment_type.toLowerCase().trim() == this.quoteForm.value.vehicle.equipment_type.toLowerCase().trim()) : null;

			if (customerRate != null && customerRate != undefined) {
				rate = customerRate.rate;
			}
		}

		return rate;
	}

	public vehicleTypeChanged(event: MatSelectChange) {
		if (this.quoteForm.value.vehicle) {
			let lineItems: Array<LineItem> = this.quoteForm.value.lineItems;

			if (lineItems && lineItems.length) {
				lineItems.map((lineItem) => {
					if (lineItem.rate <= 0 || lineItem.rate == null || lineItem.rate == undefined) {
						lineItem.rate = this.getLineItemLaborRate();
					}
				});

				this.quoteForm.get("lineItems").patchValue(lineItems);
			}
		}
	}

	public removeLineItem(): void {
		if (!this.selectedLineItem) return;

		if (this.quoteForm) {
			let items = this.quoteForm.get("lineItems") as UntypedFormArray;

			if (items) {
				let index = items.controls.findIndex((item) => item === this.selectedLineItem);

				items.removeAt(index);
				this.quoteForm.markAsDirty();

				if (items.length > 0) {
					this.selectedLineItem = items.controls[0] as UntypedFormGroup;
					this.selectedLineItemIndex = this.selectedLineItemIndex - 1;
				} else {
					this.selectedLineItem = null;
					this.selectedLineItemIndex = -1;
				}

				SnackBarService.openSnackBarAlert("Line item removed.");
			}
		}
	}

	public getQuoteProducts(): any {
		let products = [];

		if (this.selectedLineItem) {
			products = (this.selectedLineItem.get("products") as UntypedFormArray).controls || [];
		}

		return products;
	}

	public deleteQuoteClicked = () => {
		let quoteDeleted = Promise.resolve();

		if (this.userCantEdit()) {
			return quoteDeleted;
		}

		this.loading = true;

		let componentRef = this.dialog.open(ConfirmModal, {
			data: {
				dialogue: "This action cannot be undone. Are you sure you would like to delete this quote?",
			},
			disableClose: true
		});

		return componentRef
			.afterClosed()
			.toPromise().then((result) => {
				if (result && this.quote.id) {
					return quoteDeleted = this.api
						.deactivateQuote(this.quote.id)
						.toPromise()
						.then((response) => {
							SnackBarService.openSnackBarAlert("Quote deleted.");
							this.quote = null;
							this.refreshQuotes.emit({ id: null, callback: null });
						})
						.catch((error) => {
							SnackBarService.openSnackBarAlert(error.error.message, "red");
						});
				}
			}).catch(error => {
				//		Do Nothing
			}).finally(() => {
				this.loading = false;
			});
	};

	public getFormErrorMessage() {
		let message = 'Looks like your quote is missing some required information some where. Please look it over and try again';

		if (this.quoteForm) {
			let erroredControls: { control: FormControl, key: string }[] = [];

			let iterateFormAndGatherErroredControls = (abstractControl: AbstractControl, key: string) => {
				if (abstractControl instanceof FormGroup) {
					for (let key in abstractControl.controls) {
						iterateFormAndGatherErroredControls(abstractControl.controls[key], key);
					}
				} else if (abstractControl instanceof FormControl && (abstractControl as FormControl).invalid) {
					erroredControls.push({ control: abstractControl, key: key });
				} else if (abstractControl instanceof FormArray) {
					(abstractControl as FormArray).controls.map(item => {
						iterateFormAndGatherErroredControls(item, '');
					});
				}
			};

			iterateFormAndGatherErroredControls(this.quoteForm, '');

			if (erroredControls.length > 0) {
				message = 'Looks like your quote is missing some required information.\n\nHint:\n';

				erroredControls.map((erroredControl) => {
					message += `${erroredControl.key} is ${Object.keys(erroredControl.control.errors).join(', ')}.\n`;
				});
			}

		}

		return message;
	}

	public onQuoteSubmit = (doRefresh: boolean = true, skipLoadingCheck: boolean = false) => {
		if (!this.quoteForm || this.quoteForm.invalid || this.userCantEdit(skipLoadingCheck)) {
			if (this.userCantEdit(skipLoadingCheck)) {
				SnackBarService.openSnackBarAlert("Sorry, you do not have the appropriate permissions to complete this action.", "red");
			}

			if (this.quoteForm.invalid) {
				SnackBarService.openSnackBarAlert(this.getFormErrorMessage(), "red");

				let iterateControls = (group: UntypedFormGroup | UntypedFormArray) => {
					Object.keys(group.controls).forEach((key: string) => {
						const abstractControl: AbstractControl = group.controls[key];

						if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
							iterateControls(abstractControl);
						} else {
							abstractControl.markAsTouched();
						}
					});
				};

				iterateControls(this.quoteForm);
			}

			return Promise.reject();
		}

		if (this.loading) {
			return Promise.resolve();
		}

		this.loading = true;

		//		First create / update the quote
		let isNew = this.quoteForm.value.id;
		let quoteSubmitted = isNew ? this.api.updateQuote(this.quoteForm.value).toPromise() : this.api.createQuote(this.quoteForm.value).toPromise();

		return quoteSubmitted.then(() => {

			if (this.storage.has(quotesStorageKey, this.quoteForm.value.id)) {
				this.storage.remove(quotesStorageKey, this.quoteForm.value.id);
			}

			this.quoteForm.markAsPristine();
			this.quoteForm.markAsUntouched();
		}).catch((error) => {
			console.error(error);
			this.loading = false;
		}).finally(() => {
			//		Call for a refresh of this data
			if (doRefresh) {

				this.refreshQuotes.emit({
					id: isNew, callback: () => {
						this.loading = false;
					}
				});
			} else {
				this.loading = false;
			}
		});
	};

	public userCantEdit(skipLoadingCheck: boolean = false): boolean {
		//	Block edits if the user is not an admin and its closed.
		//	Or we are loading
		return (skipLoadingCheck ? false : this.loading) || (!this.userService.isAdmin());
	}

	public padQuoteNumber = padToLength;

	public convertToRepairOrder = () => {
		if (!this.quoteForm.value.id || this.quoteForm.invalid) {
			SnackBarService.openSnackBarAlert("Looks like your quote might be missing some information. Please look it over and try again.", "red");
		}

		this.loading = true;

		let componentRef = this.dialog.open(UserSelectModal, {
			data: {
				dialogue: "Please select a user to assign this quote to.",
			},
			disableClose: true
		});

		return componentRef
			.afterClosed()
			.toPromise().then((userId: number) => {
				if (!userId) {
					SnackBarService.openSnackBarAlert(`Looks you have not selected a user. Please select a user then click "Ok".`, "red");
					return;
				}

				return this.onQuoteSubmit(true, true).then(() => this.api.convertQuoteToRepairOrder(this.quoteForm.value.id, userId).toPromise().then(() => {
					SnackBarService.openSnackBarAlert("Repair order successfully created from quote");
				}).catch(error => {
					SnackBarService.openSnackBarAlert(`Looks like an error occurred during the conversion. ${error && error.error && error.error.message ? error.error.message : ''}`, "red");
				})).catch(error => {
					//		Do nothing, error being displayed is handled by onQuoteSubmit
				});
			}).finally(() => {
				this.loading = false;
			});


	};
}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserPicker } from '../../users/user_picker/userPicker.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'user-select-modal',
	template: `
		<div style="max-width: 60vw">
			<h1 mat-dialog-title>{{ dialogue }}</h1>
			<div mat-dialog-content>
				<user-picker></user-picker>
			</div>
			<div mat-dialog-actions>
				<button mat-button (click)="onNoClick()">No Thanks</button>
				<button mat-button (click)="okClick()" cdkFocusInitial>Ok</button>
			</div>
		</div>
	`
})
export class UserSelectModal {
	@ViewChild(UserPicker) userPicker: UserPicker;
	public dialogue: string = 'Please select a user.';

	constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ConfirmModal>) {
		if (data) {
			if (data.hasOwnProperty('dialogue')) this.dialogue = data.dialogue;
		}
	}

	public onNoClick(): void {
		this.dialogRef.close();
	}

	public okClick() {
		this.dialogRef.close(this.userPicker.userId);
	}
}
