<mat-form-field *ngIf="!loading" class="w-100">
	<mat-label>{{ label }}</mat-label>
	<input [readonly]="isDisabled" matInput placeholder="Vehicle" [formControl]="control"
		[matAutocompleteDisabled]="isDisabled" [matAutocomplete]="auto">
	<mat-autocomplete (optionSelected)="optionSelected($event)" #auto="matAutocomplete" [displayWith]="displayFn">
		<mat-option *ngFor="let vehicle of filteredVehicles | async" [value]="vehicle.id">
			{{ vehicle.unit_number }} - {{ vehicle.vin }}
		</mat-option>
	</mat-autocomplete>
	<mat-error *ngFor="let validator of validators">
		<span *ngIf="formControlHasError(control, validator.key)" [innerHTML]="validator.message"></span>
	</mat-error>
</mat-form-field>